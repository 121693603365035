// import moment from "moment";
import { ActionTypes } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return { ...state, loading: true };
    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        success: {
          ...action.payload,
          criteria: action.payload?.criteria,
        },
      };

    case ActionTypes.SET_CRITERIA:
      return {
        ...state,
        success: {
          ...state?.success,
          criteria: action?.spread
            ? [...state?.success?.criteria, ...action?.payload]
            : action.payload,
        },
      };

    case ActionTypes.ASSIGN_CRITERIA:
      return {
        ...state,
        success: {
          ...state?.success,
          criteria: [...state?.success?.criteria, ...action?.payload],
        },
      };
    case ActionTypes.UPDATE_CRITERIA:
      return {
        ...state,
        success: {
          ...state?.success,
          criteria: [
            ...state?.success?.criteria.filter(
              (c1) => !action.payload.find((c2) => c2.id === c1.id)
            ),
            ...action.payload,
          ],
        },
      };
    case ActionTypes.DELETE_CRITERIA:
      return {
        ...state,
        success: {
          ...state?.success,
          criteria: [
            ...state?.success?.criteria.filter(
              (c1) => !action.payload.find((c2) => c2 === c1.id)
            ),
          ],
        },
      };
    case ActionTypes.SET_OPTIONS:
      return {
        ...state,
        success: {
          ...state?.success,
          options: action?.spread
            ? [
                ...state?.success?.options?.filter(
                  (item) => item?.id !== action?.payload?.id
                ),
                action?.payload,
              ]
            : action?.payload,
        },
      };

    case ActionTypes.SET_DELETE_OPTIONS:
      return {
        ...state,
        success: {
          ...state?.success,
          options: state?.success?.options?.filter(
            (item) => item?.id !== action?.payload
          ),
        },
      };
    case ActionTypes.DELETE_DECISION_CRITERIAS:
      return {
        ...state,
        success: {
          ...state?.success,
          criteria: state?.success?.criteria?.filter(
            (item) => item?.id !== action?.payload
          ),
        },
      };
    case ActionTypes.CLOSE_INITIAL_POPUP:
      return {
        ...state,
        success: {
          ...state?.success,
          is_new: false,
        },
      };
    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
