import { getPros } from "api";
import { Error } from "utils/Response";
import { ActionTypes } from "./types";

export const GetPros = (Data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await getPros(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
export const SetPros = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
