import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyDXCmai_xs_aSzqlaOUZY5x_Hioo9Ix0Ko",
  authDomain: "arootah-project-planner.firebaseapp.com",
  projectId: "arootah-project-planner",
  storageBucket: "arootah-project-planner.appspot.com",
  messagingSenderId: "330922813758",
  appId: "1:330922813758:web:0250acf9cb7f02c490e46f",
  measurementId: "G-X5BMYR3HB1",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BHJIrX3AdNIYkjf9o8A4tICPw87Y7hQdnuLBYzlBDVrIrWkIaK77K600grna0Iomu76J1XO-kz5ZGpGoNSBqt3c",
    });

    if (currentToken) {
      return currentToken;
      // console.log('current token for client: ', currentToken)
    } else {
      // console.log(
      //   'No registration token available. Request permission to generate one.'
      // )
    }
  } catch (err) {
    // console.log('An error occurred while retrieving token. ', err)
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
