import { Drawer, Radio } from "antd";
import React, { useState } from "react";

// Styles
import styles from "./styles.module.scss";

// Icons
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { ReactComponent as ReloadIcon } from "assets/svg/reload.svg";
import { ReactComponent as AIAssistant } from "assets/svg/aiAssistant.svg";
import { ReactComponent as AIAssistantLogo } from "assets/svg/assistant-logo.svg";
import loaderIcon from "assets/svg/assistant-loader.gif";

const Assistant = ({
  active,
  setActive,
  type,
  loading,
  data,
  handleSocket,
  setSelectedDescription,
}) => {
  const [selectRadio, setSelectRadio] = useState(null);

  const socketData =
    type === "purpose"
      ? data?.message?.Purposes
      : type === "objective"
      ? data?.message?.Objectives
      : data?.message?.Brainstorm_Ideas;

  const apply = () => {
    setSelectRadio(null);
    setSelectedDescription(socketData[selectRadio]?.Description);
    setActive(false);
  };

  return (
    <div>
      <Drawer
        placement="right"
        headerStyle={{
          display: "none",
        }}
        closable={true}
        open={active}
        onClose={() => {
          setSelectRadio(null);
          setActive(false);
        }}
        className="assistant-drawer"
      >
        <div className={styles.assistant}>
          <div className={styles.assistant_header}>
            <h3>{type || "PURPOSE"}</h3>

            <CloseIcon
              className="cursor-pointer"
              onClick={() => {
                setSelectRadio(null);
                setActive(false);
              }}
            />
          </div>

          {!loading && socketData?.length > 0 ? (
            <>
              <div className={styles.assistant_lists}>
                {socketData?.map((item, index) => {
                  return (
                    <div className={styles.assistant_list} key={index}>
                      <div className={styles.assistant_list_content}>
                        <h5>
                          <span>{item?.Title}:</span> {item?.Description}
                        </h5>

                        {index === 0 && (
                          <h6 className={styles.tags}>Top Pick</h6>
                        )}
                      </div>
                      <Radio
                        checked={selectRadio === index}
                        onChange={() => {
                          setSelectRadio(index);
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div className={styles.assistant_actions}>
                <button
                  onClick={() => {
                    setSelectRadio(null);
                    handleSocket(type);
                  }}
                >
                  <ReloadIcon />
                  Regenerate
                </button>
                <button onClick={apply} disabled={selectRadio === null}>
                  Apply
                </button>
              </div>
            </>
          ) : (
            <div className={styles.assistant_loader}>
              <AIAssistantLogo />
              <h3>Suggesting {type}...</h3>
              <img src={loaderIcon} alt="loader" width={52} height={53} />
              {/* <button
                onClick={() => {
                  handleSocket(type);
                }}
              >
                <ReloadIcon />
                Refresh
              </button> */}
            </div>
          )}

          <div className={styles.assistant_footer}>
            <AIAssistant />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Assistant;
