import { deleteOption } from "api";
import { Error } from "utils/Response";
import { SetDeleteDecisionOptions } from "../GetDecisionDetails/action";
import { ActionTypes } from "./types";

export const DeleteOption = (Data, decisionId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await deleteOption(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 204) {
      dispatch(SetDeleteDecisionOptions(Data));
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
