import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import throttle from 'lodash/throttle';

const style = {
  cursor: 'move',
  transition: 'transform 0.3s ease',
  height:"max-content"
};

export const Card = ({ id, children, index, moveCard }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover: throttle((item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }, 50),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <div ref={ref}  style={{ ...style, opacity}} data-handler-id={id}>
      {children}
    </div>
  );
};