import React from 'react';
import { ThemeProvider } from "styled-components";

import { useSelector } from 'react-redux';

import Background from '../../assets/images/profile/background.jpg';

import * as Styled from './styled';
import theme from '../../theme';
import Loader from "components/Loader";

import AccountInformation from '../../components/UserProfile/AccountInformation';
import ProfilePicture from '../../components/UserProfile/ProfilePicture';
import { AppLayout } from 'components';

import { getProject, PROJECT } from 'utils/constants';

const Profile = () => {
  const {
    user: { data: userData },
  } = useSelector((state) => state.auth);

  const project = getProject(location.pathname);

  const { first_name, last_name } = userData;

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        {!userData ? (
          <Loader />
        ) : (
          <Styled.Wrapper $padding={project === PROJECT.DM}>
            <Styled.Header>
              <Styled.ImageWrapper>
                <Styled.ImagePlaceholder src={Background} alt="" />
                <Styled.Title>Profile</Styled.Title>
              </Styled.ImageWrapper>

              <ProfilePicture data={userData} />

              <Styled.Bar>
                <Styled.User>{first_name} {last_name}</Styled.User>
              </Styled.Bar>
            </Styled.Header>

            <AccountInformation userData={userData} />
          </Styled.Wrapper>
        )}
      </AppLayout>
    </ThemeProvider>
  );
};

export default Profile;
