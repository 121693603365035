import React, { useState } from "react";
import classes from "./TemplatePreview.module.scss";
import { ReactComponent as CloseIcon } from "./close.svg";
import { Checkbox, Col, Row } from "antd";
import { CreateDecisionFromTemplate } from "redux/DecisionMatrix/CreateDecisionFromTemplate/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ALLOCATOR_TOOL, DECISION_COACH } from "../../../../utils/routes";

const TemplatePreview = ({
  onClose,
  template,
  selectedCheckboxes,
  setSelectedCheckboxes,
  forAllocator
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    if (event.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== checkboxId)
      );
    }
  };

  const handleCreateDecision = () => {
    const criteria_list = selectedCheckboxes.join(",");

    dispatch(
      CreateDecisionFromTemplate(
        {
          template: template?.id,
          criteria_list,
        },
        handleCallback
      )
    );
  };

  const handleCallback = decision => {
    navigate(`/${forAllocator ? ALLOCATOR_TOOL : DECISION_COACH}/${decision?.id}`);
  }

  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <span>Preview</span>
            <button
              type="button"
              className={classes.close}
              onClick={onClose}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.body}>
            <div className={classes.title_container}>
              <div className={classes.gradient}></div>
              <div>
                <h5>{template?.name}</h5>
                {/* <a href="#">Domain/Area/Category</a> */}
              </div>
            </div>
            <div className={classes.description}>
              <p>{template?.description}</p>
            </div>
            <div className={classes.criteria_container}>
              <h6>Criteria</h6>
              <p>Select each criteria that fulfills your optimal decision.</p>
              <div className={classes.criteria_checkboxes}>
                {template?.criteria?.map((item, index) => (
                  <div className={classes.checkbox} key={index}>
                    <Checkbox
                      id={item?.id}
                      onChange={(e) => handleCheckboxChange(e)}
                      className="decision_table_checkbox fixed"
                    />
                    <p>{item?.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={classes.footer}>
            <button type="button" disabled={!selectedCheckboxes.length} onClick={handleCreateDecision}>
              + CREATE DECISION
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { TemplatePreview };
