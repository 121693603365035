import PropTypes from 'prop-types';
import { CSSProperties, forwardRef, ReactElement } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input<{ $suffix?: boolean; $size?: 'sm' | 'md'; }>`
  padding: 7px 12px;
  width: 100%;
  color: var(--neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: 1px solid var(--neutral-200, #E5E5E5);
  border-radius: 4px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ${({ $suffix }) => $suffix && css`
    padding-right: 38px;
  `}

  ${({ $size }) => $size && $size === 'sm' && css`
    font-size: 12px;
  `}

  ${({ $size }) => $size && $size === 'md' && css`
    padding: 12px;
  `}

  :placeholder {
    color: var(--neutral-400, var(--Placeholder, #A3A3A3));
  }

  :focus,
  :active {
    border-color #71AACA;
    box-shadow: 0px 0px 0px 2px #D0E3ED;
  }
`;

const SuffixWrap = styled.span<{ $size?: 'sm' | 'md'; }>`
  position: absolute;
  right: 12px;
  color: var(--neutral-400, var(--Placeholder, #A3A3A3));
  background-color: #fff;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;

  ${({ $size }) => $size && $size === 'sm' && css`
    top: 8px;
  `}
`;

interface InputFieldProps {
  suffix?: string | ReactElement | (() => ReactElement);
  size?: 'sm' | 'md';
  parentStyle?: CSSProperties;
  [key: string]: any; // Add specific props as needed
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ suffix: Suffix, size, parentStyle, ...props }, ref) => {
  return (
    <Wrapper style={parentStyle}>
      <Input {...props} $suffix={!!Suffix} $size={size} ref={ref} />
      {Suffix && (
        <SuffixWrap $size={size}>
          {typeof Suffix === 'function' ? <Suffix /> : Suffix}
        </SuffixWrap>
      )}
    </Wrapper>
  );
});

InputField.propTypes = {
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  size: PropTypes.oneOf(['sm', 'md']),
  // Rest of the props should be validated using PropTypes.object
  // if they are not known or PropTypes.shape if they are known.
};

export default InputField;
