import styled from "styled-components";
import {
  fadeInUpMixin,
  zoomInSlightlyMixin,
} from "../../../components/Auth/animated";
import TextBoxLabel from "../../../atoms/TextBoxLabel";
import TextBox from "../../../atoms/TextBox";
import { Button } from "react-bootstrap";
import { Submit } from "../SignUp/styled";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  /* height: 100vh; */
`;

export const Continaer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  gap: 10px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }
`;

export const Screen = styled.div`
  width: 320px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-align: center;
  ${fadeInUpMixin}
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #4b545b;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 44px;
  ${fadeInUpMixin}
`;

export const FormStyled = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div`
  width: 100%;
  max-width: 388px;
  margin: 22px auto 0;
  ${fadeInUpMixin}
`;

export const Label = styled(TextBoxLabel)`
  padding: 20px 0 6px;

  &:first-child {
    padding-top: 0;
  }
`;

export const TextBoxs = styled(TextBox)`
  width: 100%;

  &[type="password"] {
    width: calc(100% - 36px);
    border-right: none !important;
    border-radius: 0.25rem 0 0 0.25rem;
  }
`;

export const Eye = styled(Button)`
  position: absolute;
  background: transparent;
  opacity: 0.8;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 0;
  border-right: 1px solid #e3e5e9;
  border-top: 1px solid #e3e5e9;
  border-bottom: 1px solid #e3e5e9;
  border-radius: 0 0.25rem 0.25rem 0;
`;

export const FormInfo = styled.div`
  margin-top: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #818ea3;
`;

export const FormError = styled.div`
  margin-top: 4px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #f00;
`;

export const SubmitWrapper = styled.div`
  ${fadeInUpMixin}
`;

export const SubmitStyled = styled(Submit)`
  margin-top: 12px;
`;

export const Linkbox = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 20px;
  color: #1b2a3d;
  text-align: center;

  a {
    margin-left: 7px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #1271a6;
  }
`;

export const Content = styled.div`
  width: 650px;
  ${zoomInSlightlyMixin}

  h1 {
    font-size: 40px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 23px;
  }

  ul {
    padding: 0px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    li {
      font-size: 16px;
      font-weight: 300;
    }
  }
`;
