import React, { useEffect, useState } from "react";

import * as Styled from "./styled";
import * as yup from "yup";
import { Formik } from "formik";

import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeSlashIcon } from "assets/svg/eye-slash.svg";

import PasswordIndicator from "../../../components/PasswordIndicator";
import { ErrorFocus } from "../../../utils/ErrorFocus";
import Layout from "../../../components/Auth/Layout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { inviteUser } from "../../../modules/actions/AuthActions";

import { SIGN_IN_PATH } from "utils/routes";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("This field is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number."
    ),
  confirmPassword: yup
    .string()
    .required("This field is required.")
    .oneOf([yup.ref("password"), null], "Password entries do not match."),
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

const Invite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const [animate, setAnimate] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);


  const onPasswordVisibleClickHandle = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onPasswordConfirmVisibleClickHandle = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  const onSubmitHandle = async ({ password, confirmPassword }) => {
    setDisabled(true);
    dispatch(
      inviteUser(
        {
          form: { password_1: password, password_2: confirmPassword },
          token,
        },
        navigate
      )
    );
    setDisabled(false);
  };

  return (
    <Layout bg="register">
      <Styled.Wrapper>
        <Styled.Continaer>
          <Styled.Screen>
            <Styled.Title $animate={animate} $animateDelay={100}>
              Create an Account
            </Styled.Title>
            <Styled.SubTitle $animate={animate} $animateDelay={150}>
              The start of your peak performance journey is just minutes away.
            </Styled.SubTitle>

            <Formik
              validationSchema={schema}
              onSubmit={onSubmitHandle}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Styled.FormStyled noValidate onSubmit={handleSubmit}>
                  <Styled.FormGroup $animate={animate} $animateDelay={200}>
                    <Styled.Label className="d-flex align-items-center justify-content-between">
                      Password*
                    </Styled.Label>
                    <div className="flex-grow-1 position-relative w-100">
                      <Styled.TextBoxs
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={errors.password}
                        aria-label="Password"
                        data-testid="password-input"
                      />
                      <Styled.Eye
                        className="form-control-eye"
                        type="button"
                        onClick={onPasswordVisibleClickHandle}
                      >
                        {passwordVisible ? <EyeSlashIcon /> : <EyeIcon />}
                      </Styled.Eye>
                      <PasswordIndicator value={values.password} secondary />
                      {errors.password ? null : (
                        <Styled.FormInfo>
                          Password must have a minimum of 8 characters and at
                          least one of each: uppercase, special character, and
                          number.
                        </Styled.FormInfo>
                      )}
                      <Styled.FormError>{errors.password}</Styled.FormError>
                    </div>
                  </Styled.FormGroup>

                  <Styled.FormGroup $animate={animate} $animateDelay={350}>
                    <Styled.Label className="authLabel">
                      Confirm Password*
                    </Styled.Label>
                    <div className="flex-grow-1 position-relative w-100">
                      <Styled.TextBoxs
                        type={passwordConfirmVisible ? "text" : "password"}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={errors.confirmPassword}
                        aria-label="Confirm password"
                        data-testid="password-confirm-input"
                      />
                      <Styled.Eye
                        className="form-control-eye"
                        type="button"
                        onClick={onPasswordConfirmVisibleClickHandle}
                      >
                        {passwordConfirmVisible ? (
                          <EyeSlashIcon />
                        ) : (
                          <EyeIcon />
                        )}
                      </Styled.Eye>
                      <Styled.FormError type="invalid">
                        {errors.confirmPassword}
                      </Styled.FormError>

                      {/* <Styled.FormInfo>*Indicates required field.</Styled.FormInfo> */}
                    </div>
                  </Styled.FormGroup>

                  <Styled.SubmitWrapper $animate={animate} $animateDelay={400}>
                    <Styled.SubmitStyled
                      type="submit"
                      loading={disabled}
                      disabled={disabled}
                    >
                      Create Account
                    </Styled.SubmitStyled>
                  </Styled.SubmitWrapper>

                  <ErrorFocus />
                </Styled.FormStyled>
              )}
            </Formik>

            <Styled.Linkbox $animate={animate} $animateDelay={450}>
              Already a Member?
              <Link to={`${SIGN_IN_PATH}?app=dm`}>Log In</Link>
            </Styled.Linkbox>
          </Styled.Screen>

          <Styled.Content $animate={animate} $animateDelay={400}>
            <h1>
              Streamlined Project Management Mastery for Efficient Execution
            </h1>
            <h3>
              Elevate your project management game with our app designed to
              streamline your workflow and boost overall efficiency.
            </h3>

            <ul>
              <li>Seamlessly coordinate and collaborate with your team</li>
              <li>Identify and mitigate potential risks in real-time.</li>
              <li>
                Stay ahead of challenges by proactively addressing issues as
                they arise.
              </li>
            </ul>
          </Styled.Content>
        </Styled.Continaer>
      </Styled.Wrapper>
    </Layout>
  );
};

export default Invite;
