import { listSample } from "api";
import { Error } from "utils/Response";
import { ActionTypes } from "./types";

export const ListSample = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await listSample();
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const DeleteSampleDecision = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_DECISION,
    payload: id,
  });
};

