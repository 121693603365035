import { Layout } from 'antd'
import React from 'react'
import { MobilePlaceholder } from 'scenes/DecisionMatrix/components/MobilePlaceholder';
import useWindowSize from 'utils/useWindowSize';
import styles from './AppLayout.module.scss'

const { Content } = Layout

export default function AppLayout ({ children }) {
  const { width } = useWindowSize();

  return (
    <Layout className={styles.authcontainer}>
      {width <= 991 && <MobilePlaceholder />}

      <MobilePlaceholder />

      <Layout className={styles.authMain}>
        {/* Main content */}
        <Content className={styles.authcontent}>{children}</Content>
      </Layout>
    </Layout>
  )
}
