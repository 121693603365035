import { ActionTypes } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  columnsToShow: {
    weightedScore: true,
    adjustedScore: true,
    prosCons: true,
    criteriaDescription: false,
    isAscending: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return { ...state, loading: true };
    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        columnsToShow: action.payload,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
