import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history"; // eslint-disable-line
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { AuthReducer } from "modules/reducers/AuthReducer";
import { UserReducer } from "modules/reducers/UserReducer";
import { PlanReducer } from "modules/reducers/PlanReducer";
import { MissionStatementReducer } from "modules/reducers/MissionStatementReducer";
import addCollaboratorsReducer from "./DecisionMatrix/AddCollaborators/reducer";
import addOptionRowsReducer from "./DecisionMatrix/AddOptionRows/reducer";
import assignCriteriaReducer from "./DecisionMatrix/AssignCriteria/reducer";
import updateCriteriaRowsReducer from "./DecisionMatrix/UpdateCriteriaRows/reducer";
import createDecisionReducer from "./DecisionMatrix/AssignDecision/reducer";
import assignOptionReducer from "./DecisionMatrix/AssignOption/reducer";
import assignOptionCriteriaReducer from "./DecisionMatrix/AssignOptionCriteria/reducer";
import deleteCollaboratorsReducer from "./DecisionMatrix/DeleteCollaborators/reducer";
import deleteConsReducer from "./DecisionMatrix/DeleteCons/reducer";
import deleteCriteriaReducer from "./DecisionMatrix/DeleteCriteria/reducer";
import deleteDecisionReducer from "./DecisionMatrix/DeleteDecision/reducer";
import deleteOptionReducer from "./DecisionMatrix/DeleteOption/reducer";
import deleteOptionCriteriaReducer from "./DecisionMatrix/DeleteOptionCriteria/reducer";
import deleteProsReducer from "./DecisionMatrix/DeletePros/reducer";
import getConsReducer from "./DecisionMatrix/GetCons/reducer";
import getCriteriaReducer from "./DecisionMatrix/GetCriteria/reducer";
import getDecisionReducer from "./DecisionMatrix/GetDecision/reducer";
import getDecisionDetailsReducer from "./DecisionMatrix/GetDecisionDetails/reducer";
import getMitigationsReducer from "./DecisionMatrix/GetMitigations/reducer";
import getOptionReducer from "./DecisionMatrix/GetOption/reducer";
import getOptionCriteriaReducer from "./DecisionMatrix/GetOptionCriteria/reducer";
import getOptionDetailReducer from "./DecisionMatrix/GetOptionDetail/reducer";
import getProsReducer from "./DecisionMatrix/GetPros/reducer";
import updateCriteriaReducer from "./DecisionMatrix/UpdateCriteria/reducer";
import updateDecisionReducer from "./DecisionMatrix/UpdateDecision/reducer";
import updateOptionCriteriaReducer from "./DecisionMatrix/UpdateOptionCriteria/reducer";
import updateOptionsReducer from "./DecisionMatrix/UpdateOptions/reducer";
import addCriteriaRowReducer from "./DecisionMatrix/AddCriteriaRows/reducer";
import updatedConsReducer from "./DecisionMatrix/UpdateCons/reducer";
import updatedProsReducer from "./DecisionMatrix/UpdatePros/reducer";
import appleLoginReducer from "./Auth/AppleLogin/reducer";
import changeUserProfileReducer from "./Auth/ChangeUserProfile/reducer";
import facebookLoginReducer from "./Auth/FacebookLogin/reducer";
import forgotPasswordReducer from "./Auth/ForgotPassword/reducer";
import getUserProfileReducer from "./Auth/GetUserProfile/reducer";
import verifyOtpReducer from "./Auth/VerifyOtp/reducer";
import resendOtpReducer from "./Auth/ResendOtp/reducer"
import googleLoginReducer from "./Auth/GoogleLogin/reducer";
import resetPasswordReducer from "./Auth/ResetPassword/reducer";
import signinReducer from "./Auth/SignIn/reducer";
import signupReducer from "./Auth/SignUp/reducer";
import updatePasswordReducer from "./Auth/UpdatePassword/reducer";
import addToShortlistReducer from "./DecisionMatrix/AddToShortlist/reducer";
import removeFromShortlistReducer from "./DecisionMatrix/RemoveFromShortlist/reducer.js";
import columnsToShowReducer from "./DecisionMatrix/SetColumnsToShow/reducer";
import getListTemplateReducer from "./DecisionMatrix/ListTemplates/reducer";
import getListSamplesReducer from "./DecisionMatrix/ListSamples/reducer";
import createDecisionTemplateReducer from "./DecisionMatrix/CreateDecisionFromTemplate/reducer";
import generateAIProsConsReducer from "./DecisionMatrix/GenerateAIProsCons/reducer";
import generateAIScoringRubicReducer from "./DecisionMatrix/GenerateAIScoringRubic/reducer";
import generateAIDefinitionReducer from "./DecisionMatrix/GenerateAIDefinition/reducer";
import generateAICriteriaReducer from "./DecisionMatrix/GenerateAICriteria/reducer";
import aiPurposeDefinitionReducer from "./DecisionMatrix/GenerateAIPruposeDefinition/reducer";
import generateFactsReducer from "./DecisionMatrix/GenerateFacts/reducer";
import addToFinalistReducer from "./DecisionMatrix/AddToFinalist/reducer";
import removeFromFinalistReducer from "./DecisionMatrix/RemoveFromFinalist/reducer";
import getFinalistsReducer from "./DecisionMatrix/GetFinalists/reducer";
import shareDecisionReducer from "./DecisionMatrix/ShareDecision/reducer";
import getShareDecisionReducer from "./DecisionMatrix/GetShareDecision/reducer";
import getSharedDecisionsReducer from "./DecisionMatrix/GetSharedDecisions/reducer";
import getSummaryReducer from "./DecisionMatrix/GetDecisionSummary/reducer";
import selectedOption1Reducer from "./DecisionMatrix/SelectedOption1/reducer";
import selectedOption2Reducer from "./DecisionMatrix/SelectedOption2/reducer";
import aiObjectiveDefinitionReducer from "./DecisionMatrix/GenerateAIDescriptionDefinition/reducer";
import { sagas } from "../modules/sagas";

export const history = createBrowserHistory();
const enhancers = [];

const saga = createSagaMiddleware();
const initialState = {};
const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    plan: PlanReducer,
    user: UserReducer,
    missionStatement: MissionStatementReducer,
    appleLoginReducer,
    changeUserProfileReducer,
    facebookLoginReducer,
    forgotPasswordReducer,
    getUserProfileReducer,
    verifyOtpReducer,
    resendOtpReducer,
    googleLoginReducer,
    resetPasswordReducer,
    signinReducer,
    signupReducer,
    updatePasswordReducer,
    addCollaboratorsReducer,
    addOptionRowsReducer,
    assignCriteriaReducer,
    updateCriteriaRowsReducer,
    createDecisionReducer,
    assignOptionReducer,
    assignOptionCriteriaReducer,
    deleteCollaboratorsReducer,
    deleteConsReducer,
    deleteCriteriaReducer,
    deleteDecisionReducer,
    deleteOptionReducer,
    deleteOptionCriteriaReducer,
    deleteProsReducer,
    getConsReducer,
    getCriteriaReducer,
    getDecisionReducer,
    getMitigationsReducer,
    getOptionReducer,
    getOptionCriteriaReducer,
    getOptionDetailReducer,
    getProsReducer,
    updateCriteriaReducer,
    updateDecisionReducer,
    updateOptionCriteriaReducer,
    updateOptionsReducer,
    getDecisionDetailsReducer,
    addCriteriaRowReducer,
    updatedConsReducer,
    updatedProsReducer,
    addToShortlistReducer,
    removeFromShortlistReducer,
    columnsToShowReducer,
    getListTemplateReducer,
    getListSamplesReducer,
    createDecisionTemplateReducer,
    generateAIProsConsReducer,
    generateAIScoringRubicReducer,
    generateAIDefinitionReducer,
    generateAICriteriaReducer,
    aiPurposeDefinitionReducer,
    aiObjectiveDefinitionReducer,
    generateFactsReducer,
    addToFinalistReducer,
    removeFromFinalistReducer,
    getFinalistsReducer,
    shareDecisionReducer,
    getShareDecisionReducer,
    getSharedDecisionsReducer,
    getSummaryReducer,
    selectedOption1Reducer,
    selectedOption2Reducer,
  });

const createRootReducer = (history) => (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(history)(state, action);
};
const middleWares = [saga, thunk];

const store = createStore(
  createRootReducer(history), // root reducer with router state
  initialState,
  compose(applyMiddleware(...middleWares), ...enhancers)
);

saga.run(sagas);

export default store;
