import _ from "lodash";
import { LOGOUT_SUCCESS } from "./AuthReducer";

export const CREATE_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_ERROR = "CREATE_PLAN_ERROR";
export const GET_PLAN_REQUEST = "GET_PLAN_REQUEST";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";
export const GET_PLAN_BY_ID_REQUEST = "GET_PLAN_BY_ID_REQUEST";
export const GET_PLAN_BY_ID_SUCCESS = "GET_PLAN_BY_ID_SUCCESS";
export const GET_PLAN_BY_ID_ERROR = "GET_PLAN_BY_ID_ERROR";
export const GET_SHARED_PLAN_BY_ID_REQUEST = "GET_SHARED_PLAN_BY_ID_REQUEST";
export const GET_SHARED_PLAN_BY_ID_SUCCESS = "GET_SHARED_PLAN_BY_ID_SUCCESS";
export const GET_SHARED_PLAN_BY_ID_ERROR = "GET_SHARED_PLAN_BY_ID_ERROR";
export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";
export const REMOVE_PLAN_REQUEST = "REMOVE_PLAN_REQUEST";
export const REMOVE_PLAN_SUCCESS = "REMOVE_PLAN_SUCCESS";
export const REMOVE_PLAN_ERROR = "REMOVE_PLAN_ERROR";
export const ARCHIVE_PLAN_REQUEST = "ARCHIVE_PLAN_REQUEST";
export const ARCHIVE_PLAN_SUCCESS = "ARCHIVE_PLAN_SUCCESS";
export const ARCHIVE_PLAN_ERROR = "ARCHIVE_PLAN_ERROR";
export const CREATE_ACTION_ITEM_REQUEST = "CREATE_ACTION_ITEM_REQUEST";
export const CREATE_ACTION_ITEM_SUCCESS = "CREATE_ACTION_ITEM_SUCCESS";
export const CREATE_ACTION_ITEM_ERROR = "CREATE_ACTION_ITEM_ERROR";
export const UPDATE_ACTION_ITEM_REQUEST = "UPDATE_ACTION_ITEM_REQUEST";
export const UPDATE_ACTION_ITEM_SUCCESS = "UPDATE_ACTION_ITEM_SUCCESS";
export const UPDATE_ACTION_ITEM_ERROR = "UPDATE_ACTION_ITEM_ERROR";
export const DELETE_ACTION_ITEM_REQUEST = "DELETE_ACTION_ITEM_REQUEST";
export const DELETE_ACTION_ITEM_SUCCESS = "DELETE_ACTION_ITEM_SUCCESS";
export const DELETE_ACTION_ITEM_ERROR = "DELETE_ACTION_ITEM_ERROR";
export const CHANGE_ACTION_ITEM_ORDERS_REQUEST =
  "CHANGE_ACTION_ITEM_ORDERS_REQUEST";
export const CHANGE_ACTION_ITEM_ORDERS_SUCCESS =
  "CHANGE_ACTION_ITEM_ORDERS_SUCCESS";
export const CHANGE_ACTION_ITEM_ORDERS_ERROR =
  "CHANGE_ACTION_ITEM_ORDERS_ERROR";
export const CHANGE_PLAN_CATEGORY_ORDERS_REQUEST =
  "CHANGE_PLAN_CATEGORY_ORDERS_REQUEST";
export const CHANGE_PLAN_CATEGORY_ORDERS_SUCCESS =
  "CHANGE_PLAN_CATEGORY_ORDERS_SUCCESS";
export const CHANGE_PLAN_CATEGORY_ORDERS_ERROR =
  "CHANGE_PLAN_CATEGORY_ORDERS_ERROR";
export const CHANGE_CATEGORY_ORDERS_REQUEST = "CHANGE_CATEGORY_ORDERS_REQUEST";
export const CHANGE_CATEGORY_ORDERS_SUCCESS = "CHANGE_CATEGORY_ORDERS_SUCCESS";
export const CHANGE_CATEGORY_ORDERS_ERROR = "CHANGE_CATEGORY_ORDERS_ERROR";
export const CREATE_PLAN_CATEGORY_REQUEST = "CREATE_PLAN_CATEGORY_REQUEST";
export const CREATE_PLAN_CATEGORY_SUCCESS = "CREATE_PLAN_CATEGORY_SUCCESS";
export const CREATE_PLAN_CATEGORY_ERROR = "CREATE_PLAN_CATEGORY_ERROR";
export const UPDATE_PLAN_CATEGORY_REQUEST = "UPDATE_PLAN_CATEGORY_REQUEST";
export const UPDATE_PLAN_CATEGORY_SUCCESS = "UPDATE_PLAN_CATEGORY_SUCCESS";
export const UPDATE_PLAN_CATEGORY_ERROR = "UPDATE_PLAN_CATEGORY_ERROR";
export const ADJUST_CATEGORY_WEIGHT_REQUEST = "ADJUST_CATEGORY_WEIGHT_REQUEST";
export const ADJUST_CATEGORY_WEIGHT_SUCCESS = "ADJUST_CATEGORY_WEIGHT_SUCCESS";
export const ADJUST_CATEGORY_WEIGHT_ERROR = "ADJUST_CATEGORY_WEIGHT_ERROR";
export const DELETE_PLAN_CATEGORY_REQUEST = "DELETE_PLAN_CATEGORY_REQUEST";
export const DELETE_PLAN_CATEGORY_SUCCESS = "DELETE_PLAN_CATEGORY_SUCCESS";
export const DELETE_PLAN_CATEGORY_ERROR = "DELETE_PLAN_CATEGORY_ERROR";
export const CREATE_NOTES_REQUEST = "CREATE_NOTES_REQUEST";
export const CREATE_NOTES_SUCCESS = "CREATE_NOTES_SUCCESS";
export const CREATE_NOTES_ERROR = "CREATE_NOTES_ERROR";
export const UPDATE_NOTES_REQUEST = "UPDATE_NOTES_REQUEST";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_ERROR = "UPDATE_NOTES_ERROR";
export const DELETE_NOTES_REQUEST = "DELETE_NOTES_REQUEST";
export const DELETE_NOTES_SUCCESS = "DELETE_NOTES_SUCCESS";
export const DELETE_NOTES_ERROR = "DELETE_NOTES_ERROR";
export const GET_TOOL_REQUEST = "GET_TOOL_REQUEST";
export const GET_TOOL_SUCCESS = "GET_TOOL_SUCCESS";
export const GET_TOOL_ERROR = "GET_TOOL_ERROR";
export const GET_ALL_USER_REQUEST = "GET_ALL_USER_REQUEST";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR";
export const UPDATE_COLLABORATOR_REQUEST = "UPDATE_COLLABORATOR_REQUEST";
export const UPDATE_COLLABORATOR_SUCCESS = "UPDATE_COLLABORATOR_SUCCESS";
export const UPDATE_COLLABORATOR_ERROR = "UPDATE_COLLABORATOR_ERROR";
export const CREATE_REQUEST_ACCESS_REQUEST = "CREATE_REQUEST_ACCESS_REQUEST";
export const CREATE_REQUEST_ACCESS_SUCCESS = "CREATE_REQUEST_ACCESS_SUCCESS";
export const CREATE_REQUEST_ACCESS_ERROR = "CREATE_REQUEST_ACCESS_ERROR";
export const CREATE_TOOL_REQUEST = "CREATE_TOOL_REQUEST";
export const CREATE_TOOL_SUCCESS = "CREATE_TOOL_SUCCESS";
export const CREATE_TOOL_ERROR = "CREATE_TOOL_ERROR";
export const BULK_UPDATE_TOOL_REQUEST = "BULK_UPDATE_TOOL_REQUEST";
export const BULK_UPDATE_TOOL_SUCCESS = "BULK_UPDATE_TOOL_SUCCESS";
export const BULK_UPDATE_TOOL_ERROR = "BULK_UPDATE_TOOL_ERROR";
export const UPDATE_TOOL_REQUEST = "UPDATE_TOOL_REQUEST";
export const UPDATE_TOOL_SUCCESS = "UPDATE_TOOL_SUCCESS";
export const UPDATE_TOOL_ERROR = "UPDATE_TOOL_ERROR";
export const DELETE_TOOL_REQUEST = "DELETE_TOOL_REQUEST";
export const DELETE_TOOL_SUCCESS = "DELETE_TOOL_SUCCESS";
export const DELETE_TOOL_ERROR = "DELETE_TOOL_ERROR";
export const CREATE_R_FILE_REQUEST = "CREATE_R_FILE_REQUEST";
export const CREATE_R_FILE_SUCCESS = "CREATE_R_FILE_SUCCESS";
export const CREATE_R_FILE_ERROR = "CREATE_R_FILE_ERROR";
export const UPDATE_R_FILE_REQUEST = "UPDATE_R_FILE_REQUEST";
export const UPDATE_R_FILE_SUCCESS = "UPDATE_R_FILE_SUCCESS";
export const UPDATE_R_FILE_ERROR = "UPDATE_R_FILE_ERROR";
export const DELETE_R_FILE_REQUEST = "DELETE_R_FILE_REQUEST";
export const DELETE_R_FILE_SUCCESS = "DELETE_R_FILE_SUCCESS";
export const DELETE_R_FILE_ERROR = "DELETE_R_FILE_ERROR";
export const UPDATE_RESOURCES_REQUEST = "UPDATE_RESOURCES_REQUEST";
export const UPDATE_RESOURCES_SUCCESS = "UPDATE_RESOURCES_SUCCESS";
export const UPDATE_RESOURCES_ERROR = "UPDATE_RESOURCES_ERROR";
export const UPDATE_GOAL_REQUEST = "UPDATE_GOAL_REQUEST";
export const UPDATE_GOAL_SUCCESS = "UPDATE_GOAL_SUCCESS";
export const UPDATE_GOAL_ERROR = "UPDATE_GOAL_ERROR";
export const BULK_GOAL_UPDATE_REQUEST = "BULK_GOAL_UPDATE_REQUEST";
export const BULK_GOAL_UPDATE_SUCCESS = "BULK_GOAL_UPDATE_SUCCESS";
export const BULK_GOAL_UPDATE_ERROR = "BULK_GOAL_UPDATE_ERROR";

export const UPADTE_GOAL_LABEL_REQUEST = "UPADTE_GOAL_LABEL_REQUEST";
export const UPADTE_GOAL_LABEL_SUCCESS = "UPADTE_GOAL_LABEL_SUCCESS";
export const UPADTE_GOAL_LABEL_ERROR = "UPADTE_GOAL_LABEL_ERROR";

export const DELETE_GOAL_REQUEST = "DELETE_GOAL_REQUEST";
export const DELETE_GOAL_SUCCESS = "DELETE_GOAL_SUCCESS";
export const DELETE_GOAL_ERROR = "DELETE_GOAL_ERROR";
export const CREATE_REMINDER_REQUEST = "CREATE_REMINDER_REQUEST";
export const CREATE_REMINDER_SUCCESS = "CREATE_REMINDER_SUCCESS";
export const CREATE_REMINDER_ERROR = "CREATE_REMINDER_ERROR";
export const CREATE_LABEL_AND_UNITS_REQUEST = "CREATE_LABEL_AND_UNITS_REQUEST";
export const CREATE_LABEL_AND_UNITS_SUCCESS = "CREATE_LABEL_AND_UNITS_SUCCESS";
export const CREATE_LABEL_AND_UNITS_ERROR = "CREATE_LABEL_AND_UNITS_ERROR";

export const CLONE_PLAN_REQUEST = "CLONE_PLAN_REQUEST";
export const CLONE_PLAN_SUCCESS = "CLONE_PLAN_SUCCESS";
export const CLONE_PLAN_ERROR = "CLONE_PLAN_ERROR";

export const GET_GOAL_LIST_REQUEST = "GET_GOAL_LIST_REQUEST";
export const GET_GOAL_LIST_SUCCESS = "GET_GOAL_LIST_SUCCESS";
export const GET_GOAL_LIST_ERROR = "GET_GOAL_LIST_ERROR";

export const GET_GOAL_LIST_BY_ID_REQUEST = "GET_GOAL_LIST_BY_ID_REQUEST";
export const GET_GOAL_LIST_BY_ID_SUCCESS = "GET_GOAL_LIST_BY_ID_SUCCESS";
export const GET_GOAL_LIST_BY_ID_ERROR = "GET_GOAL_LIST_BY_ID_ERROR";

export const UPDATE_CUSTOMIZE_COLUMN_REQUEST = "UPDATE_CUSTOMIZE_PLAN_REQUEST";
export const UPDATE_CUSTOMIZE_COLUMN_SUCCESS = "UPDATE_CUSTOMIZE_PLAN_SUCCESS";
export const UPDATE_CUSTOMIZE_COLUMN_ERROR = "UPDATE_CUSTOMIZE_PLAN_ERROR";

export const GET_CUSTOMIZE_COLUMN_REQUEST = "GET_CUSTOMIZE_COLUMN_REQUEST";
export const GET_CUSTOMIZE_COLUMN_SUCCESS = "GET_CUSTOMIZE_COLUMN_SUCCESS";
export const GET_CUSTOMIZE_COLUMN_ERROR = "GET_CUSTOMIZE_COLUMN_ERROR";
export const CREATE_NEW_ACTION_ITEM_REQUEST = "CREATE_NEW_ACTION_ITEM_REQUEST";
export const CREATE_NEW_ACTION_ITEM_SUCCESS = "CREATE_NEW_ACTION_ITEM_SUCCESS";
export const CREATE_NEW_ACTION_ITEM_ERROR = "CREATE_NEW_ACTION_ITEM_ERROR";

export const CLEAR_SELECTED_GOAL_LIST = "CLEAR_SELECTED_GOAL_LIST";
export const CLEAR_UPLOAD_FILE = "CLEAR_UPLOAD_FILE";

export const UPLOAD_FILE_WITHOUT_PROJECTID_REQUEST =
  "UPLOAD_FILE_WITHOUT_PROJECTID_REQUEST";
export const UPLOAD_FILE_WITHOUT_PROJECTID_SUCCESS =
  "UPLOAD_FILE_WITHOUT_PROJECTID_SUCCESS";
export const UPLOAD_FILE_WITHOUT_PROJECTID_ERROR =
  "UPLOAD_FILE_WITHOUT_PROJECTID_ERROR";

export const CREATE_PROJECT_PLAN_REQUEST = "CREATE_PROJECT_PLAN_REQUEST";
export const CREATE_PROJECT_PLAN_SUCCESS = "CREATE_PROJECT_PLAN_SUCCESS";
export const CREATE_PROJECT_PLAN_ERROR = "CREATE_PROJECT_PLAN_ERROR";

export const BULK_UPDATE_PLAN_ACTION_CATEGORY_REQUEST =
  "BULK_UPDATE_PLAN_ACTION_CATEGORY_REQUEST";
export const BULK_UPDATE_PLAN_ACTION_CATEGORY_SUCCESS =
  "BULK_UPDATE_PLAN_ACTION_CATEGORY_SUCCESS";
export const BULK_UPDATE_PLAN_ACTION_CATEGORY_ERROR =
  "BULK_UPDATE_PLAN_ACTION_CATEGORY_ERROR";

export const UPDATE_USER_TUTORIAL_REQUEST = "UPDATE_USER_TUTORIAL_REQUEST";
export const UPDATE_USER_TUTORIAL_SUCCESS = "UPDATE_USER_TUTORIAL_SUCCESS";
export const UPDATE_USER_TUTORIAL_ERROR = "UPDATE_USER_TUTORIAL_ERROR";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  plan: {
    ...block,
    allPlans: [],
    tools: [],
    allRequest: [],
    newtool: {},
    allGoalList: [],
    selectedGoalList: null,
    customizeColumn: null,
    loadingCustomizeColumn: false,
    uploadFile: null,
    loadingUplaod: false,
    loadingDelete: false,
    loadingPlan: false,
    loadingArchive: false,
    loadingUpdatePlan: false,
    loadingNote: false,
    loadingNoteUpdate: false,
    loadingAdjustCategoryWeight: false,
    planDetails: null,
  },
};

export const PlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PLAN_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          allPlans: [action.payload, ...state.plan.allPlans],
          loading: false,
          success: true,
        },
      };
    case CREATE_PLAN_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case GET_PLAN_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case GET_PLAN_SUCCESS: {
      const newPlans = _.get(action, "payload.response", []);
      const searchQuery = _.get(action, "payload.search", "").toLowerCase();

      let existingPlans = state.plan.allPlans || [];
      let updatedPlans = searchQuery
        ? newPlans.filter((plan) =>
            plan.name.toLowerCase().includes(searchQuery)
          )
        : [
            ...newPlans
              .reduce((acc, newPlan) => {
                acc.set(newPlan.id, newPlan);
                return acc;
              }, new Map(existingPlans.map((plan) => [plan.id, plan])))
              .values(),
          ];

      return {
        ...state,
        plan: {
          ...state.plan,
          allPlans: updatedPlans,
          loading: false,
          success: true,
        },
      };
    }
    case GET_PLAN_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case GET_PLAN_BY_ID_REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          loadingPlan: true,
        },
      };
    case GET_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          planDetails: action.payload,
          loading: false,
          success: true,
          loadingPlan: false,
          loadingNote: false,
          loadingNoteUpdate: false,
          loadingAdjustCategoryWeight: false,
        },
      };
    case GET_PLAN_BY_ID_ERROR:
      return {
        ...state,
        plan: {
          ...state.plan,
          loadingPlan: false,
          loading: false,
          error: action.error,
        },
      };
    case GET_SHARED_PLAN_BY_ID_REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          loadingPlan: true,
        },
      };
    case GET_SHARED_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          planDetails: action.payload,
          loading: false,
          success: true,
          loadingPlan: false,
        },
      };
    case GET_SHARED_PLAN_BY_ID_ERROR:
      return {
        ...state,
        plan: {
          ...state.plan,
          loadingPlan: false,
          loading: false,
          error: action.error,
        },
      };
    case REMOVE_PLAN_REQUEST:
      return { ...state, plan: { ...state.plan, loadingDelete: true } };
    case REMOVE_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          allPlans: state.plan.allPlans.filter(
            (plan) => plan.id !== action.payload
          ),
          planDetails: null,
          loadingDelete: false,
          success: true,
        },
      };
    case REMOVE_PLAN_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loadingUpdatePlan: false, error: action.error },
      };
    case ARCHIVE_PLAN_REQUEST:
      return { ...state, plan: { ...state.plan, loadingArchive: true } };
    case ARCHIVE_PLAN_SUCCESS: {
      const updatedPlans = state.plan.allPlans.map((plan) =>
        plan.id === action.payload.project_plan
          ? { ...plan, is_archived: action.payload.is_archived }
          : plan
      );

      return {
        ...state,
        plan: {
          ...state.plan,
          allPlans: [...updatedPlans],
          planDetails: null,
          loadingArchive: false,
          success: true,
        },
      };
    }
    case ARCHIVE_PLAN_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loadingArchive: false, error: action.error },
      };
    case UPDATE_PLAN_REQUEST:
      return { ...state, plan: { ...state.plan, loadingUpdatePlan: true } };
    case UPDATE_PLAN_SUCCESS: {
      const updatedPlans = state.plan.allPlans.map((plan) =>
        plan.id === action.payload.id ? { ...plan, ...action.payload } : plan
      );

      return {
        ...state,
        plan: {
          ...state.plan,
          allPlans: updatedPlans,
          loadingUpdatePlan: false,
          success: true,
        },
      };
    }
    case UPDATE_PLAN_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loadingUpdatePlan: false, error: action.error },
      };
    case CREATE_ACTION_ITEM_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_ACTION_ITEM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_ACTION_ITEM_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case CREATE_NEW_ACTION_ITEM_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_NEW_ACTION_ITEM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_NEW_ACTION_ITEM_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_ACTION_ITEM_REQUEST: {
      // Map through categories to find the right one
      const processedCategories = state.plan.planDetails.categories.map(
        (category) => {
          return {
            ...category,
            // Map through action items to find and update the correct one
            action_items: category.action_items.map((item) => {
              // Check if this item is the one that needs updating
              if (item.id === action.id) {
                // Found the item, now update it with payload data using the spread operator
                return {
                  ...item,
                  ...action.payload, // Assuming action.payload.data contains the updated data for the item
                };
              }
              // If not the item, return it unchanged
              return item;
            }),
          };
        }
      );

      return {
        ...state,
        plan: {
          ...state.plan,
          planDetails: {
            ...state.plan.planDetails,
            categories: processedCategories,
          },
          loading: true, // This might not need to be set here depending on your loading logic
        },
      };
    }
    case UPDATE_ACTION_ITEM_SUCCESS:
      return {
        ...state,
        // plan: { ...state.plan, loading: false },
      };
    case UPDATE_ACTION_ITEM_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case DELETE_ACTION_ITEM_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case DELETE_ACTION_ITEM_SUCCESS:
      return {
        ...state,
      };
    case DELETE_ACTION_ITEM_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case CHANGE_ACTION_ITEM_ORDERS_REQUEST: {
      const processedCategories = state.plan.planDetails.categories.map(
        (category) => {
          if (category.id === action.payload.category) {
            return {
              ...category,
              action_items: category.action_items
                .map((item) => {
                  const itemOriginal = action.payload.actions.find(
                    (action) => action.id === item.id
                  );

                  if (itemOriginal) {
                    return {
                      ...item,
                      order_id: itemOriginal.order_id,
                    };
                  }

                  return item;
                })
                .sort((a, b) => a.order_id - b.order_id),
            };
          }

          return category;
        }
      );

      return {
        ...state,
        plan: {
          ...state.plan,
          planDetails: {
            ...state.plan.planDetails,
            categories: processedCategories,
          },
          loading: true,
        },
      };
    }
    case CHANGE_ACTION_ITEM_ORDERS_SUCCESS:
      return {
        ...state,
      };

    case CHANGE_ACTION_ITEM_ORDERS_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CHANGE_PLAN_CATEGORY_ORDERS_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CHANGE_PLAN_CATEGORY_ORDERS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_PLAN_CATEGORY_ORDERS_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CHANGE_CATEGORY_ORDERS_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CHANGE_CATEGORY_ORDERS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_CATEGORY_ORDERS_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CREATE_PLAN_CATEGORY_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_PLAN_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case CREATE_PLAN_CATEGORY_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_PLAN_CATEGORY_REQUEST: {
      // Map through categories to find the right one
      const processedCategories = state.plan.planDetails.categories.map(
        (category) => {
          const payload = action.payload;

          if (payload.start && payload.start !== null) {
            payload.start = Number(action.payload.start).toFixed(2);
          }
          if (payload.current && payload.current !== null) {
            payload.current = Number(action.payload.current).toFixed(2);
          }
          if (payload.target && payload.target !== null) {
            payload.target = Number(action.payload.target).toFixed(2);
          }

          if (category.id === action.id) {
            return {
              ...category,
              ...payload,
            };
          }

          return category;
        }
      );

      return {
        ...state,
        plan: {
          ...state.plan,
          planDetails: {
            ...state.plan.planDetails,
            categories: processedCategories,
          },
          loading: true, // This might not need to be set here depending on your loading logic
        },
      };
    }
    case UPDATE_PLAN_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_PLAN_CATEGORY_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case ADJUST_CATEGORY_WEIGHT_REQUEST:
      return {
        ...state,
        plan: { ...state.plan, loadingAdjustCategoryWeight: true },
      };
    case ADJUST_CATEGORY_WEIGHT_SUCCESS:
      return {
        loadingAdjustCategoryWeight: false,
        ...state,
      };
    case ADJUST_CATEGORY_WEIGHT_ERROR:
      return {
        ...state,
        plan: {
          ...state.plan,
          loadingAdjustCategoryWeight: false,
          error: action.error,
        },
      };
    case DELETE_PLAN_CATEGORY_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case DELETE_PLAN_CATEGORY_SUCCESS:
      return {
        ...state,
      };
    case DELETE_PLAN_CATEGORY_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CREATE_NOTES_REQUEST:
      return { ...state, plan: { ...state.plan, loadingNote: true } };
    case CREATE_NOTES_SUCCESS:
      return {
        ...state,
        loadingNote: false,
      };
    case CREATE_NOTES_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loadingNote: false, error: action.error },
      };
    case UPDATE_NOTES_REQUEST:
      return { ...state, plan: { ...state.plan, loadingNoteUpdate: true } };
    case UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        loadingNoteUpdate: false,
      };
    case UPDATE_NOTES_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loadingNoteUpdate: false, error: action.error },
      };
    case DELETE_NOTES_REQUEST:
      return { ...state, plan: { ...state.plan, loadingNoteUpdate: true } };
    case DELETE_NOTES_SUCCESS:
      return {
        ...state,
        loadingNoteUpdate: false,
      };
    case DELETE_NOTES_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loadingNoteUpdate: false, error: action.error },
      };
    case CREATE_TOOL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_TOOL_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          newtool: action.payload,
          loading: false,
          success: true,
        },
      };
    case CREATE_TOOL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case BULK_UPDATE_TOOL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case BULK_UPDATE_TOOL_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          newtool: action.payload,
          loading: false,
          success: true,
        },
      };
    case BULK_UPDATE_TOOL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case GET_TOOL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case GET_TOOL_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          tools: action.payload,
          loading: false,
          success: true,
        },
      };
    case GET_TOOL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_TOOL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case UPDATE_TOOL_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_TOOL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case DELETE_TOOL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case DELETE_TOOL_SUCCESS:
      return {
        ...state,
      };
    case DELETE_TOOL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case GET_ALL_USER_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          allRequest: action.payload,
          loading: false,
          success: true,
        },
      };
    case GET_ALL_USER_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case UPDATE_COLLABORATOR_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case UPDATE_COLLABORATOR_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_COLLABORATOR_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CREATE_REQUEST_ACCESS_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
      };
    case CREATE_REQUEST_ACCESS_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CREATE_R_FILE_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_R_FILE_SUCCESS:
      return {
        ...state,
      };
    case CREATE_R_FILE_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_R_FILE_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case UPDATE_R_FILE_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_R_FILE_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case DELETE_R_FILE_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case DELETE_R_FILE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_R_FILE_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_RESOURCES_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case UPDATE_RESOURCES_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_RESOURCES_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case BULK_GOAL_UPDATE_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case BULK_GOAL_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case BULK_GOAL_UPDATE_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPADTE_GOAL_LABEL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case UPADTE_GOAL_LABEL_SUCCESS:
      return {
        ...state,
      };
    case UPADTE_GOAL_LABEL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_GOAL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case UPDATE_GOAL_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_GOAL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case DELETE_GOAL_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case DELETE_GOAL_SUCCESS:
      return {
        ...state,
      };
    case DELETE_GOAL_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CREATE_REMINDER_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_REMINDER_SUCCESS:
      return {
        ...state,
      };
    case CREATE_REMINDER_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case CREATE_LABEL_AND_UNITS_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case CREATE_LABEL_AND_UNITS_SUCCESS:
      return {
        ...state,
      };
    case CREATE_LABEL_AND_UNITS_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case UPDATE_CUSTOMIZE_COLUMN_REQUEST:
      return { ...state, plan: { ...state.plan } };
    case UPDATE_CUSTOMIZE_COLUMN_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_CUSTOMIZE_COLUMN_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case GET_CUSTOMIZE_COLUMN_REQUEST: {
      return {
        ...state,
        plan: { ...state.plan, loadingCustomizeColumn: true },
      };
    }
    case GET_CUSTOMIZE_COLUMN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          customizeColumn: action.payload,
          loadingCustomizeColumn: false,
          success: true,
        },
      };
    case GET_CUSTOMIZE_COLUMN_ERROR:
      return {
        ...state,
        plan: {
          ...state.plan,
          loadingCustomizeColumn: false,
          error: action.error,
        },
      };

    case GET_GOAL_LIST_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case GET_GOAL_LIST_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          allGoalList: action.payload,
          loading: false,
          success: true,
        },
      };
    case GET_GOAL_LIST_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    case GET_GOAL_LIST_BY_ID_REQUEST:
      return { ...state, plan: { ...state.plan, loading: true } };
    case GET_GOAL_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          selectedGoalList: action.payload,
          loading: false,
          success: true,
        },
      };
    case GET_GOAL_LIST_BY_ID_ERROR:
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };

    case CLEAR_SELECTED_GOAL_LIST:
      return {
        ...state,
        plan: {
          ...state.plan,
          selectedGoalList: null,
        },
      };

    case CLEAR_UPLOAD_FILE:
      return {
        ...state,
        plan: {
          ...state.plan,
          uploadFile: null,
        },
      };

    case UPLOAD_FILE_WITHOUT_PROJECTID_REQUEST: {
      return { ...state, plan: { ...state.plan, loadingUplaod: true } };
    }

    case UPLOAD_FILE_WITHOUT_PROJECTID_SUCCESS: {
      return {
        ...state,
        plan: {
          ...state.plan,
          uploadFile: action.payload,
          loadingUplaod: false,
        },
      };
    }
    case UPLOAD_FILE_WITHOUT_PROJECTID_ERROR: {
      return {
        ...state,
        plan: { ...state.plan, loadingUplaod: false, error: action.error },
      };
    }

    case CREATE_PROJECT_PLAN_REQUEST: {
      return { ...state, plan: { ...state.plan, loading: true } };
    }
    case CREATE_PROJECT_PLAN_SUCCESS: {
      return {
        ...state,
        plan: { ...state.plan, loading: false },
      };
    }
    case CREATE_PROJECT_PLAN_ERROR: {
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    }
    case UPDATE_USER_TUTORIAL_REQUEST: {
      return { ...state, plan: { ...state.plan, loading: true } };
    }
    case UPDATE_USER_TUTORIAL_SUCCESS: {
      return {
        ...state,
        plan: { ...state.plan, loading: false },
      };
    }
    case UPDATE_USER_TUTORIAL_ERROR: {
      return {
        ...state,
        plan: { ...state.plan, loading: false, error: action.error },
      };
    }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          allPlans: [],
          success: false,
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
};
