import {
    GET_SYSTEM_WORKSPACES_REQUEST,
    GET_SYSTEM_AREAS_REQUEST,
    GET_HIERARCHY_REQUEST,


    ASSIGN_AREA_REQUEST,
    REMOVE_AREA_REQUEST,

    PRIORITIZE_AREAS_REQUEST,


    GET_SYSTEM_CATEGORIES_BY_AREA_REQUEST,
    GET_ROLES_BY_CATEGORY_REQUEST,
    GET_VALUES_BY_CATEGORY_REQUEST,
    ASSIGN_CATEGORY_REQUEST,
    ASSIGN_WORKSPACE_REQUEST,
    GET_USER_WORKSPACES_REQUEST,
    USER_CURENT_WORKSPACE_ID,
    GET_USER_AREAS_REQUEST,
    GET_USER_CATEGORIES_BY_AREA_REQUEST,
    ASSIGN_SUB_CATEGORY_REQUEST,
    GET_USER_SUBCATEGORIES_BY_AREA_REQUEST,
    REMOVE_CATEGORY_REQUEST,
    REMOVE_SUBCATEGORY_REQUEST,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_SUB_CATEGORY_REQUEST, REMOVE_CATEGORY_LOCAL_SUCCESS, USER_CURENT_WORKSPACE_NAME, ASSIGN_GOAL_REQUEST
} from '../reducers/MissionStatementReducer';
import {DELETE_GOAL_REQUEST} from "../reducers/PlanReducer";

export const getSystemWorkspaces = () => ({
    type: GET_SYSTEM_WORKSPACES_REQUEST
});
export const getUserWorkspaces = () => ({
    type: GET_USER_WORKSPACES_REQUEST
});

export const getSystemAreas = payload => ({
    type: GET_SYSTEM_AREAS_REQUEST,
    payload
});

export const getHierarchy = () => ({
    type: GET_HIERARCHY_REQUEST
});

export const assignWorkspace = payload => ({
    type: ASSIGN_WORKSPACE_REQUEST,
    payload
});


export const assignArea = payload => ({
    type: ASSIGN_AREA_REQUEST,
    payload
});

export const removeArea = payload => ({
    type: REMOVE_AREA_REQUEST,
    payload
});

export const removeCategory = payload => ({
    type: REMOVE_CATEGORY_REQUEST,
    payload
});

export const removeSubCategory = payload => ({
    type: REMOVE_SUBCATEGORY_REQUEST,
    payload
});

export const prioritizeAreas = payload => ({
    type: PRIORITIZE_AREAS_REQUEST,
    payload
});
export const addUserAreas = payload => (
    {
        // type: ADD_USER_AREAS,
        // payload
    });
export const getSystemCategoriesByArea = payload => ({
    type: GET_SYSTEM_CATEGORIES_BY_AREA_REQUEST,
    payload
});
export const getRolesByCategory = payload => ({
    type: GET_ROLES_BY_CATEGORY_REQUEST,
    payload
});
export const getValuesByCategory = payload => ({
    type: GET_VALUES_BY_CATEGORY_REQUEST,
    payload
});
export const assignCategory = payload => ({
    type: ASSIGN_CATEGORY_REQUEST,
    payload
});

export const assignSubCategory = payload => ({
    type: ASSIGN_SUB_CATEGORY_REQUEST,
    payload
});
export const curentUserWorkspaceId = payload => ({
    type: USER_CURENT_WORKSPACE_ID,
    payload
});

export const getUserArea = payload => ({
    type: GET_USER_AREAS_REQUEST,
    payload
});

export const getUserCategories = payload => ({
    type: GET_USER_CATEGORIES_BY_AREA_REQUEST,
    payload
});

export const getUserSubCategories = payload => ({
    type: GET_USER_SUBCATEGORIES_BY_AREA_REQUEST,
    payload
});


export const userCurentWorkspaceId = payload => ({
    type: USER_CURENT_WORKSPACE_ID,
    payload
});

export const updateCategory = payload => ({
    type: UPDATE_CATEGORY_REQUEST,
    payload
});

export const updateSubCategory = payload => ({
    type: UPDATE_SUB_CATEGORY_REQUEST,
    payload
});

export const removeUserCategoryLocal = payload => ({
    type: REMOVE_CATEGORY_LOCAL_SUCCESS,
    payload
});
export const curentUserWorkspaceName = payload => ({
    type: USER_CURENT_WORKSPACE_NAME,
    payload
});
export const assignGoal = payload => ({
    type: ASSIGN_GOAL_REQUEST,
    payload
});
export const getGoal = payload => ({
    type: ASSIGN_GOAL_REQUEST,
    payload
});
export const deleleUserGoal = payload => ({
    type: DELETE_GOAL_REQUEST,
    payload
});




