import React from "react";
import { Col, Row } from "antd";
import cc from 'classnames';

import classes from "./skeleton.module.scss";

const Skeleton = () => {
  return (
    <div className={classes.wrapper}>
      <Row gutter={2}>
        <Col span={12} style={{ paddingRight: 10 }}>
          <div class={classes.container}>
            <div class={cc(classes.row, classes.first)}>
              <div></div>
              <div></div>
            </div>
            <div class={cc(classes.row, classes.second)}>
              <div></div>
              <div></div>
            </div>
            <div class={cc(classes.row, classes.third)}>
              <div></div>
              <div></div>
            </div>
            <div class={cc(classes.row, classes.fourth)}>
              <div></div>
              <div></div>
            </div>
          </div>
        </Col>

        <Col span={12} style={{ paddingLeft: 10 }}>
          <div class={classes.container}>
            <div class={cc(classes.row, classes.first)}>
              <div></div>
              <div></div>
            </div>
            <div class={cc(classes.row, classes.second)}>
              <div></div>
              <div></div>
            </div>
            <div class={cc(classes.row, classes.third)}>
              <div></div>
              <div></div>
            </div>
            <div class={cc(classes.row, classes.fourth)}>
              <div></div>
              <div></div>
            </div>
          </div>
        </Col>
      </Row>

      <div className={classes.text}>
        <span>"Just crunching some numbers, please hold (and resist the urge to make any decisions with a coin toss)."</span>
      </div>
    </div>
  );
};

export default Skeleton;
