import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

const WeightInput = ({ value, handleChangeWeight, record }) => {
  const inputRef = useRef(null);
  const [weight, setWeight] = useState();
  const [weightValue, setWeightValue] = useState();
 
  const handleOnChange = (e) => {
    const newValue = Number(e.target.value);
      setWeightValue(newValue);

    if (newValue > 100) {
      toast("Weight should be less than 100%", {
        type: "error",
      });
    } else {
      setWeight(newValue);
    }
  };

  useEffect(() => {
    setWeight(value);
  }, [value]);

  return (
    <input
      ref={inputRef}
      type="number"
      value={weight}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          if (weightValue <= 100){
            handleChangeWeight(weightValue / 100, record)
          }
        }
      }}
      onChange={handleOnChange}
    />
  );
};

export default WeightInput;
