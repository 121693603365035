import React, { useEffect, useState } from "react";
import classes from "./AIAssistantModal.module.scss";
import { ReactComponent as AssistantIcon } from "./assistant.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import FormInput from "../../../../components/FormInput/FormInput";
import { useDispatch } from "react-redux";
import { Col, Row, Modal } from "antd";
import {
  SetAIScoringRubic
} from "../../../../redux/DecisionMatrix/GenerateAIScoringRubic/action";
import Skeleton from "../../../../scenes/DecisionMatrix/components/Skeleton/skeleton";
import { websocketConnection } from "utils/websocket";

const AIScoringRubicModal = ({
  onClose,
  onSelectedCriteriaChange,
  selectedCriteria,
  criteria,
  decisionID,
  handleApply,
}) => {
  const dispatch = useDispatch();
  const [rubric, setRubric] = useState("");
  const [isRubricLoading, setIsRubricLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState();
  const [isselectedCriteria, setIsSelectedCriteria] = useState();
  const [isTryAgain, setIsTryAgain] = useState(true);
  const [isSuggest, setIsSuggest] = useState(false);

  useEffect(() => {
    // websocketConnection?.connect();
    websocketConnection?.setMessageCallback(handleMessage);

    return () => {
      // websocketConnection.close();
      setResponse("");
      setErrors("");
    };
  }, []);

  const handleMessage = (message) => {
    let res = JSON?.parse(message);

    if (res.ping || res.pong)
      return;

    if (res?.message?.Scale) {
      let rubricValue = '';

      Object.entries(res?.message?.Scale).map(([key, value]) => {
        rubricValue += `${key} = ${value} \n`;
      });

      setRubric(rubricValue);
    }

    setErrors(res?.message?.error?.message);
    setIsRubricLoading(false);
  };

  useEffect(() => {
    return () => {
      dispatch(SetAIScoringRubic(null));
      setRubric("");
    };
  }, []);

  useEffect(()=>{
    if (rubric?.length > 0){
      setIsSuggest(false)
    }
  },[rubric])


  const handleSuggest = () => {
    if (selectedCriteria?.title?.length > 0) {
      setRubric("");
      if (isselectedCriteria) {
        setIsSuggest(true);
        setIsTryAgain(false)
        setErrors("");
        setIsRubricLoading(true);
        websocketConnection?.send(
          JSON.stringify({
            endpoint: "generate_rubric",
            model_id: decisionID,
            title: selectedCriteria?.title,
          })
        );
        // dispatch(
        //   GenerateAIScoringRubic(
        //     `?decision=${decisionId}&title=${criteriaSelected.title}`
        //   )
        // );
      }
    }
  };

  const getOptions = () => {
    const list = [{ value: "", label: "Select Criteria" }];

    criteria?.map((item) => {
      if (item.title?.length > 0) {
        list?.push({ value: item?.id, label: item?.title });
      }
    });

    return list;
  };

  const handleCriteriaChange = (value) => {
    setIsSelectedCriteria(value);
    onSelectedCriteriaChange && onSelectedCriteriaChange(_,value);
  };

  useEffect(() => {
    setIsSelectedCriteria(_, selectedCriteria?.id);
  }, [selectedCriteria?.id]);

  return (
    <>
      <Modal
        open={true}
        footer={null}
        bodyStyle={{ padding: "0" }}
        closable={null}
      >
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.heading}>
              <AssistantIcon />
              <span>AI Assistant</span>
              <span className={classes.beta}>Beta</span>
            </div>
            <button
              type="button"
              onClick={onClose}
              className={classes.close}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>
          <div className={classes.body}>
            <p>Scoring Rubric Suggestion</p>
            <p className={classes.suggestText}>
              Set names for your each scoring value.
            </p>
            <Row gutter={8}>
              <Col span={19}>
                <div className={`${classes.inputField}`}>
                  <FormInput
                    disabled={isSuggest}
                    height="44px"
                    name={"selectedCriteria"}
                    error={errors ? errors : null}
                    setFieldValue={(name, value) => {
                      handleCriteriaChange(value)
                    }}
                    value={selectedCriteria?.id}
                    type="select"
                    options={getOptions()}
                  />
                </div>
              </Col>
              <Col span={5}>
                <button
                  type="button"
                  disabled={isRubricLoading}
                  className={classes.suggest}
                  onClick={handleSuggest}
                >
                  {isRubricLoading ? "Suggesting" : "Suggest"}
                </button>
              </Col>
            </Row>
            {isRubricLoading ? (
              <Skeleton />
            ) : (
              <div className={`${classes.inputField}`}>
                <FormInput
                  style={{ minHeight: "66px", background: "#F4F4F4" }}
                  placeholder={"Enter your description"}
                  type="textarea"
                  className="mt-4"
                  name={"Scale_Text"}
                  // error={
                  //   touched.priority && errors.priority
                  //     ? errors.priority
                  //     : null
                  // }
                  setFieldValue={(name, value) => {
                    setRubric(value);
                    setResponse({ ...response, [name]: value });
                  }}
                  value={rubric}
                  disabled={response?.Scale}

                />
              </div>
            )}
          </div>

          <div className={classes.footer}>
            <button
              disabled={isTryAgain}
              type="button"
              className={classes.tryagain}
              onClick={handleSuggest}
              style={{ opacity: isTryAgain ? 0.5 : 1 }}
            >
              Try Again
            </button>
            <button
              style={{ opacity: rubric?.length == 0 ? 0.5 : 1 }}
              type="button"
              disabled={rubric?.length == 0}
              onClick={() => handleApply(selectedCriteria, rubric)}
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { AIScoringRubicModal };
