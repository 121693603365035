import React from "react";

// Components
import { AppLayout } from "components";

import NewProjectPlanner from "../NewProjectPlanner";

const ProjectPlanner = () => {
  return (
    <>
      <AppLayout type="HomePage">
        <NewProjectPlanner />
      </AppLayout>
    </>
  );
};

export default ProjectPlanner;
