import {useNavigate} from 'react-router-dom';

import Button from '../../components/Button';
import RadioButton from '../../components/RadioButton';

import styles from './styles.module.scss';

const SetupJourney = () => {
    const navigate = useNavigate();

    const onClickHandle = () => {
        navigate('/mission-statement/areas');
    }

    return (
        <div className={styles.content}>
            <div className={styles.background}>

            </div>

            <div className={styles.wrapper}>
                <div className={styles.form}>
                    <h1 className={styles.title}>Select your journey</h1>

                    <div className={styles.group}>
                        <RadioButton className={styles.radio} id="lmos" group="journey" label="LMOS" checked/>
                        <RadioButton id="standalone" group="journey" label="Standalone"/>
                    </div>

                    <Button onClick={onClickHandle}>Next</Button>
                </div>
            </div>
        </div>
    );
}

export default SetupJourney;
