import React from "react";
import {Link, useParams} from "react-router-dom";

import {sidebarItems} from "constants";
import {ReactComponent as Dashboard} from 'assets/svg/sidebar/DashboardMobileIcon.svg'
import Footer from "components/AppLayout/Footer";
import {ReactComponent as DecisionTool} from "assets/svg/DecisionTool.svg";

import DMlogo from 'assets/images/DMmobileSidebarLogo.png'

import styles from "./SidebarMobile.module.scss";
import c from 'classnames';

const getProject = pathname => {
    if (pathname.includes('project-planner'))
        return 'project-planner';
    else if (pathname.includes('decision-manager'))
        return 'decision-manager';
}

export default function Sidebar({pathname}) {
    // Local Variable
    const menuItems = sidebarItems;
    const project = getProject(pathname);
    const params = useParams();
    //
    return (
        <div className={styles.bg}>
            <div
                className={c(styles.main, {[styles.dm]: project === 'decision-manager'})}
                // collapsed={!sidebarOpen}
                // collapsible
                // collapsedWidth={90}
                // trigger={null}
                // width={274}
            >
                <div className={styles.nav}>
                    <div className={styles.img}>
                        <img src={DMlogo} alt=""/>
                    </div>
                    {menuItems.filter(item => item.project === project).map(({title, path, extraParams, key}) => (
                        <Link
                            key={key}
                            to={path}
                            className={c(styles.navItem, {[styles.active]: pathname === path || extraParams?.includes(Object.keys(params)[0])})}
                        >
                            {title === "Dashboard"
                                ? <Dashboard/>
                                : <DecisionTool/>}

                            <span>{title}</span>
                        </Link>
                    ))}
                </div>
                <Footer/>
                {/* <Menu mode="inline" selectedKeys={[pathname]}>
        {menuItems.filter(item => item.project === project).map(({ title, path }) => {
          return (
            <Menu.Item className={styles.menuitem}  key={path}>
              <Link
                to={path}
                className={!sidebarOpen ? styles.navBoxOpen : styles.navBox}
              >
                {title === "Dashboard" ? (
                  <DashboardIcon
                    style={{ marginRight: sidebarOpen ? 32 : 0 }}
                    className={
                      pathname === path ? styles.activeIcon : styles.sideicon
                    }
                  />
                ) : (
                  <DecisionTool
                    className={
                      pathname === path ? styles.activeIcon : styles.sideicon
                    }
                    style={{ marginRight: sidebarOpen ? 32 : 0 }}
                    alt="icon"
                  />
                )}
                <span
                  className={
                    !sidebarOpen ? styles.sidebartitle : styles.sidebartitleOpen
                  }
                  style={{ color: pathname === path ? "#fff" : "#0A3661" }}
                >
                  {title}
                </span>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu> */}

                {/* <div className={styles.triggericon}>
        <img src={Trigger} />
      </div> */}
            </div>
        </div>
    );
}
