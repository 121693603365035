import { FC, useEffect, useState } from "react";
import { Button } from "elements";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as Styled from "./styled";

import { ReactComponent as LockIcon } from "./assets/lock.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";

// @ts-ignore
import { useToken } from "utils/utility";

// @ts-ignore
// import { SIGN_IN_PATH } from "utils/routes";

// @ts-ignore
import { clonePlan } from "modules/actions/PlanActions";

interface IProps {
  id: string;
  isCloning: string;
}

const ViewOnly: FC<IProps> = ({ id, isCloning }) => {
  const { token } = useToken();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // @ts-ignore
  const { user: { data: userData } } = useSelector((state) => state.auth);

  const userId = userData?.external_accounts?.find((account: any) => account.decision_and_project)?.decision_and_project ?? null;

  useEffect(() => {
    const handleClone = (data: any) => {
      if (data) {
        navigate(`/project-planner/plan-details/${data.id}`, { replace: true });
      } else {
        setIsLoading(false);
      }
    };

    if (isCloning && id && userId) {
      setIsLoading(true);
      dispatch(clonePlan({ project_id: id, user_id: userId }, handleClone));
    }
  }, [isCloning, dispatch, id, userId, navigate]);

  const handleClick = () => {
    const handleClone = (data: any) => {
      if (data) {
        navigate(`/project-planner/plan-details/${data.id}`, { replace: true });
      } else {
        setIsLoading(false);
      }
    };

    if (!token) {
      Cookies.set("pp-to-clone", id);
      navigate(`/sign-in?app=pp`);
    } else {
      setIsLoading(true);
      dispatch(clonePlan({ project_id: id, user_id: userId }, handleClone));
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Col>
          <LockIcon />
          <Styled.Title>View Only</Styled.Title>
        </Styled.Col>

        <Button onClick={handleClick} disabled={isLoading}>
          <CopyIcon />
          {isLoading ? "Copying..." : "Make a copy"}
        </Button>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default ViewOnly;
