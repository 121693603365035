import React from "react";
import { Modal, Spin } from "antd";

import classes from "./ConfirmationModal.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import useWindowSize from "utils/useWindowSize";

const ConfirmationModal = ({
  data,
  isModalOpen,
  setIsModalOpen,
  handleDelete,
  loading,
}) => {
  const { width } = useWindowSize();

  return (
    <>
      {isModalOpen && (
        <div className={classes.bg}>
          <div className={classes.wrapper}>
            <div className={classes.header}>
              <span>Delete decision</span>
              <div
                onClick={() => setIsModalOpen(false)}
                className={classes.close}
              >
                <CloseOutlined style={{ color: "white" }} />
              </div>
            </div>
            <div className={classes.body}>
              <div>
                Are you sure you want to delete this decision?
                <p>{data?.problem}</p>
              </div>
              {/* <div className={classes.item}>{item.name}</div> */}
            </div>

            {width > 767 ? (
              <div className={classes.button}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                >
                  {loading ? <Spin className={classes.spinner} /> : "DELETE"}
                </button>
              </div>
            ) : (
              <div className={classes.buttonMob}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                >
                  {loading ? <Spin className={classes.spinner} /> : "DELETE"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
