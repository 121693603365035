import {
  CHECK_AUTH_REQUEST,
  SIGNUP_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  SIGNUP_LEAVE,
  CHANGE_PASSWORD_REQUEST,
  GOOGLE_LOGIN_REQUEST,
  FACEBOOK_LOGIN_REQUEST,
  APPLE_LOGIN_REQUEST,
  RESET_BLOCK_AUTH,
  RESET_PASSWORD_REQUEST,
  GET_USER_FROM_LOCAL_STORAGE_REQUEST,
  GET_USER_PROFILE_REQUEST,
  CHANGE_USER_PROFILE_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  LOGOUT_REQUEST,
  SET_MODAL,
  FORGOT_PASSWORD_RESET_VALUES,
  DELETE_ACCOUNT_REQUEST,
  INVITE_USER_REQUEST,
} from "../reducers/AuthReducer";

export const checkAuth = (params) => ({
  type: CHECK_AUTH_REQUEST,
  params,
});

export const getUserProfile = () => ({
  type: GET_USER_PROFILE_REQUEST,
});

export const changeUserProfile = (payload) => ({
  type: CHANGE_USER_PROFILE_REQUEST,
  payload,
});

export const signup = (payload, navigate) => ({
  type: SIGNUP_REQUEST,
  payload,
  navigate,
});

export const login = (payload, navigate, token, redirect) => ({
  type: LOGIN_REQUEST,
  payload,
  navigate,
  token,
  redirect,
});
export const toggle = (payload) => ({
  type: SET_MODAL,
  payload,
});
export const signupLeave = () => ({ type: SIGNUP_LEAVE });

export const googleLogin = (payload) => ({
  type: GOOGLE_LOGIN_REQUEST,
  payload,
});

export const facebookLogin = (payload) => ({
  type: FACEBOOK_LOGIN_REQUEST,
  payload,
});

export const appleLogin = (payload) => ({
  type: APPLE_LOGIN_REQUEST,
  payload,
});

export const forgotPassword = (payload, onNavigate) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
  onNavigate,
});

export const resetState = () => ({
  type: FORGOT_PASSWORD_RESET_VALUES,
});

export const changePassword = (payload) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const resetPassword = (payload, navigate) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
  navigate,
});

export const inviteUser = (payload, navigate) => ({
  type: INVITE_USER_REQUEST,
  payload,
  navigate,
});

export const updatePassword = (payload, callback) => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload,
  callback,
});

export const resetBlockAuth = (payload) => ({
  type: RESET_BLOCK_AUTH,
  payload,
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});

export const getUserFromLocalStorage = () => ({
  type: GET_USER_FROM_LOCAL_STORAGE_REQUEST,
});

export const deleteAccount = (id) => ({
  type: DELETE_ACCOUNT_REQUEST,
  id,
});
