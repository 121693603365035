import { verifyOtp } from "api";
import { SignIn } from "../SignIn/action";
import { Error } from "../../../utils/Response"
import { ActionTypes } from "./types";
import { getSimplifiedError } from "../../../utils/error";
import { ALLOCATOR_TOOL } from "../../../utils/routes";
import Cookies from "js-cookie";
import {
  LOGIN_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
} from "../../../modules/reducers/AuthReducer";
import { GET_PROJECT_NOTIFICATIONS_REQUEST } from "modules/reducers/UserReducer";


export const VerifyOTPAction = (payload, navigate) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.OTP_LOADING, payload: payload });
    
    const { data } = await verifyOtp(payload);

    dispatch({
      type: ActionTypes.OTP_SUCCESS,
      payload: data
    });

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      data: data.user,
    })

    dispatch({
      type: LOGIN_SUCCESS,
      data: {
        user: data.user,
        token: data.token
      }
    })

    dispatch({
      type: GET_PROJECT_NOTIFICATIONS_REQUEST,
    })


    if (process.env.NODE_ENV === 'development')
      Cookies.set('token-local', JSON.stringify(data));

    const dmToClone = Cookies.get("dm-to-clone");
    const ppToClone = Cookies.get("pp-to-clone");

    if (dmToClone) {
      navigate(`/shared-decision/${dmToClone}?clone=true`, { replace: true });
      Cookies.remove("dm-to-clone");
      return;
    }

    if (ppToClone) {
      navigate(`/shared-project/${ppToClone}?clone=true`, { replace: true });
      Cookies.remove("pp-to-clone");
      return;
    }

    if (data.user?.app_access && data.user?.app_access?.allocator_tool && (redirect === "dm" || redirect === "at")) {
      navigate(`/${ALLOCATOR_TOOL}`, { replace: true });
    }
    else {
      navigate(
        redirect && redirect === "pp"
          ? "/project-planner"
          : "/decision-manager",
        { replace: true }
      );
    }

  } catch (error) {
    const getError = getSimplifiedError(error)
    if (error.response && error.response.status === 403) {
      Error('Server error');
    } else {
      Error(error);
    }

    dispatch({
      type: ActionTypes.OTP_FAIL,
      payload: getError.length > 0 ? getError[0] : error
    });
  }
};
