import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { Axios } from "../../api/axios";
import { getSimplifiedError } from "../../utils/error";
import {
  GET_PROJECT_OWNER_REQUEST,
  GET_PROJECT_OWNER_SUCCESS,
  GET_PROJECT_OWNER_ERROR,
  INVITE_OWNER_REQUEST,
  INVITE_OWNER_SUCCESS,
  INVITE_OWNER_ERROR,
  REGISTER_FSM_DEVICE_REQUEST,
  GET_PROJECT_NOTIFICATIONS_REQUEST,
  GET_PROJECT_NOTIFICATIONS_SUCCESS,
  GET_PROJECT_NOTIFICATIONS_ERROR,
  READ_PROJECT_NOTIFICATION_REQUEST,
  READ_PROJECT_NOTIFICATION_SUCCESS,
  READ_PROJECT_NOTIFICATION_ERROR,
  READ_ALL_PROJECT_NOTIFICATION_REQUEST,
  READ_ALL_PROJECT_NOTIFICATION_SUCCESS,
  READ_ALL_PROJECT_NOTIFICATION_ERROR,
  GET_PROJECT_PLAN_OWNER_SUCCESS,
  GET_PROJECT_USER_REQUEST,
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_USER_SUCCESS,
  GET_PROJECT_USER_ERROR,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_ERROR,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  GET_ALL_TASK_ERROR,
  GET_ALL_TASK_REQUEST,
  GET_ALL_TASK_SUCCESS,
} from "../reducers/UserReducer";
import { GET_PLAN_BY_ID_REQUEST } from "../reducers/PlanReducer";

async function getProjectOwnersAPI(payload) {
  return Axios.get(`/api/v1/project/user/${payload}`);
}

async function inviteOwnerAPI(payload) {
  return Axios.post("/api/v1/project/user/invite_user/", payload);
}

async function getProjectUser() {
  return Axios.get("/api/v1/project/user/");
}

async function getProjectList() {
  return Axios.get("/api/v1/project/project_plan/projects_list/");
}

async function getAllTask() {
  return Axios.get(
    "api/v1/project/action_item/my_tasks/?group_by=project_plan"
  );
}

async function createTask(payload) {
  return Axios.post("/api/v1/project/action_item/", payload);
}

async function registerFSMDeviceAPI(payload) {
  return Axios.post("/api/v1/project/devices/", payload);
}

async function readProjectNotificationsAPI(payload) {
  return Axios.post("/api/v1/project/notifications/read/", payload);
}

async function readAllProjectNotificationsAPI() {
  return Axios.post("/api/v1/project/notifications/read_all/", {});
}

async function getProjectNotificationsAPI() {
  return Axios.get("/api/v1/project/notifications/");
}

function* handleGetProjectOwnersData({ payload, plan, closeModal }) {
  try {
    const { data: results } = yield call(getProjectOwnersAPI, payload);
    if (results) {
      yield put({
        type: plan ? GET_PROJECT_PLAN_OWNER_SUCCESS : GET_PROJECT_OWNER_SUCCESS,
        data: results,
      });
      closeModal && closeModal(false, true, results);
    }
  } catch (error) {
    yield put({
      type: GET_PROJECT_OWNER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleInviteOwner({ payload, handleOwnerModal, ownerPayload }) {
  try {
    const { data: results } = yield call(inviteOwnerAPI, payload);
    if (results) {
      yield put({
        type: INVITE_OWNER_SUCCESS,
        data: results,
      });

      yield put({
        type: GET_PROJECT_OWNER_REQUEST,
        payload: ownerPayload || "",
        closeModal: handleOwnerModal,
      });
      
      if(payload?.project_id){
        yield put({
          type: GET_PLAN_BY_ID_REQUEST,
          payload: payload?.project_id,
        });
      }
    }
  } catch (error) {
    yield put({
      type: INVITE_OWNER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetProjectUserData({ payload }) {
  try {
    const { data: results } = yield call(getProjectUser, payload);
    if (results) {
      yield put({
        type: GET_PROJECT_USER_SUCCESS,
        data: results,
      });
    }
  } catch (error) {
    yield put({
      type: GET_PROJECT_USER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetProjectListData({ payload }) {
  try {
    const { data: results } = yield call(getProjectList, payload);
    if (results) {
      yield put({
        type: GET_PROJECT_LIST_SUCCESS,
        data: results,
      });
    }
  } catch (error) {
    yield put({
      type: GET_PROJECT_LIST_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetAllTaskData({ payload }) {
  try {
    const { data: results } = yield call(getAllTask, payload);
    if (results) {
      yield put({
        type: GET_ALL_TASK_SUCCESS,
        data: results,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_TASK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleCreateTask({ payload, handleClose }) {
  try {
    const { data: results } = yield call(createTask, payload);
    if (results) {
      yield put({
        type: CREATE_TASK_SUCCESS,
        data: results,
      });
      yield put({
        type: GET_ALL_TASK_REQUEST,
      });

      handleClose && handleClose();
    }
  } catch (error) {
    yield put({
      type: CREATE_TASK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleRegisterFCMDevice({ payload }) {
  try {
    /* const { data: results } =  */ yield call(registerFSMDeviceAPI, payload);
    /* yield put({
      type: GET_PROJECT_NOTIFICATIONS_SUCCESS,
      data: results,
    }); */
  } catch (error) {
    // console.error(error);
    /* yield put({
      type: GET_PROJECT_NOTIFICATIONS_ERROR,
      error: getSimplifiedError(error),
    }); */
  }
}

function* handleGetProjectNotifications() {
  try {
    const { data: results } = yield call(getProjectNotificationsAPI);
    yield put({
      type: GET_PROJECT_NOTIFICATIONS_SUCCESS,
      data: results,
    });
  } catch (error) {
    yield put({
      type: GET_PROJECT_NOTIFICATIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleReadProjectNotification({ payload }) {
  try {
    const { data: results } = yield call(readProjectNotificationsAPI, payload);
    yield put({
      type: READ_PROJECT_NOTIFICATION_SUCCESS,
      data: results,
    });
    yield put({
      type: GET_PROJECT_NOTIFICATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: READ_PROJECT_NOTIFICATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleReadAllProjectNotification() {
  try {
    const { data: results } = yield call(readAllProjectNotificationsAPI);
    yield put({
      type: READ_ALL_PROJECT_NOTIFICATION_SUCCESS,
      data: results,
    });
    yield put({
      type: GET_PROJECT_NOTIFICATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: READ_ALL_PROJECT_NOTIFICATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_PROJECT_OWNER_REQUEST, handleGetProjectOwnersData),
  takeLatest(INVITE_OWNER_REQUEST, handleInviteOwner),
  takeLatest(GET_PROJECT_USER_REQUEST, handleGetProjectUserData),
  takeLatest(GET_PROJECT_LIST_REQUEST, handleGetProjectListData),
  takeLatest(GET_ALL_TASK_REQUEST, handleGetAllTaskData),
  takeLatest(CREATE_TASK_REQUEST, handleCreateTask),
  takeLatest(REGISTER_FSM_DEVICE_REQUEST, handleRegisterFCMDevice),
  takeLatest(GET_PROJECT_NOTIFICATIONS_REQUEST, handleGetProjectNotifications),
  takeLatest(READ_PROJECT_NOTIFICATION_REQUEST, handleReadProjectNotification),
  takeLatest(
    READ_ALL_PROJECT_NOTIFICATION_REQUEST,
    handleReadAllProjectNotification
  ),
]);
