import styled, { css } from "styled-components";

const iconStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 0;
`;

export const Button = styled.button<{ $visible: boolean; }>`
  margin-right: 8px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  ${iconStyles};

  ${({ $visible }) => $visible && css`
    opacity: 1;
    visibility: visible;
  `}
  filter: invert(20%) sepia(100%) saturate(7500%) hue-rotate(210deg) brightness(100%) contrast(100%);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

`;
