import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { fadeInUpMixin } from '../animated';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FormText = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  margin-bottom: 22px;
  text-align: center;
`;

export const Text = styled.span`
  color: var(--davys-gry, #4B545B);
  text-align: center;
  font-size: 14px;
  margin-bottom: 32px;
  ${fadeInUpMixin}
`;

export const AppsList = styled.div`
  display: flex;

  ${({ theme }) => theme.max('sm')`
    flex-direction: column;
    gap: 32px;
  `}
`;

export const AppItem = styled(Link)`
  width: 182px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px 9px 0px;
  text-decoration: none;
  position: relative;
  transition: background .3s;
  ${fadeInUpMixin};

  &::after {
    content: '';
    position: absolute;
    background: var(--denim, #1271A6);
    width: 10px;
    height: 10px;
    bottom: -22px;
    left: 50%;
    margin-left: -5px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .3s;
  }

  svg {
    width: 70px;
    margin-bottom: 12px;
  }

  :hover {
    background: #F3F9FA;

    ::after {
      opacity: 1;
    }

    span {
      font-weight: 600;
    }
  }
`;

export const AppItemTitle = styled.span`
  color: var(--fill-icon-oxford-blue, #06203A);
  text-align: center;
  font-size: 14px;
  line-height: 16px;
`;
