import { deleteDecision } from "api";

import { Error } from "utils/Response";
import { DeleteDecisionFromReducer } from "../GetDecision/action";
import {
  DeleteSampleDecision
} from "../ListSamples/action";

import { ActionTypes } from "./types";

export const DeleteDecision = (Data, setIsModalOpen, is_sample) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await deleteDecision(Data, is_sample);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
    if (status === 204) {
      setIsModalOpen(false);

      if (is_sample)
        dispatch(DeleteSampleDecision(Data));
      else
        dispatch(DeleteDecisionFromReducer(Data))
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
