import axios from "axios";
import { apiUserServiceURL } from "utils/constants";
import { getToken } from "utils/utility";

const UserService = axios.create({
  baseURL: apiUserServiceURL,
  withCredentials: true
});

UserService.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token)
      config.headers.Authorization = `Token ${token}`;
    return config;
  },
  (error) => Promise.reject(onError)
);

UserService.interceptors.response.use(
  (response) => {
    // return response.data
    return response;
  },
  (error) => {
    if (error && error?.response?.status === 403) {
      // Cookies.remove("lmos-token");
      window.location.pathname = "/";
    }
    if (error && error.response && error.response.status === 401) {
      // Cookies.remove("lmos-token");
    }
    return Promise.reject(error);
  }
);

export { UserService };

