interface ErrorResponse {
  response?: {
    status?: number;
    data?: any;
  };
}

export const getSimplifiedError = (error: ErrorResponse): string | string[] => {
  if (error.response?.status === 500) return 'Please contact support team.';
  const errorResponse = error.response && error.response.data;
  if (!errorResponse) {
    return 'Something went wrong, please try again later';
  }
  return getErrors(errorResponse);
};

const getError = (error: any): string[] => {
  if (typeof error === 'string') return [error];
  if (!Object.keys(error).length) return [];
  let errors: string[] = [];
  if (Array.isArray(error) && error.length) {
    errors = [error[0].message, ...error];
  } else {
    Object.keys(error).forEach((e) => {
      errors = [...errors, error[e]];
    });
  }

  return errors.filter((e) => e);
};

const getErrors = (errorResponse: any): string[] => {
  let errors: string[] = [];
  Object.keys(errorResponse).forEach((error) => {
    errors = [...errors, ...getError(errorResponse[error])];
  });
  return errors;
};
