import React from 'react';

import VerificationMain from '../../../components/Auth/Verification';

// @ts-ignore
import Layout from '../../../components/Auth/Layout';
// @ts-ignore
import { getCookie } from "../../../utils/StorageVariables";

const Verification = () => {
  return (
    <Layout overflow="initial" bg="login">
      <VerificationMain active={"true"} /> 
    </Layout>
  );
}

export default Verification;
