import { Error } from "utils/Response";
import { ActionTypes } from "./types";
import { removeFromFinalist } from "api";
import { SetDecisionDetails } from "../GetDecisionDetails/action";

export const RemoveFromFinalist =
  (Data, decisionId, resetCheckList) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const { data, status } = await removeFromFinalist(Data, decisionId);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
        dispatch(SetDecisionDetails(data));
        resetCheckList && resetCheckList();
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };
