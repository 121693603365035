import React from "react";
import classes from "./Form.module.scss";
import { Button } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  assignGoal,
  assignSubGoals,
  getRolesByGoals,
  getValuesByGoals,
  updateSubGoals,
} from "../../../../../modules/actions/MissionStatementActions";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import FormInput from "components/FormInput/FormInput";

const AddAreaGoalForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const goalForUpdate = state?.goalForUpdate || "";

  const schema = yup.object({
    location: yup.string(),
    statement: yup.string(),
    systemLabel: yup.string(),
    unit: yup.string(),
    frequency: yup.string(),
    gap_current_value: yup.number(),
    gap_target_value: yup.number(),
    gapPercent: yup.number(),
    gap: yup.number(),
    startline: yup.date(),
    deadline: yup.date(),
    pleasure: yup.string(),
    pain: yup.string(),
    obstacles: yup.string(),
    brainstorm: yup.string(),
    resources: yup.string(),
    rewards: yup.string(),
    consequences: yup.string(),
  });

  const backToGoals = (values, type) => {
    if (type === "update") {
      navigate("/mission-statement/goals");
      return;
    }
    if (type === "create") {
      dispatch(assignGoal(values));
      navigate("/mission-statement/goals");
      return;
    }
    navigate("/mission-statement/goals");
  };

  const onFormSubmit = (values) => {
    if (values) {
      if (goalForUpdate) {
        backToGoals(values, "update");
        return;
      }
      backToGoals(values, "create");
    }
  };

  return (
    <Formik
      initialValues={{
        location: "",
        statement: "",
        systemLabel: "",
        unit: "",
        frequency: "",
        gap_current_value: "",
        gap_target_value: "",
        gap: "",
        gapPercent: "",
        startline: "",
        deadline: "",
        pleasure: "",
        pain: "",
        obstacles: "",
        brainstorm: "",
        resources: "",
        rewards: "",
        consequences: "",
      }}
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={async (values) => onFormSubmit(values)}
    >
      {({ handleSubmit, setFieldValue, values, errors }) => (
        <form
          className={classes.form}
          onClick={(event) => event.stopPropagation()}
        >
          <div className={classes.header}>
            <p className={classes.headerTitle}>Set Goal</p>
            <CloseOutlined onClick={backToGoals} />
          </div>
          <FormInput
            label={"Location"}
            name={"location"}
            placeholder={"Choose goal location"}
            error={errors.location}
            setFieldValue={setFieldValue}
            value={values.location}
          />
          <FormInput
            label={"Goal Statement"}
            name={"statement"}
            placeholder={"I will..."}
            error={errors.statement}
            hint={"What?"}
            setFieldValue={setFieldValue}
            value={values.statement}
          />
          <p className={classes.title}>System of Measurement</p>
          <FormInput
            label={"Label"}
            name={"systemLabel"}
            placeholder={"Enter label"}
            error={errors.systemLabel}
            setFieldValue={setFieldValue}
            value={values.systemLabel}
          />
          <FormInput
            label={"Unit"}
            name={"unit"}
            placeholder={"Enter unit"}
            error={errors.unit}
            setFieldValue={setFieldValue}
            value={values.unit}
          />
          <p className={classes.title}>Frequency</p>
          <FormInput
            label={""}
            name={"frequency"}
            placeholder={"Choose frequency"}
            error={errors.frequency}
            setFieldValue={setFieldValue}
            value={values.frequency}
          />
          <p className={classes.title}>GAP Analysis</p>
          <div className={classes.inputValue}>
            <FormInput
              label={"Current Value"}
              name={"gap_current_value"}
              placeholder={"Enter current value"}
              error={errors.gap_current_value}
              type={"number"}
              min={"0"}
              max={"100"}
              setFieldValue={setFieldValue}
              value={values.gap_current_value}
            />
            <FormInput
              label={"Target Value"}
              name={"gapTarget"}
              placeholder={"Enter target value"}
              error={errors.gap_target_value}
              type={"number"}
              min={"0"}
              max={"100"}
              setFieldValue={setFieldValue}
              value={values.gapTarget}
            />
          </div>
          <div className={classes.inputValue}>
            <FormInput
              label={"Gap"}
              name={"gap"}
              placeholder={"Your gap"}
              error={errors.gap}
              type={"number"}
              min={"0"}
              max={"100"}
              setFieldValue={setFieldValue}
              value={values.gap}
            />
            <FormInput
              label={"Gap %"}
              name={"gapPercent"}
              placeholder={"Your gap %"}
              error={errors.gapPercent}
              type={"number"}
              min={"0"}
              max={"100"}
              setFieldValue={setFieldValue}
              value={values.gapPercent}
            />
          </div>
          <div className={classes.inputValue}>
            <FormInput
              label={"Startline"}
              name={"startline"}
              placeholder={"YYYY-MM-DD"}
              error={errors.startline}
              type={"date"}
              setFieldValue={setFieldValue}
              value={values.startline}
            />
            <FormInput
              label={"Deadline"}
              name={"deadline"}
              placeholder={"YYYY-MM-DD%"}
              error={errors.deadline}
              type={"date"}
              min={"0"}
              max={"100"}
              setFieldValue={setFieldValue}
              value={values.deadline}
            />
          </div>
          <FormInput
            label={"Pleasure"}
            name={"pleasure"}
            placeholder={"Enter..."}
            error={errors.pleasure}
            setFieldValue={setFieldValue}
            value={values.pleasure}
          />
          <FormInput
            label={"Pain"}
            name={"pain"}
            placeholder={"Enter..."}
            error={errors.pain}
            setFieldValue={setFieldValue}
            value={values.pain}
          />
          <FormInput
            label={"Obstacles"}
            name={"obstacles"}
            placeholder={"Enter..."}
            error={errors.obstacles}
            setFieldValue={setFieldValue}
            value={values.obstacles}
          />
          <FormInput
            label={"Brainstorm"}
            name={"brainstorm"}
            placeholder={"Enter..."}
            error={errors.brainstorm}
            setFieldValue={setFieldValue}
            value={values.brainstorm}
          />
          <FormInput
            label={"Resources"}
            name={"resources"}
            placeholder={"Enter..."}
            error={errors.resources}
            setFieldValue={setFieldValue}
            value={values.resources}
          />
          <FormInput
            label={"Rewards"}
            name={"rewards"}
            placeholder={"Enter..."}
            error={errors.rewards}
            setFieldValue={setFieldValue}
            value={values.rewards}
          />{" "}
          <FormInput
            label={"Consequences"}
            name={"consequences"}
            placeholder={"Enter..."}
            error={errors.consequences}
            setFieldValue={setFieldValue}
            value={values.consequences}
          />
          <div className={classes.footerNavigation}>
            <Button
              className={`${classes.button} ${classes.buttonCancel}`}
              onClick={backToGoals}
            >
              CANCEL
            </Button>
            <Button className={classes.button} onClick={handleSubmit}>
              SAVE
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddAreaGoalForm;
