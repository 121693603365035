import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { InputField, Button } from "elements";

import avatarIcon from "./avatar.svg";
import deleteIcon from "./delete.svg";

// @ts-ignore
import { Axios } from "api/axios";
import Notify from "utils/notify";

import {
  Wrapper,
  FormWrapper,
  List,
  Hr,
  Content,
  Footer,
  ListPlaceholder,

  // Email Item
  Item,
  EmailText,
  DeleteBtn,
} from "./styled";

interface IProps {
  decisionId: number;
  onSuccess: () => void;
}

interface IItem {
  id: number;
  email: string;
}

interface IItemProps {
  item: IItem;
  onRemove: (id: number) => void;
}

const EmailItem: FC<IItemProps> = ({ item, onRemove }) => {
  const { id, email } = item;

  const handleRemove = () => {
    onRemove(id);
  }

  return (
    <Item>
      <img src={avatarIcon} alt="" />
      <EmailText>{email}</EmailText>
      <DeleteBtn title="Remove this email" onClick={handleRemove}>
        <img src={deleteIcon} alt="" />
      </DeleteBtn>
    </Item>
  );
};

const PublicLinkModal: FC<IProps> = ({ decisionId, onSuccess }) => {
  const [value, setValue] = useState("");
  const [list, setList] = useState<IItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const changeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    const emailExists = list.some((item) => item.email === e.target.value);

    if (emailExists) {
      e.target.setCustomValidity("This email is already added.");
    } else {
      e.target.setCustomValidity("");
    }

    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setList([...list, { id: new Date().getMilliseconds(), email: value }]);
    setValue("");
  };

  const handleShare = async () => {
    setLoading(true);

    /* try {
      const response = await Axios.post(`/api/v1/decision_matrix/decisions/remove-emails/`, {
        decision: decisionId,
        emails: list.map((item) => item.email),
      });

      if (response?.data) {
        setLoading(false);
        onSuccess();
      }
    } catch (error) {
      setLoading(false);
      Notify.error(error, true);
    } */

    try {
      const response = await Axios.post(`/api/v1/decision_matrix/decisions/add-emails/`, {
        decision: decisionId,
        emails: list.map((item) => item.email),
      });

      if (response?.data) {
        setLoading(false);
        onSuccess();
      }
    } catch (error) {
      setLoading(false);
      Notify.error(error, true);
    }
  };

  const handleRemove = (id: number) => {
    setList(list.filter((item) => item.id !== id));
  }

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit}>
        <InputField
          value={value}
          type="email"
          parentStyle={{ flex: 1 }}
          placeholder="Type Email"
          required
          onChange={changeHandle}
        />

        <Button styleType="secondary">Add</Button>
      </FormWrapper>

      <Hr $mb={0} />

      <Content>
        {list.length ? (
          <List>
            {list.map((item) => (
              <EmailItem item={item} onRemove={handleRemove} key={item.id} />
            ))}
          </List>
        ) : (
          <ListPlaceholder>
            There is no selected Email addresses yet.
          </ListPlaceholder>
        )}
      </Content>

      <Hr $mt={0} />

      <Footer>
        <Button
          onClick={handleShare}
          disabled={
            !list.length || loading
          }
        >
          {loading ? "Loading..." : "Share"}
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default PublicLinkModal;
