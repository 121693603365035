import styles from './styles.module.scss';
import c from 'classnames';

const RadioButton = ({ id, group, label, checked, className, props }) => {
  return (
    <div className={c(styles.radio, className)} {...props}>
      <input type="radio" id={id} name={group} checked={checked} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default RadioButton;
