import { css, keyframes } from 'styled-components';

const fadeInUp = keyframes`
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeInUpMixin = css`
  transform: translateY(5px);
  opacity: 0;

  ${({ $animate, $animateDelay = 0 }) => $animate && css`
    animation: ${fadeInUp} 0.4s ease-out forwards ${$animateDelay}ms;
  `}
`;

const zoomIn = keyframes`
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const zoomInMixin = css`
  transform: scale(0);
  opacity: 0;

  ${({ $animate, $animateDuration = 400, $animateDelay = 0 }) => $animate && css`
    animation: ${zoomIn} ${$animateDuration}ms ease-out forwards ${$animateDelay}ms;
  `}
`;

const zoomInSlightly = keyframes`
  0% {
    transform: rotate(-2deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
`;

export const zoomInSlightlyMixin = css`
  transform: rotate(-4deg);
  opacity: 0;

  ${({ $animate, $animateDuration = 400, $animateDelay = 0 }) => $animate && css`
    animation: ${zoomInSlightly} ${$animateDuration}ms ease-out forwards ${$animateDelay}ms;
  `}
`;
