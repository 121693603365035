import React, { useState } from 'react';

import cc from 'classnames';
import styles from './styles.module.scss';

// import { ReactComponent as HelpIcon } from "./assets/help.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { Spin } from 'antd';

const HelpIconSrc = "https://i.ibb.co/SNrFHvP/information-pamphlet-1.png";

const HelpPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onLoadHandle = () => {
    setIsLoading(false);
  }

  const onCloseHandle = () => {
    setIsOpen(false);
    setIsLoading(false);
  }

  const onToggleHandle = () => {
    if (isOpen) {
      setIsOpen(false);
      setIsLoading(true);
    } else {
      setIsOpen(true);
    }
  }

  return (
    <>
      <button className={cc(styles.button, { [styles.active]: isOpen })} title="Decision Matrix Training" type="button" onClick={onToggleHandle}>
        <figure>
          {isOpen ? <CloseIcon width={40} height={40} /> : <img width={24} height={24} src={HelpIconSrc} />}
        </figure>
      </button>

      <div className={cc(styles.body, { [styles.active]: isOpen })}>
        <div className={cc(styles.modal, { [styles.modalActive]: isOpen })}>
          {isOpen && (
            <Spin tip="Loading" size="large" spinning={isLoading}>
              <iframe onLoad={onLoadHandle} src="https://scribehow.com/page-embed/Decision_Matrix_Training__gmWRI_mOQsiEgwg1FZe0zA" width="640" height="480" allowfullscreen frameborder="0" />
            </Spin>
          )}
        </div>

        <div className={styles.background} onClick={onCloseHandle} />
      </div>
    </>
  );
}

export default HelpPopup;
