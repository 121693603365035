import { generateFacts } from "api";
import { ActionTypes } from "./types";

export const GenerateFacts = (optionCriteriaId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await generateFacts(optionCriteriaId);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    var text = error?.response?.data?.message
      ? error?.response?.data?.message
      : error?.response?.data || "";
    error?.response?.data?.fields?.forEach((element) => {
      for (const [key, value] of Object.entries(element)) {
        text = text + ", " + key + ":" + value;
      }
    });
    if (error?.response?.status === 500) {
      text = "Something went wrong!";
    }
    dispatch({
      type: ActionTypes.ERROR,
      payload: text,
    });
  }
};
export const SetGenerateFactsNull = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
