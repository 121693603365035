import React, { useEffect, useState } from 'react';
import classes from "./Form.module.scss";
import {
    Button,
} from 'antd';
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    assignSubCategory,
    getRolesByCategory,
    getValuesByCategory, updateSubCategory
} from "../../../../../modules/actions/MissionStatementActions";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import * as yup from 'yup';
import FormInput from 'components/FormInput/FormInput';

const AddSubCategoryForm = () => {
    const dispatch = useDispatch();
    const { categoryRoles, categoryValues } = useSelector(state => state.missionStatement);
    const [roles, setRoles] = useState([]);
    const [userValues, setUserValues] = useState([]);

    const navigate = useNavigate();
    const { state: { category, id, categoryForUpdate } } = useLocation();


    useEffect(() => {
        if (id) {
            dispatch(getRolesByCategory(id))
            dispatch(getValuesByCategory(id))
        }
    }, [id])

    useEffect(() => {
        if (categoryRoles.data[0]) {
            setRoles([...categoryRoles.data])
        }
    }, [categoryRoles])

    useEffect(() => {
        if (categoryValues.data[0]) {
            setUserValues([...categoryValues.data])
        }
    }, [categoryValues])

    const backToCategory = (name = '1') => {
        navigate('/mission-statement/categories', { state: { update: name } })
    }

    const schema = yup.object({
        name: yup.string().required("Enter this field").min(5, "Enter at least 5 symbols"),
        weight: yup.string().required("Enter this field"),
        description: yup.string().required("Enter this field").min(5, "Enter at least 5 symbols"),
        priority: yup.string().required("Enter this field"),
    });

    const onFormSubmit = (values) => {
        if (!categoryForUpdate) {
            dispatch(assignSubCategory({ parent_category: +category.id, is_custom: true, ...values }))
            backToCategory()
        }
        dispatch(updateSubCategory({
            id: categoryForUpdate.id,
            body: { ...values, parent_category: categoryForUpdate.parent_category, is_custom: true }
        }))
        backToCategory(values.name)
    }

    return (
        <Formik
            initialValues={{
                name: categoryForUpdate?.name || '',
                weight: categoryForUpdate?.weight || '',
                description: categoryForUpdate?.description || '',
                priority: categoryForUpdate?.priority || '',
                is_custom: categoryForUpdate?.is_custom || false,
            }}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={async (values) => onFormSubmit(values)}
        >
            {({ handleSubmit, setFieldValue, values, errors }) => (
                <form
                    className={classes.form}
                    onClick={(event) => event.stopPropagation()}
                >
                    <div className={classes.header}>
                        <p className={classes.headerTitle}>New Subcategory</p>
                        <CloseOutlined onClick={backToCategory} />
                    </div>
                    <FormInput
                        label={"Name"}
                        name={"name"}
                        placeholder={'Write name of subcategory'}
                        error={errors.name}
                        setFieldValue={setFieldValue}
                        value={values.name}
                    />
                    <FormInput
                        label={"Weight"}
                        name={"weight"}
                        placeholder={'Write weight'}
                        error={errors.weight}
                        type={"number"}
                        min={"1"}
                        max={"10"}
                        setFieldValue={setFieldValue}
                        value={values.weight}
                    />
                    <FormInput
                        label={"Description"}
                        name={"description"}
                        placeholder={'Describe your subcategories'}
                        error={errors.description}
                        setFieldValue={setFieldValue}
                        value={values.description}
                    />
                    <FormInput
                        label={"Priority"}
                        name={"priority"}
                        placeholder={'Write priority'}
                        error={errors.priority}
                        type={"number"}
                        min={"1"}
                        max={"10"}
                        setFieldValue={setFieldValue}
                        value={values.priority}
                    />
                    <div className={classes.footerNavigation}>
                        <Button
                            className={`${classes.button} ${classes.buttonCancel}`}
                            onClick={backToCategory}
                        >
                            CANCEL
                        </Button>
                        <Button
                            className={classes.button}
                            onClick={handleSubmit}
                        >SAVE
                        </Button>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export { AddSubCategoryForm }