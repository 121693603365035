export interface UnitDataItem {
  id?: string;
  label: string;
  value: string;
  items: string[];
}

export const unitsData: UnitDataItem[] = [
  {
    label: "Weight",
    value: 'weight',
    items: ['kg',  'lbs']
  },
  {
    label: "Height",
    value: 'height',
    items: ['cm', 'ft', 'inches']
  },
  {
    label: "Distance",
    value: 'distance',
    items: ['km', 'miles']
  },
  {
    label: "Energy",
    value: 'energy',
    items: ['kcal', 'kj']
  },
  {
    label: "Time",
    value: 'time',
    items: ['minutes', 'hours']
  },
  {
    label: "Volume",
    value: 'volume',
    items: ['cups', 'fl oz', 'ml']
  },
  {
    label: "Count",
    value: 'count',
    items: ['#']
  },
  {
    label: "Scale",
    value: 'scale',
    items: ['%']
  },
  {
    label: "Currency",
    value: 'currency',
    items: ['$', '£', '€']
  }
];
