import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from "elements";

interface IProps {
  active?: boolean;
  loading?: boolean;
}

const AppLoading: FC<IProps> = ({ active, loading }) => {
  const [isActive, setIsActive] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (active || loading) {
      setShouldRender(true); // Show component immediately
      timeoutId = setTimeout(() => {
        setIsActive(true); // Activate the animation after 50ms
      }, 50);
    } else {
      setIsActive(false); // Deactivate the animation
      timeoutId = setTimeout(() => {
        setShouldRender(false); // Hide the component after 200ms (when the animation is done)
      }, 200);
    }

    return () => clearTimeout(timeoutId);
  }, [active, loading]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Wrapper $active={isActive}>
      <Spinner />
    </Wrapper>
  );
};

export { AppLoading };

export const Wrapper = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #00000049;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;
