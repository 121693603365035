// import TagManager from "react-gtm-module";

export const GET_PROJECT_OWNER_REQUEST = "GET_PROJECT_OWNER_REQUEST";
export const GET_PROJECT_OWNER_SUCCESS = "GET_PROJECT_OWNER_SUCCESS";
export const GET_PROJECT_PLAN_OWNER_SUCCESS = "GET_PROJECT_PLAN_OWNER_SUCCESS";
export const GET_PROJECT_OWNER_ERROR = "GET_PROJECT_OWNER_ERROR";

export const INVITE_OWNER_REQUEST = "INVITE_OWNER_REQUEST";
export const INVITE_OWNER_SUCCESS = "INVITE_OWNER_SUCCESS";
export const INVITE_OWNER_ERROR = "INVITE_OWNER_ERROR";

export const GET_PROJECT_USER_REQUEST = "GET_PROJECT_USER_REQUEST";
export const GET_PROJECT_USER_SUCCESS = "GET_PROJECT_USER_SUCCESS";
export const GET_PROJECT_USER_ERROR = "GET_PROJECT_USER_ERROR";

export const GET_PROJECT_LIST_REQUEST = "GET_PROJECT_LIST_REQUEST";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_ERROR = "GET_PROJECT_LIST_ERROR";

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR";

export const GET_ALL_TASK_REQUEST = "GET_ALL_TASK";
export const GET_ALL_TASK_SUCCESS = "GET_ALL_TASK_SUCCESS";
export const GET_ALL_TASK_ERROR = "GET_ALL_TASK_ERROR";

export const REGISTER_FSM_DEVICE_REQUEST = "REGISTER_FSM_DEVICE_REQUEST";

export const GET_PROJECT_NOTIFICATIONS_REQUEST =
  "GET_PROJECT_NOTIFICATIONS_REQUEST";
export const GET_PROJECT_NOTIFICATIONS_SUCCESS =
  "GET_PROJECT_NOTIFICATIONS_SUCCESS";
export const GET_PROJECT_NOTIFICATIONS_ERROR =
  "GET_PROJECT_NOTIFICATIONS_ERROR";
export const READ_PROJECT_NOTIFICATION_REQUEST =
  "READ_PROJECT_NOTIFICATION_REQUEST";
export const READ_PROJECT_NOTIFICATION_SUCCESS =
  "READ_PROJECT_NOTIFICATION_SUCCESS";
export const READ_PROJECT_NOTIFICATION_ERROR =
  "READ_PROJECT_NOTIFICATION_ERROR";
export const READ_ALL_PROJECT_NOTIFICATION_REQUEST =
  "READ_ALL_PROJECT_NOTIFICATION_REQUEST";
export const READ_ALL_PROJECT_NOTIFICATION_SUCCESS =
  "READ_ALL_PROJECT_NOTIFICATION_SUCCESS";
export const READ_ALL_PROJECT_NOTIFICATION_ERROR =
  "READ_PROJECT_NOTIFICATION_ERROR";

export const CLOSE_INITIAL_SETUP_MODAL = "CLOSE_INITIAL_SETUP_MODAL";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  invitedUser: { ...block, data: {} },
  notifications: { ...block, data: {} },
  projectOwners: {
    ...block,
    count: 0,
    users: [],
    planUsers: [],
    projectList: [],
    projectUser: [],
    allTask: [],
    projectUserLoading: false,
  },
  pageIndex: 1,
  userQuery: "",
  showInitialSetupModal: true,
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_OWNER_REQUEST:
      return {
        ...state,
        projectOwners: { ...state.projectOwners, loading: true },
      };
    case GET_PROJECT_OWNER_SUCCESS:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          success: true,
          users: action.data,
        },
      };
    case GET_PROJECT_PLAN_OWNER_SUCCESS:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          success: true,
          planUsers: action.data,
        },
      };
    case GET_PROJECT_OWNER_ERROR:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          error: action.error,
        },
      };
    case INVITE_OWNER_REQUEST:
      return { ...state, invitedUser: { ...state.invitedUser, loading: true } };
    case INVITE_OWNER_SUCCESS:
      return {
        ...state,
        invitedUser: {
          ...state.invitedUser,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case INVITE_OWNER_ERROR:
      return {
        ...state,
        invitedUser: {
          ...state.invitedUser,
          loading: false,
          error: action.error,
        },
      };

    case GET_PROJECT_USER_REQUEST:
      return {
        ...state,
        projectOwners: { ...state.projectOwners, projectUserLoading: true },
      };
    case GET_PROJECT_USER_SUCCESS:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          projectUserLoading: false,
          success: true,
          projectUser: action.data,
        },
      };
    case GET_PROJECT_USER_ERROR:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          projectUserLoading: false,
          error: action.error,
        },
      };

    case GET_PROJECT_LIST_REQUEST:
      return {
        ...state,
        projectOwners: { ...state.projectOwners, loading: true },
      };
    case GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          success: true,
          projectList: action.data,
        },
      };
    case GET_PROJECT_LIST_ERROR:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          error: action.error,
        },
      };

    case GET_ALL_TASK_REQUEST:
      return {
        ...state,
        projectOwners: { ...state.projectOwners, loading: true },
      };
    case GET_ALL_TASK_SUCCESS:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          success: true,
          allTask: action.data,
        },
      };
    case GET_ALL_TASK_ERROR:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_TASK_REQUEST:
      return {
        ...state,
        projectOwners: { ...state.projectOwners, loading: true },
      };

    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          success: true,
        },
      };

    case CREATE_TASK_ERROR:
      return {
        ...state,
        projectOwners: {
          ...state.projectOwners,
          loading: false,
          error: action.error,
        },
      };

    case GET_PROJECT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: { ...state.notifications, loading: true },
      };
    case GET_PROJECT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_PROJECT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: action.error,
        },
      };
    case READ_PROJECT_NOTIFICATION_REQUEST:
      return {
        ...state,
        notifications: { ...state.notifications, loading: true },
      };
    case READ_PROJECT_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case READ_PROJECT_NOTIFICATION_ERROR:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: action.error,
        },
      };
    case READ_ALL_PROJECT_NOTIFICATION_REQUEST:
      return {
        ...state,
        notifications: { ...state.notifications, loading: true },
      };
    case READ_ALL_PROJECT_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };
    case READ_ALL_PROJECT_NOTIFICATION_ERROR:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: action.error,
        },
      };

    case CLOSE_INITIAL_SETUP_MODAL:
      return {
        ...state,
        showInitialSetupModal: false,
      };

    default:
      return state;
  }
};
