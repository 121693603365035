import React, { useState } from "react";
import classes from "scenes/DecisionMatrix/components/compareProCons/compareProCons.module.scss"
import { EllipsisOutlined } from "@ant-design/icons";
// import { Popover } from "antd";
// import { Axios } from "../../../../api/axios";
// import { apiBaseURL } from "../../../../utils/constants";



const MitigationOption = ({
  index,
  editCons,
  mitigationRef,
  addMitigation,
  setAddMitigation,
  cons,
  setCons,
  mitigationLimitError,
  setEditMitigationLimitError
}) => {
  return (
    <div key={+cons[index] + 0.33} className={classes.prosWrapper}>
      {cons[index]?.mitigation && (addMitigation !== index) ? (
        <div className={classes.mitigation}>
          <div>{cons[index]?.mitigation}</div>
        </div>
      ) : (
        addMitigation === index && (
          <div>
            <textarea
            rows="1"
            onBlur={() => {
              editCons(index);
            }}
            onChange={(e) =>{
              setEditMitigationLimitError(e.target.value.replaceAll('\n', '').length > 2000)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                editCons(index);
                // setShowInput('')
              }
            }}
            // onChange={(e) => localUpdateCons(e.target.value)}
            ref={mitigationRef}
            value={mitigationRef?.current?.value}
            placeholder={"Enter a mitigation (Optional)"}
            className={classes.inputMitigation}
            type="text" 
            autoFocus={true}
            style={{borderColor:addMitigation === index && mitigationLimitError ? '#f51e14' : null, marginBottom:addMitigation === cons[index] && mitigationLimitError ? '7px' : null}}   
          >
          </textarea>
          {addMitigation === index && mitigationLimitError && (<div style={{color:'#f51e14', fontSize:12, marginLeft:15}}>Mitigation cannot be greater than 2000 characters.</div>)}
          </div>
        )
      )}
      {/* <hr className={classes.br} /> */}
    </div>
  );
};

export { MitigationOption };
