import styled from "styled-components";

export const Content = styled.div`
  width: 553px;
  z-index: 1000;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;
`;

export const Disclaimer = styled.span`
  color: var(--Text-Overline-Dark-gray, #878787);
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
`;
