import styles from './styles.module.scss';
import c from 'classnames';

const initialData = {
  first: {
    step: 1,
    text: 'Step 1',
    hide: false,
    active: false,
    complete: false
  },
  second: {
    step: 2,
    text: 'Step 2',
    hide: false,
    active: false,
    complete: false
  },
  third: {
    step: 3,
    text: 'Step 3',
    hide: false,
    active: false,
    complete: false,
    last: false,
  }
}

const Headline = ({ data = initialData }) => {
  const settings = {
    first: {
      ...initialData.first,
      ...data.first
    },
    second: {
      ...initialData.second,
      ...data.second
    },
    third: {
      ...initialData.third,
      ...data.third
    }
  };

  return (
    <div className={c(styles.headline, { [styles.hideFirst]: settings.first.hide }, { [styles.activeFirst]: settings.first.active }, { [styles.completeFirst]: settings.first.complete }, { [styles.hideSecond]: settings.second.hide }, { [styles.activeSecond]: settings.second.active }, { [styles.hideThirds]: settings.third.hide }, { [styles.lastThirds]: settings.third.last })}>
      <span></span>
      <figure>
        <p>{settings.first.step}</p>
        <figcaption>{settings.first.text}</figcaption>
      </figure>
      <span></span>

      <figure>
        <p>{settings.second.step}</p>
        <figcaption>{settings.second.text}</figcaption>
      </figure>
      <span></span>

      <figure>
        <p>{settings.third.step}</p>
        <figcaption>{settings.third.text}</figcaption>
      </figure>
      <span></span>
    </div>
  );
}

export default Headline;
