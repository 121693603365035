import { updateDecision } from "api";
import { Error, Success } from "utils/Response";
import { SetDecision } from "../GetDecision/action";
import { SetDecisionDetails } from "../GetDecisionDetails/action";
import { ActionTypes } from "./types";

export const RequestEditDecisionLoading = (action) => async (dispatch) => {
  dispatch({
    type: ActionTypes.EDIT,
    payload: action,
  });
};

export const UpdateDecision = (Data, isDecisionDetail, onClose) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await updateDecision(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      if (isDecisionDetail) {
        dispatch(SetDecisionDetails(data));
        // onClose();
      } else {
        dispatch(SetDecision(data));
      }
      // navigate && navigate("/decision-manager");
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
