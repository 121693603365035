import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Outlet} from 'react-router-dom';

import styles from './styles.module.scss';

import {getHierarchy, getSystemWorkspaces} from 'modules/actions/MissionStatementActions';
import {AppLayout} from "components/index";

const InitialSetup = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSystemWorkspaces());
        dispatch(getHierarchy());
    }, []);

    return (
        <AppLayout>
            <div className={styles.page}>
                <div className={styles.content}>
                    <Outlet/>
                </div>
            </div>
        </AppLayout>
    )
}

export default InitialSetup;
