import { Col, Popover, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowUp } from "../../assets/svg/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../assets/svg/down-arrow.svg";
import { EllipsisOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetDescriptionsSummary } from "redux/DecisionMatrix/GetDecisionSummary/action";

const CompareSummary = ({ optionId }) => {
  const [listData, setListData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetDescriptionsSummary(optionId));
  }, []);
  const { success: list } = useSelector((state) => state.getSummaryReducer);

  useEffect(() => {
    setListData(list);
  }, [list]);
  const content = (
    <div>
      <button type="button" onClick={() => {}}>
        EDIT
      </button>
      <button type="button" onClick={() => {}}>
        DELETE
      </button>
    </div>
  );
  return (
    <Container>
      <Header>
        <Heading>Compare</Heading>
        <Quantity>
          <TextGreen>{listData?.pros?.length}</TextGreen>
          <Text>/</Text>
          <TextRed>{listData?.cons?.length}</TextRed>
        </Quantity>
      </Header>
      <Lists>
        <Rows>
          <Col span={12}>
            <ProsContainer>
              <ProsHeader>
                <SubHeading>Pros</SubHeading>
              </ProsHeader>
              {listData?.pros?.map((item, i) => (
                <List key={i}>
                  <Span>{i + 1}.</Span>
                  <Content>
                    <Small>{item?.criteria_title}</Small>
                    <Para>{item?.context}</Para>
                  </Content>
                  <ActionItem>
                    <Sorting>
                      <Dot></Dot>
                      <SmallText>High</SmallText>
                    </Sorting>
                    <ButtonWrapper>
                      <Button disabled>
                        <ArrowUp />
                      </Button>
                      <Button disabled>
                        <DownArrow />
                      </Button>
                    </ButtonWrapper>
                    <PopoverContainer>
                      <Popover
                      // content={content}
                      // onClick={(e) => {
                      //   e?.stopPropagation();
                      // }}
                      // trigger="focus"
                      >
                        <EllipsisOutlined rotate={90} />
                      </Popover>
                    </PopoverContainer>
                  </ActionItem>
                </List>
              ))}
            </ProsContainer>
          </Col>
          <Col span={12}>
            <ConsContainer>
              <ConsHeader>
                <SubHeading>Cons</SubHeading>
              </ConsHeader>
              {listData?.cons?.map((item, i) => (
                <List key={i}>
                  <Span>{i + 1}.</Span>
                  <Content>
                    <Small>{item?.criteria_title}</Small>
                    <Para>{item?.context}</Para>
                    {item?.mitigation && (
                      <Migitation>Migitation: {item?.mitigation}</Migitation>
                    )}
                  </Content>
                  <ActionItem>
                    <Sorting>
                      <Dot></Dot>
                      <SmallText>High</SmallText>
                    </Sorting>
                    <ButtonWrapper>
                      <Button disabled>
                        <ArrowUp />
                      </Button>
                      <Button disabled>
                        <DownArrow />
                      </Button>
                    </ButtonWrapper>
                    <PopoverContainer>
                      <Popover
                      // content={content}
                      // onClick={(e) => {
                      //   e?.stopPropagation();
                      // }}
                      // trigger="focus"
                      >
                        <EllipsisOutlined rotate={90} />
                      </Popover>
                    </PopoverContainer>
                  </ActionItem>
                </List>
              ))}
            </ConsContainer>
          </Col>
        </Rows>
      </Lists>
    </Container>
  );
};

export default CompareSummary;

const PopoverContainer = styled.div`
  transform: translateY(-3px);
`;
const Container = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
const Heading = styled.h2`
  color: #1b2a3d;
  font-size: 16px;
  line-height: 20px;
`;
const Quantity = styled.div`
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 28px;
  border-radius: 6px;
`;
const Text = styled.p`
  line-height: 0;
  margin: 0;
  padding: 0;
  color: #d9dbd7;
  font-size: 12px;
  font-weight: 600;
`;
const TextRed = styled.p`
  line-height: 0;
  margin: 0;
  padding: 0;
  color: #ff8071;
  font-size: 12px;
  font-weight: 600;
`;
const TextGreen = styled.p`
  line-height: 0;
  margin: 0;
  padding: 0;
  color: #89c740;
  font-size: 12px;
  font-weight: 600;
`;
const Lists = styled.div`
  border: 1px solid #f5f5f5;
  margin-top: 1em;
  height: 100vh;
  width: 100%;
`;

const ProsContainer = styled.div`
  width: 100%;
  /* border: 1px solid #f5f5f5; */
  overflow-y: auto;
  height: 100vh;
  position: relative;
`;
const ConsContainer = styled.div`
  width: 100%;
  /* border: 1px solid #f5f5f5; */
  overflow-y: auto;
  height: 100vh;
  position: relative;
`;
const ProsHeader = styled.div`
  border-top-left-radius: 6px;
  width: 100%;
  background-color: #89c740;
  padding: 10px 10px 10px 16px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;
const ConsHeader = styled.div`
  border-top-right-radius: 6px;
  width: 100%;
  background-color: #ff8071;
  padding: 10px 10px 10px 16px;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 10;
`;
const SubHeading = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 0;
`;
const List = styled.div`
  border: 1px solid #f5f5f5;
  padding: 10px 4px 10px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`;
const Span = styled.p`
  color: #a3a3a3;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
`;
const Content = styled.div`
  width: 80%;
`;
const ActionItem = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Small = styled.p`
  color: #1271a6;
  font-size: 9px;
  font-family: Inter;
  font-weight: 500;
  line-height: 12px;
  margin: 0;
  padding: 0;
`;
const Migitation = styled.p`
  margin: 0;
  padding: 0;
  color: #858e99;
  font-size: 11px;
  font-family: Inter;
  line-height: 16px;
`;
const Para = styled.p`
  margin: 0;
  padding: 0;
  color: #404040;
  font-size: 14px;
  font-family: Inter;
  line-height: 18px;
`;
const Rows = styled(Row)`
  width: 100%;
`;
const Sorting = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;
const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f00000;
  margin: 0;
  padding: 0;
`;
const SmallText = styled.p`
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
  padding-right: 3px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  padding-left: 4px;
  transform: translateY(2px);
`;
const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Icon = styled.img`
  height: 8px;
`;
