import { Table, Col, Progress } from 'antd'
import { COLORS } from 'constants/colors'
import React from 'react'
const columns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    align: 'center',
    render: text => <span>{text}</span>
  },
  {
    title: 'STATUS',
    className: 'column-money',
    dataIndex: 'status',
    align: 'center',
    render: text => <Progress percent={50} strokeColor={COLORS.slider} showInfo={false} />
  },
  {
    title: 'EXECUTION',
    dataIndex: 'execution',
    align: 'center'
  },
  {
    title: 'GOAL',
    dataIndex: 'goal',
    align: 'center'
  },
  {
    title: 'AREA',
    align: 'center',
    dataIndex: 'area'
  },
  {
    title: 'CATEGORY',
    align: 'center',
    dataIndex: 'category'
  },
  {
    title: 'SUBCATEGORY',
    align: 'center',
    dataIndex: 'subcategory'
  },
  {
    title: 'START',
    align: 'center',
    dataIndex: 'start'
  },
  {
    title: 'DEADLINE',
    align: 'center',
    dataIndex: 'deadline'
  }
]
const data = [
  {
    key: '1',
    name: 'Really long content item',
    status: '',
    execution: 'Equipment',
    goal: '-',
    area: '9',
    category: '0%',
    subcategory: '0%',
    start: '05.06.2021',
    deadline: '-'
  },
  {
    key: '2',
    name: 'Really long content item',
    status: '',
    execution: 'Equipment',
    goal: '-',
    area: '9',
    category: '0%',
    subcategory: '0%',
    start: '05.06.2021',
    deadline: '-'
  },
  {
    key: '3',
    name: 'Really long content item',
    status: '',
    execution: 'Equipment',
    goal: '-',
    area: '9',
    category: '0%',
    subcategory: '0%',
    start: '05.06.2021',
    deadline: '-'
  }
]

const ProjectTable = () => (
  <Col span={24} className={'mb-2'}>
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination={{ position: ['none', 'none'] }}
    />
  </Col>
)

export default ProjectTable
