import { useEffect, useRef, useState } from "react";

import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as Trash } from "./trash.svg";
// import { ReactComponent as Star } from "./star.svg";
import { PlusOutlined } from "@ant-design/icons";

// @ts-ignore
import styles from "./ToggleMenu.module.scss";
import { generateUniqueId } from "../../utils/helper";

type UnitOption = {
  id: number;
  created_at?: string;
  updated_at?: string;
  value: string;
  label: number;
  name: string;
  isNew: boolean;
};

type MatricType = {
  created_at: string;
  value: string;
  updated_at: string;
  id: number;
  goal: number;
  custom_unit_options: UnitOption[];
};

export default function ToggleMenu({
  title,
  planDetails,
  customItems,
  setCustomItems,
  setDeletedItems,
  deletedItems,
  deletedUnits,
  setDeletedUnits,
  errorItems,
  setErrorItems,
}: {
  title: string;
  planDetails: any;
  customItems: MatricType[];
  setCustomItems: (customItems: MatricType[]) => void;
  deletedItems: string[];
  setDeletedItems: (deletedItems: string[]) => void;
  deletedUnits: string[];
  setDeletedUnits: (deletedUnits: string[]) => void;
  errorItems: string[];
  setErrorItems: (errorItems: string[]) => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  const [metric, setMetric] = useState<string | null>(null);
  const [customUnit, setCustomUnit] = useState<string[]>([]);
  const [unitData, setUnitData] = useState<UnitOption[]>([]);

  useEffect(() => {
    if (!collapsed && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [collapsed, errorItems, unitData]);

  useEffect(() => {
    if (planDetails?.value) {
      setMetric(planDetails?.value);
    }

    if (planDetails?.custom_unit_options?.length > 0) {
      setUnitData(planDetails?.custom_unit_options);
      setCustomUnit(
        planDetails?.custom_unit_options?.map(
          (unit: UnitOption) => unit?.value || ""
        )
      );
    }
  }, [planDetails]);

  const hanldeAddUnit = () => {
    const data: UnitOption = {
      value: "",
      name: "",
      label: planDetails?.id,
      id: Number(generateUniqueId()),
      isNew: true,
    };

    setUnitData([...unitData, data]);

    const filterData = customItems?.filter((item: MatricType) => {
      if (item?.id === planDetails?.id) {
        item.custom_unit_options = [...unitData, data];
      }
      return item;
    });

    setCustomItems([...filterData]);
    if (contentHeight) setContentHeight(contentHeight + 100);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value.startsWith(" ")) {
      value = value.trimStart();
    }

    if (value.length > 12) {
      value = value.slice(0, 12);
    }

    const updatedCustomItems = customItems?.map((item: MatricType) =>
      item?.id === planDetails?.id ? { ...item, value } : item
    );

    const removeError = errorItems?.filter((item: any) => {
      if (item !== planDetails?.id) {
        return item;
      }
    });

    setErrorItems([...removeError]);
    setCustomItems(updatedCustomItems);
    setMetric(value);
  };

  const handleUnitChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    data: MatricType
  ) => {
    let value = e.target.value;

    if (value.startsWith(" ")) {
      value = value.trimStart();
    }

    if (value.length > 6) {
      value = value.slice(0, 6);
    }
    const updatedUnitData = [...customUnit];
    updatedUnitData[index] = value;

    const updatedCustomItems = customItems?.map((item: MatricType) => {
      if (item?.id === planDetails?.id) {
        return {
          ...item,
          custom_unit_options: item.custom_unit_options.map(
            (unitOption: UnitOption) => {
              return data?.id === unitOption?.id
                ? { ...unitOption, value }
                : unitOption;
            }
          ),
        };
      }
      return item;
    });

    const removeError = errorItems?.filter((item: any) => {
      if (item !== data?.id) {
        return item;
      }
    });

    setErrorItems([...removeError]);
    setCustomItems([...updatedCustomItems]);
    setCustomUnit(updatedUnitData);
  };

  const handleDeleteMetric = () => {
    const filterData = customItems?.filter(
      (item: any) => item?.id !== planDetails?.id
    );

    const removeError = errorItems?.filter((item: any) => {
      if (item !== planDetails?.id) {
        return item;
      }
    });

    setErrorItems([...removeError]);
    setCustomItems([...filterData]);
    setDeletedItems([...deletedItems, planDetails?.id]);
  };

  const handleDeleteUnit = (index: number, data: any) => {
    const filterData = customItems?.map((item: any) => {
      if (item?.id === planDetails?.id) {
        return {
          ...item,
          custom_unit_options: item.custom_unit_options?.filter(
            (unit: any) => unit?.id !== data?.id
          ),
        };
      }
      return item;
    });

    const updatedUnitData = unitData?.filter((item: any) => {
      if (data?.id !== item?.id) {
        return item;
      }
    });

    const custumUnitData = customUnit?.filter((item: any, i: number) => {
      if (index !== i) {
        return item;
      }
    });

    const removeError = errorItems?.filter((item: any) => {
      if (item !== data?.id) {
        return item;
      }
    });

    setErrorItems([...removeError]);
    setCustomItems(filterData);
    setCustomUnit(custumUnitData);
    setUnitData([...updatedUnitData]);
    if (!data?.isNew) {
      setDeletedUnits([...deletedUnits, data?.id]);
    }
  };

  return (
    <div
      className={`${styles.toggleMenuContainer}
      ${collapsed ? styles.collapsed : ""}`}
    >
      <div className={styles.toggleHeader}>
        <div onClick={() => setCollapsed((prevCollapsed) => !prevCollapsed)}>
          <ArrowDown className={styles.icon} />
          <h3 className={styles.title}>{title || "Metric"}</h3>
        </div>

        <div>
          {/* <Star className="cursor-pointer" /> */}
          <Trash className="cursor-pointer" onClick={handleDeleteMetric} />
        </div>
      </div>

      <div
        ref={contentRef}
        className={styles.toggleContent}
        style={{
          maxHeight: collapsed ? "0" : `${contentHeight}px`,
          marginBottom: collapsed ? "0px" : "12px",
          marginTop: collapsed ? "0px" : "12px",
        }}
        // style={{
        //   maxHeight: "100%",
        //   marginBottom: "12px",
        //   marginTop: "12px",
        // }}
      >
        <div className={styles.metric}>
          <h3>Metric</h3>

          <div className={styles.metricInput}>
            <input
              type="text"
              placeholder="Enter metric"
              value={metric || ""}
              onChange={handleChange}
              style={{
                border: errorItems?.includes(planDetails?.id)
                  ? "1px solid red"
                  : "",
              }}
            />
          </div>

          {errorItems?.includes(planDetails?.id) && (
            <p className={styles.error}>Please enter metric</p>
          )}
        </div>

        {unitData && unitData?.length > 0 && (
          <div className={styles.unit}>
            <h3>Unit</h3>

            {unitData?.map((unit: any, index: number) => {
              console.log(
                planDetails,
                errorItems?.includes(unit?.id),
                unit?.id,
                errorItems,
                "errorItems1231"
              );

              return (
                <div key={index}>
                  <div className={styles.unitInput}>
                    <input
                      type="text"
                      placeholder="Enter unit"
                      value={customUnit[index] || ""}
                      onChange={(e) => handleUnitChange(e, index, unit)}
                      style={{
                        border: errorItems?.includes(unit?.id)
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <Trash
                      className="cursor-pointer"
                      onClick={() => handleDeleteUnit(index, unit)}
                    />
                  </div>

                  {errorItems?.includes(unit?.id) && (
                    <p className={styles.error}>Please enter unit</p>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div className={styles.addUnitBtn}>
          <div onClick={hanldeAddUnit}>
            <PlusOutlined />
            Add Unit
          </div>
        </div>
      </div>
    </div>
  );
}
