import { Table, Col, Progress } from 'antd'
import { COLORS } from 'constants/colors'
import React from 'react'

const Owner = ({ menu }) => {
  return (
    <Col span={24} className={'mb-2'}>
      <div className='ownerTag'>{menu}</div>
    </Col>
  )
}

export default Owner
