import { createDecisionFromTemplate } from "api";
import { Error } from "utils/Response";
import { ActionTypes } from "./types";

export const CreateDecisionFromTemplate = (Data, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await createDecisionFromTemplate(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200 && callback) {
      callback(data);
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
