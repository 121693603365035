import { Checkbox } from "antd";
import { Draggable } from "react-beautiful-dnd";

import grabIcon from "../../../../assets/svg/grab.svg";
import dragIcon from "../../../../assets/svg/area-drag.svg";

import c from "classnames";
import styles from "./styles.module.scss";

const Area = ({
  area,
  index,
  showWeight,
  showCheckbox,
  type = "default",
  onChange,
}) => {
  const onChangeHandle = (event) => {
    onChange(event.target, area.id);
  };

  return (
    <Draggable key={area.id} draggableId={area.id.toString()} index={index}>
      {(provided) => (
        <div
          className={c(styles.wrapper, styles[type])}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={styles.content}>
            <div {...provided.dragHandleProps}>
              <img src={type === "reorder" ? dragIcon : grabIcon} alt="" />
            </div>

            {showCheckbox && (
              <div className={styles.checkbox}>
                <Checkbox checked={area.checked} onChange={onChangeHandle} />
              </div>
            )}

            {showWeight && <span className={styles.weight}>{area.weight}</span>}

            <h4>{area.name}</h4>
            {/*<div>CREATE NEW SUBCATEGORY</div>*/}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Area;
