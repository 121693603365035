import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "./logo.svg";
import { Col, Row } from "antd";
import { ReactComponent as Finalist } from "./Checklist.svg";

const PrintFinalist = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    printComparison: () => {
      // Set A4 paper size for printing
      const mediaQueryList = window.matchMedia("print");
      mediaQueryList.mediaText = "print and (size: A4)";
      window.print(); // Trigger printing when the function is called
    },
  }));

  const [currentGroup, setCurrentGroup] = useState([]);
  const [titleValsArray, setTitleValsArray] = useState([]);
  const [finalRes, setFinalRes] = useState();

  let pageCount = currentGroup?.length;

  function processOptions(options, startIndex = 0, accumulatedOptions = []) {
    if (startIndex >= options.length) {
      return accumulatedOptions;
    }

    const endIndex = Math.min(startIndex + 3, options.length);
    const currentGroup = options?.slice(startIndex, endIndex);

    return processOptions(options, endIndex, [
      ...accumulatedOptions,
      currentGroup,
    ]);
  }

  useEffect(() => {
    if (!finalRes) return;
    const processedOptions = processOptions(finalRes);
    setCurrentGroup(processedOptions);
  }, [finalRes]);

  const [activeCriterias, setActiveCriterias] = useState();
  useEffect(() => {
    setActiveCriterias(props?.activeCriterias);
  }, []);

  function mapCriteriaForOptions(options) {
    return options.map((option) => {
      const optionDetail = {
        optionId: option.id,
        image: option.image,
        name: option.name,
        adjusted_score: option.adjusted_score,
        weighted_score: option.weighted_score,
      };

      const mappedCriteria = option.option_criteria.map((criteria) => ({
        optionId: criteria.option,
        criteriaId: criteria.criteria,
        score: parseFloat(criteria.score),
        secondStageScore: parseFloat(criteria.second_stage_score),
      }));

      return {
        optionDetail,
        mappedCriteria,
      };
    });
  }

  useEffect(() => {
    if (!props.decision.options) return;
    const mappedOptions = mapCriteriaForOptions(
      props.decision.options.filter((option) => option?.finalist)
    );
    setFinalRes(mappedOptions);
  }, [props?.decision?.options]);

  function compareCriteriaWithOptions(options, activeCriterias) {
    const matchingObjects = [];

    options?.forEach((option) => {
      option?.option_criteria?.forEach((criteriaObj) => {
        const matchingCriteria = activeCriterias?.find(
          (criteria) => criteria?.id === criteriaObj?.criteria
        );

        if (matchingCriteria) {
          const mergedObject = {
            ...criteriaObj,
            ...matchingCriteria,
            option: {
              image: option?.image,
              name: option?.name,
              weighted_score: option?.weighted_score,
              adjusted_score: option?.adjusted_score,
              criteriaId: option?.criteria,
              optionId: option?.id,
            },
          };
          matchingObjects?.push(mergedObject);
        }
      });
    });

    return matchingObjects;
  }
  useEffect(() => {
    if (!currentGroup) return;

    const titleValsArray = currentGroup?.map((group) => {
      const result = compareCriteriaWithOptions(group, activeCriterias);
      // const titleVals = result.reduce((map, obj) => {
      //   if (!map.has(obj.title)) {
      //     map.set(obj.title, []);
      //   }
      //   map.get(obj.title).push(obj.score);
      //   return map;
      // }, new Map());
      // return titleVals;
    });
    setTitleValsArray(titleValsArray);
  }, [currentGroup]);

  function formatDate(dateString) {
    if (!dateString) {
      return "N/A"; // or any default value you want to display for invalid or missing dates
    }
    const [year, month, day] = dateString?.split("-");
    return `${month}/${day}/${year}`;
  }

  function getChunkedLength(totalLength, index) {
    const maxChunkSize = 3;
    const chunkIncrement = index * maxChunkSize;

    return Math.min(maxChunkSize + chunkIncrement, totalLength);
  }

  return (
    <div>
      <div>
        {[...Array(pageCount)]?.map((_, pageIndex) => {
          const titleVals = titleValsArray[pageIndex] || new Map();
          const titleHeader = currentGroup[pageIndex] || new Map();
          const filterMapCreteria = titleHeader?.map((item) => {
            return item?.mappedCriteria;
          });

          return (
            pageIndex < currentGroup.length && (
              <PrintPage key={pageIndex}>
                <Wrapper>
                  <Container>
                    <Header>
                      <Logo />
                      <RightSide>
                        <Heading>{props?.decision?.problem}</Heading>
                        <Date>
                          Deadline:
                          <Span>
                            {formatDate(props?.decision?.deadline)}
                          </Span>{" "}
                        </Date>
                      </RightSide>
                    </Header>
                    <HeroHeader>
                      <Heading>
                        <Finalist /> Finalists{" "}
                        {getChunkedLength(
                          props?.decision?.options?.filter(
                            (option) => option?.finalist
                          )?.length,
                          pageIndex
                        )}
                        <LightFont>
                          {" "}
                          of{" "}
                          {
                            props?.decision?.options.filter(
                              (option) => option?.finalist
                            )?.length
                          }
                        </LightFont>{" "}
                      </Heading>
                      <Cards>
                        {titleHeader?.map(({ optionDetail }, index) => (
                          <Card key={index}>
                            {optionDetail?.image ? (
                              <CardIcon
                                src={optionDetail?.image}
                                alt={optionDetail?.image}
                              />
                            ) : (
                              <CardIcon
                                src={require("./Placeholder.jpg")}
                                alt=""
                              />
                            )}

                            <CardText>{optionDetail?.name}</CardText>
                            <div>
                              <SubText>
                                Adjusted Score:
                                <Span>{optionDetail?.adjusted_score}</Span>
                              </SubText>
                              <SubText>
                                Average Score:{" "}
                                <Span>{optionDetail?.weighted_score}</Span>
                              </SubText>
                            </div>
                          </Card>
                        ))}
                      </Cards>
                    </HeroHeader>
                    <Table>
                      {activeCriterias?.map((item, index) => (
                        <RowWrapper
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f1f1f1" : "#fff",
                          }}
                        >
                          <Row>
                            <InnerWrapper>
                              <Col span={1}>
                                <Serial>{index + 1}</Serial>
                              </Col>
                              <Col span={5}>
                                <Content>{item?.title}</Content>
                              </Col>

                              {filterMapCreteria?.map((fil) => (
                                <Col span={6}>
                                  {fil?.map((crit, i) => (
                                    <Column key={i}>
                                      {/* <Circle> */}{" "}
                                      {/* {crit?.criteriaId === item?.id
                                        ? crit?.secondStageScore
                                          ? Math?.ceil(crit?.secondStageScore)
                                          : Math?.ceil(crit?.score)
                                        : " N/A"} */}
                                      {crit?.criteriaId == item?.id && (
                                        <Circle
                                          style={{
                                            backgroundColor:
                                              i % 2 === 0
                                                ? "#e3e3e3"
                                                : "#f5f5f5",
                                          }}
                                        >
                                          {crit?.secondStageScore
                                            ? crit?.secondStageScore
                                            : crit?.score}
                                        </Circle>
                                      )}
                                      {/* </Circle> */}
                                    </Column>
                                  ))}
                                </Col>
                              ))}
                              {/* <Col span={6}>
                                <Column>
                                 
                                  <Content>{index + 1}</Content>
                                </Column>
                              </Col>
                              <Col span={6}>
                                <Column>
                                  <Content>{index + 1}</Content>
                                </Column>
                              </Col>
                              <Col span={6}>
                                <Column>
                                  <Content>{index + 1}</Content>
                                </Column>
                              </Col> */}
                              {/* {titleHeader?.map(({ mappedCriteria }, i) => {
                                return mappedCriteria?.map(
                                  (crit, scoreIndex) => (
                                    <Col span={6} key={crit?.criteriaId}>
                                      <Column>
                                        <Circle
                                          style={{
                                            backgroundColor:
                                              scoreIndex % 2 === 0
                                                ? "#e3e3e3"
                                                : "#f5f5f5",
                                          }}
                                        >
                                          {crit?.criteriaId === item?.id
                                            ? crit?.secondStageScore
                                              ? Math?.ceil(
                                                  crit?.secondStageScore
                                                )
                                              : Math?.ceil(crit?.score)
                                            : " N/A"}
                                          {crit?.criteriaId}
                                        </Circle>
                                      </Column>
                                    </Col>
                                  )
                                );
                              })} */}
                            </InnerWrapper>
                          </Row>
                        </RowWrapper>
                      ))}
                    </Table>

                    <Footer>
                      <FooterSpan>
                        © Arootah Follow us @arootahcoach Visit arootah.com
                      </FooterSpan>
                    </Footer>
                  </Container>
                </Wrapper>
              </PrintPage>
            )
          );
        })}
      </div>
    </div>
  );
});

const PrintPage = styled.div`
  height: calc(1123px - 0.5rem);
  overflow: hidden;
`;
const Wrapper = styled.div`
  background-color: #fff;
`;

const Container = styled.div`
  padding: 1rem 1.7rem;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #d9d9d9;
`;
const Icon = styled.img``;
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const Heading = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: #1271a6;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transform: translateY(-5px);
  /* width: 40%; */
`;
const Date = styled.p`
  color: #525252;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  transform: translateY(-5px);
`;
const Span = styled.span`
  color: #3090f1;
`;
const HeroHeader = styled.div`
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const LightFont = styled.span`
  font-weight: 400;
  margin-left: 4px;
`;
const Cards = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  width: 68%;
  /* max-width: fit-content; */
  /* margin-right: 0.5rem; */
`;
const Card = styled.div`
  width: 167px;
  height: 167px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`;
const CardIcon = styled.img`
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  border-radius: 4px;
`;
const CardText = styled.p`
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 34px;
  width: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SubText = styled.p`
  margin: 0;
  padding: 0;
  color: #525252;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Table = styled.div`
  margin-top: 1rem;
  height: 800px;
  /* border: 1px solid green; */
`;
const RowWrapper = styled.div`
  padding: 4px 84px 4px 10px;
  /* background-color: #f1f1f1; */
`;
const Serial = styled.p`
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;
const Content = styled.p`
  margin: 0;
  padding: 0;
  color: #4b545b;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  vertical-align: middle;
  white-space: nowrap;
`;
const Circle = styled.span`
  /* padding: 0.3rem; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background: #e3e3e3; */
  color: #000;
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  margin: 0 auto;
  padding: 0;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;
const FooterSpan = styled.span`
  color: #1271a6;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const Column = styled.div`
  transform: translateX(40px) !important;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;
// const SpanCircle = styled.span`
//   background-color: red;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   /* height: 30px; */
// `;
export default PrintFinalist;
