import {Checkbox, Popover} from 'antd';
import {Draggable} from "react-beautiful-dnd";

import grabIcon from '../../../../assets/svg/grab.svg';
import dragIcon from '../../../../assets/svg/area-drag.svg';

import c from 'classnames';
import styles from './styles.module.scss';
import {DashOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {

    removeCategory,
    removeSubCategory, removeUserCategoryLocal
} from "../../../../modules/actions/MissionStatementActions";
import {Axios} from "../../../../api/axios";


const Category = ({
                      area,
                      index,
                      showWeight,
                      showCheckbox,
                      type = 'default',
                      subCategories,
                      systemCategories,
                      setUserSubCategoriesStateX,
                      userSubCategoriesStateX
                  }) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    let {state: {update}} = useLocation();


    const {
        userSubCategories
    } = useSelector(state => state.missionStatement);

    const [userSubCategoriesState, setUserSubCategories] = useState([]);

    area.weight = 10 - index


    useEffect(() => {
        if (subCategories) {
            Axios.get(`/api/v1/hierarchy/user_sub_category/?parent_category=${area.id}`).then(data => {
                setUserSubCategories(data)
            })
        }

    }, [area.id, update])

    useEffect(() => {
        setUserSubCategories([...userSubCategories.data])
    }, [userSubCategories])


    const deleteCategory = async () => {
        if (subCategories) {
            dispatch(removeCategory(area.id))
            dispatch(removeUserCategoryLocal(area))
            return
        }
        dispatch(removeSubCategory(area.id))
        setUserSubCategoriesStateX([...userSubCategoriesStateX.filter(c => c.id !== area.id)])
    }

    const toAddSubcategoryForm = () => {
        const id = systemCategories.find(c => c.name === area.name) ? systemCategories.find(c => c.name === area.name).id : 640
        navigate('add-subcategory', {state: {category: area, id}})
    }



    const content = (
        <div>
            <p onClick={() => deleteCategory(area.id)}>Delete</p>
            <p onClick={() => {
                if (subCategories) {
                    const id = systemCategories.find(c => c.name === area.name) ? systemCategories.find(c => c.name === area.name).id : 640
                    navigate('add-category', {state: {categoryForUpdate: area, selectedAreaId: area.parent_area, id}})
                } else {
                    const id = 640
                    navigate('add-Subcategory', {state: {categoryForUpdate: area, id}})
                }


            }}>Edit</p>
        </div>
    );

    return (
        <div><Draggable key={area.id} draggableId={area.name} index={index}>
            {provided => (
                <div className={c(styles.wrapper, styles[type])} ref={provided.innerRef} {...provided.draggableProps}>
                    <div className={styles.content}>

                        <div style={{display: "flex"}}>
                            <div {...provided.dragHandleProps}>
                                {subCategories && <img src={type === 'reorder' ? dragIcon : grabIcon} alt=""/>}
                            </div>

                            {showWeight && (
                                <div className={styles.minus} onClick={deleteCategory}>
                                    -
                                </div>
                            )}

                            {showWeight && <span className={styles.weight}>{area.weight}</span>}

                            <h4>{area.name}</h4>
                        </div>


                        <div className={styles.addSubcategoyWrapper}>{subCategories ?
                            <div style={{display: "flex", marginTop: '5px'}} onClick={toAddSubcategoryForm}>
                                <PlusOutlined style={{color: '#1F85CF'}}/>
                                <h2 className={styles.addCategory}>CREATE NEW SUBCATEGORIE</h2>
                            </div>:<div></div>}
                            <div>{showWeight &&
                                <Popover content={content} trigger="hover">
                                    <DashOutlined rotate={90} style={{marginBottom: '5px'}}/>
                                </Popover>}
                            </div>

                        </div>

                    </div>

                </div>
            )}
        </Draggable>
            <div style={{
                display: 'flex',
                justifyContent: "flex-end"
            }}>{subCategories ?
                <div
                    style={{width: "85%"}}>{userSubCategoriesState.filter(c => c.parent_category === area.id).map((subCategory, index) =>
                    <div><Category
                        subCategories={false}
                        area={subCategory}
                        showCheckbox
                        showWeight
                        index={index}
                        type="reorder"
                        userSubCategoriesStateX={userSubCategoriesState}
                        setUserSubCategoriesStateX={setUserSubCategories}
                        key={subCategory.id}/></div>)}</div> : ''}</div>
        </div>
    );
}

export {Category};
