import { Button, Descriptions, Divider, Modal, Row } from "antd";
import React from "react";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";

export default function DeleteModal({
  visible,
  handleRemove,
  loading,
  planDetails,
  heading,
  descriptions,
  onClose,
  isPlan,
  isAction,
  loadingArchive,
  actionName,
  item,
}) {
  return (
    <Modal
      footer={null}
      className={"deleteModal"}
      closable={false}
      onCancel={onClose}
      title={false}
      open={visible}
    >
      <Row justify="space-between" align="middle" className="pt-2 ph-2">
        <div className="font-16 text_black text_bold">{heading}</div>
        <Cross onClick={onClose} className={"c-pointer"} />
      </Row>
      <Divider />
      <div className="ph-2">
        <div className="mt-2 mb-2">{descriptions}</div>
        {/* {!isPlan && !isAction && (
          <div className='mt-1 text_bold mb-2'>"{planDetails?.name}"</div>
        )} */}
        {actionName && (
          <div className="mt-1 text_bold mb-2">"{actionName}"</div>
        )}
        <Row justify="space-between">
          <Button
            // icon={<MailOutlined />}
            className="outlinePrimaryButton uppercase halfWidth mb-2"
            type="primary"
            // loading={loadingArchive || loading}
            // onClick={isPlan ? handleArchive : onClose}
            onClick={onClose}
          >
            {/* {isPlan ? 'send to archive' : 'Cancel'} */}
            {"Cancel"}
          </Button>
          <Button
            // icon={<MailOutlined />}
            className="inviteButton buttonHeight redButton uppercase halfWidth b-1"
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={handleRemove}
          >
            {isPlan
              ? item?.is_archived
                ? "Send To Unarchive"
                : "Send To Archive"
              : "Delete Permanently"}
          </Button>
        </Row>
      </div>
    </Modal>
  );
}
