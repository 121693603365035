import { resendOtp } from "api";
import { Error } from "../../../utils/Response"
import { ActionTypes } from "./types";
import { getAppBaseURL } from "../../../utils/utility"

export const ResendOTPAction = (Data, onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.OTP_LOADING, payload: Data });
    const url = getAppBaseURL();
    const dataForm = {
      ...Data,
      redirect_url : `https://${url}/`
    }
    const { data, status } = await resendOtp(dataForm);

    dispatch({
      type: ActionTypes.OTP_SUCCESS,
      payload: data
    });
    if (status === 201) {
      if (onComplete){
        onComplete()
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
    } else {
      Error(error);
    }

    dispatch({
      type: ActionTypes.OTP_FAIL,
      payload: error
    });
  }
};
