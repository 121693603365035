import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "./logo.svg";

import { ReactComponent as OptionComparision } from "./weight.svg";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

const PrintComparisonOptions = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    PrintComparisonOptions: () => {
      // Set A4 paper size for printing
      const mediaQueryList = window.matchMedia("print");
      mediaQueryList.mediaText = "print and (size: A4)";
      window.print(); // Trigger printing when the function is called
    },
  }));
  const [activeCriterias, setActiveCriterias] = useState([]);
  const [leftOption, setLeftOption] = useState(null);
  const [rightOption, setRightOption] = useState(null);
  const [leftData, setLeftData] = useState();
  const [rightData, setRightData] = useState();
  const { success: userDecision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { selectedOption1 } = useSelector(
    (state) => state.selectedOption1Reducer
  );
  const { selectedOption2 } = useSelector(
    (state) => state.selectedOption2Reducer
  );
  useEffect(() => {
    if (userDecision && userDecision?.criteria)
      setActiveCriterias(
        userDecision?.criteria
          ?.filter((c) => c?.active)
          ?.sort((a, b) => a?.id - b?.id)
          ?.sort((a, b) => b?.weight - a?.weight)
      );
  }, [userDecision]);

  useEffect(() => {
    if (selectedOption1) {
      const res = userDecision?.options?.find(
        (item) => item?.id === selectedOption1?.id
      );

      setLeftOption(res);
    }
    if (selectedOption2) {
      const res = userDecision?.options?.find(
        (item) => item?.id === selectedOption2?.id
      );
      setRightOption(res);
    }
  }, [selectedOption1, selectedOption2]);

  useEffect(() => {
    if (!leftOption) return;
    const combinedDataLeft = leftOption?.option_criteria?.map((item1) => {
      const matchingItem = activeCriterias?.find(
        (item2) => item2?.id === item1?.criteria
      );
      if (matchingItem) {
        return { ...item1, ...matchingItem };
      } else {
        return item1;
      }
    });

    let res = combinedDataLeft?.sort((a, b) => b?.score - a?.score); // Sort in descending order based on score
    setLeftData(res);
  }, [leftOption]);

  useEffect(() => {
    if (!rightOption) return;
    const combinedDataRight = rightOption?.option_criteria?.map((item1) => {
      const matchingItem = activeCriterias?.find(
        (item2) => item2?.id === item1?.criteria
      );
      if (matchingItem) {
        return { ...item1, ...matchingItem };
      } else {
        return item1;
      }
    });
    let res = combinedDataRight?.sort((a, b) => b?.score - a?.score); // Sort in descending order based on score

    setRightData(res);
  }, [rightOption]);

  const splitTwoArraysIntoPages = (array1, array2) => {
    const pageSize = 10; // Set the desired page size
    // Sort both array1 and array2 based on the 'title' key
    // const sortedArray1 = array1
    //   ?.slice()
    //   ?.sort((a, b) => a?.title?.localeCompare(b?.title));
    // const sortedArray2 = array2
    //   ?.slice()
    //   ?.sort((a, b) => a?.title?.localeCompare(b?.title));

    const sortedArray1 = array1?.sort((a, b) => b?.weight - a?.weight);
    const sortedArray2 = array2?.sort((a, b) => b?.weight - a?.weight);
    // Split sortedArray1 into pages
    const pages1 = [];
    for (let i = 0; i < sortedArray1?.length; i += pageSize) {
      pages1?.push(sortedArray1?.slice(i, i + pageSize));
    }

    // Split sortedArray2 into pages
    const pages2 = [];
    for (let i = 0; i < sortedArray2?.length; i += pageSize) {
      pages2?.push(sortedArray2?.slice(i, i + pageSize));
    }

    // Combine the pages from both arrays into the desired format
    const combinedPages = pages1?.map((page, index) => [page, pages2[index]]);
    // Calculate the start and end criteria number for each page
    const criteriaRanges = pages1?.map((page, index) => {
      const startNumber = index * pageSize + 1;
      const endNumber = Math?.min(startNumber + pageSize - 1, array1?.length);
      return `${startNumber}-${endNumber}`;
    });
    return { combinedPages, criteriaRanges };
  };

  const pages = splitTwoArraysIntoPages(leftData, rightData);
  const formatDate = (dateString) => {
    if (!dateString) {
      return "N/A"; // or any default value you want to display for invalid or missing dates
    }
    const [year, month, day] = dateString?.split("-");
    return `${month}/${day}/${year}`;
  };
  return (
    <Wrapper>
      {pages?.combinedPages?.map((page, pageIndex) => (
        <MainContent key={pageIndex}>
          <PrintHeader>
            <Titlecontainer>
              <Logo />
            </Titlecontainer>
            <CarchoiceContainer>
              <CarchoiceHeading>
                {/* Which Fund Manager to Choose? */}
                {userDecision?.problem}
              </CarchoiceHeading>
              <CarChoiceDate>
                <DealineHeading>Deadline:</DealineHeading>
                <DeadlineDate>
                  {formatDate(userDecision?.deadline)}
                </DeadlineDate>
              </CarChoiceDate>
            </CarchoiceContainer>
          </PrintHeader>

          <PrintSection>
            <ComparisionHeading>
              <OptionComparision />
              Option Comparison
            </ComparisionHeading>
            {pageIndex === 0 && (
              <Row gutter={[8, 0]}>
                <Col>
                  <AvatarContent>
                    {leftOption?.image ? (
                      <Icon src={leftOption?.image} alt="img" />
                    ) : (
                      <Icon src={require("./Placeholder.jpg")} alt="winner" />
                    )}
                  </AvatarContent>
                </Col>
                <Col span={7}>
                  <CarContent>
                    <AvatarContentHeading>
                      <AvatarHeading>{leftOption?.name}</AvatarHeading>
                    </AvatarContentHeading>
                    <Adjustedscore>
                      Adjusted score: <Span>{leftOption?.adjusted_score}%</Span>
                    </Adjustedscore>
                    <Averagescore>
                      Average score: <Span>{leftOption?.weighted_score}</Span>
                    </Averagescore>
                    <DescriptionContainer>
                      <DescriptionHeading>Description</DescriptionHeading>
                      <Info>{leftOption?.notes}</Info>
                    </DescriptionContainer>
                  </CarContent>
                </Col>
                <Col>
                  <AvatarContent>
                    {rightOption?.image ? (
                      <Icon src={rightOption?.image} alt="img" />
                    ) : (
                      <Icon src={require("./Placeholder.jpg")} alt="winner" />
                    )}
                  </AvatarContent>
                </Col>
                <Col span={7}>
                  <CarContent>
                    <AvatarContentHeading>
                      <AvatarHeading>{rightOption?.name}</AvatarHeading>
                    </AvatarContentHeading>
                    <Adjustedscore>
                      Adjusted score:{" "}
                      <Span>{rightOption?.adjusted_score}%</Span>
                    </Adjustedscore>
                    <Averagescore>
                      Average score: <Span>{rightOption?.weighted_score}</Span>
                    </Averagescore>
                    <DescriptionContainer>
                      <DescriptionHeading>Description</DescriptionHeading>
                      <Info>{rightOption?.notes}</Info>
                    </DescriptionContainer>
                  </CarContent>
                </Col>
              </Row>
            )}
          </PrintSection>

          <CriteriaSection isheight={pageIndex > 0}>
            <CriteriaHeading>
              Criteria {pages?.criteriaRanges[(page, pageIndex)]}
            </CriteriaHeading>
            <CriteriaContent>
              <Row gutter={[5, 0]}>
                <Col span={12}>
                  {page[0]?.map((item, index) => (
                    <>
                      <Criteriadiv
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f1f1f1" : "#ffff",
                        }}
                      >
                        <CriteriaName>
                          <Number>{pageIndex * 10 + index + 1}</Number>
                          {item?.title}
                        </CriteriaName>
                        <CriteriaDescriptioncontent>
                          <MarketPrice>
                            {item?.facts ? item?.facts : "N/A"}
                          </MarketPrice>
                          <CriteriaCount>
                            <CriteriaNumber>
                              {item?.second_stage_score
                                ? Math.floor(item?.second_stage_score)
                                : Math.floor(item?.score)}
                            </CriteriaNumber>
                          </CriteriaCount>
                        </CriteriaDescriptioncontent>
                      </Criteriadiv>
                    </>
                  ))}
                </Col>
                <Col span={12}>
                  {page[1]?.map((item, index) => (
                    <Criteriadiv
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffff",
                      }}
                    >
                      <CriteriaName>
                        <Number>{pageIndex * 10 + index + 1}</Number>
                        {item?.title}
                      </CriteriaName>
                      <CriteriaDescriptioncontent>
                        <MarketPrice>
                          {item?.facts ? item?.facts : "N/A"}
                        </MarketPrice>
                        <CriteriaCount>
                          <CriteriaNumber>
                            {item?.second_stage_score
                              ? Math.floor(item?.second_stage_score)
                              : Math.floor(item?.score)}
                          </CriteriaNumber>
                        </CriteriaCount>
                      </CriteriaDescriptioncontent>
                    </Criteriadiv>
                  ))}
                </Col>
              </Row>
            </CriteriaContent>
            <Pagebreak>
              <Pages>
                Page {pageIndex + 1} of {pages?.combinedPages?.length}
              </Pages>
            </Pagebreak>
          </CriteriaSection>

          <Footar>
            <FootarHeading>
              © Arootah Follow us @arootahcoach Visit arootah.com
            </FootarHeading>
          </Footar>
          <div style={{ pageBreakAfter: "always" }}></div>
        </MainContent>
      ))}
    </Wrapper>
  );
});

export default PrintComparisonOptions;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /*  */
  /* padding: 0 1.2rem; */
`;

const MainContent = styled.div`
  background-color: #fff;
  width: 877px;
  /* height: 1135px; */
  /* border: 1px solid red; */
  padding: 0 1.2rem;
`;

const PrintHeader = styled.div`
  padding: 0.4rem 0rem;
  border-bottom: 2px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
`;

const CarchoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CarchoiceHeading = styled.h3`
  margin: 0;
  color: #1271a6;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const CarChoiceDate = styled.div`
  display: flex;
`;

const DealineHeading = styled.h3`
  margin: 0;
  color: #525252;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 4px;
`;

const DeadlineDate = styled.p`
  margin: 0;
  color: #3090f1;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Icon = styled.img`
  width: 164px;
  height: 164px;
  border-radius: 6px;
`;
const PrintSection = styled.div`
  margin-top: 0.1rem;
`;
const ComparisionHeading = styled.h3`
  margin: 0;
  color: #1271a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 0px 8px 0px;
`;

const AvatarContent = styled.div``;

const CarContent = styled.div`
  display: flex;
  flex-direction: column;

  /* border: 1px solid red; */
`;

const AvatarContentHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AvatarHeading = styled.h3`
  margin: 0;
  color: #000;
  font-family: Inter;
  font-size: 18.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
`;

const Adjustedscore = styled.h3`
  margin: 0;
  margin-top: 0.2rem;
  color: #525252;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Averagescore = styled.h3`
  margin: 0;
  margin-top: 0.2rem;
  color: #525252;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Span = styled.span`
  color: #3090f1;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionHeading = styled.h3`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  margin-top: 0.1rem;
`;

const Info = styled.p`
  margin: 0;
  color: #525252;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-width: 17rem;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const CriteriaSection = styled.div`
  width: 100%;
  height: ${({ isheight }) => (isheight ? "1070px" : "910px")};
  position: relative;
  margin-top: 8px;
`;

const CriteriaHeading = styled.h3`
  margin: 0;
  margin-top: 0.15rem;
  color: #000;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const CriteriaContent = styled.div`
  /* width: 100%;
  height: 100%; */
`;

const Pagebreak = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 7px 0;
  position: absolute;
  bottom: 0;
`;
const Pages = styled.h3`
  margin: 0;
  color: #525252;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const Criteriadiv = styled.div`
  height: 78px;
  padding: 0rem 0.7rem;
`;

const Number = styled.span`
  color: #000;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  margin-right: 0.7rem;
  display: inline-block;
`;

const CriteriaName = styled.h3`
  margin: 0;
  margin-bottom: 0.35rem;
  margin-top: 0.25rem;
  color: #4b545b;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 13.5px;
  display: flex;
  align-items: center;
`;

const CriteriaDescriptioncontent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const MarketPrice = styled.h3`
  margin: 0;
  color: #4b545b;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CriteriaCount = styled.div`
  width: 26px;
  height: 26px;
  background-color: #e3e3e3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CriteriaNumber = styled.span`
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.98px;
  display: inline-block;
`;
const Footar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #d9d9d9;
`;
const FootarHeading = styled.h3`
  color: #1271a6;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  margin: 9px;
`;
