import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './styled';

import ArrowNext from '../../../assets/images/auth/arrowNext.svg';

import CreateAccount from '../../../components/Auth/CreateAccount';
import SignUp from '../../../components/Auth/SignUp';
import Layout from '../../../components/Auth/Layout';
import LegalTerms from '../../../components/Auth/LegalTerms';
import VerificationMain from '../../../components/Auth/Verification'

const increaseBy = (num) => {
  let counter = num;
  return () => {
    const result = counter;
    counter += 50;
    return result;
  }
}

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const hasAcceptedTerms = location.state ? location.state?.hasAcceptedTerms : false;

  const queryParams = new URLSearchParams(location.search);
  const step = queryParams.get('step') || '1';
  const appParam = queryParams.get('app');

  // const animateDelayCounter = increaseBy(100);

  const [animate, setAnimate] = useState(false);
  const [animation, setAnimation] = useState(false);

  const onAreaClickHandle = type => () => {
    setAnimation(false);

    setTimeout(() => {
      // history.replace(`/register?type=${type}`);
    }, 100);
  }

  const onLegalTermsHandle = () => {
    navigate(`/sign-up?app=${appParam}&step=2`);
    // history.push(`/register?type=${userType}&step=3`, { hasAcceptedTerms: true });
  }

  const onSignUpStepHandle = () => {
    navigate(`/sign-up?app=${appParam}&step=3`);
    // history.push(`/register?type=${userType}&step=4`, { hasAcceptedTerms });
  }

  const onOtpVerifyStepHandle = () => {
    navigate(`/sign-up?app=${appParam}&step=4`);
  }

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 100);
  }, [step]);

  // No cheatin', user
  useEffect(() => {
    if ((step === '3' || step === '4') && !hasAcceptedTerms) {
      // navigate(`/sign-up?app=${appParam}&step=2`);
      // history.push(`/register?type=${userType}&step=2`);
    }
  }, [step, hasAcceptedTerms]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  return (
    <Layout bg={step === '1' ? 'terms' : 'register'}>
      <>
        {step !== '2' && (
          <Styled.BackgroundWrapper>
            <Styled.Background $animate={animate} $animateDuration={500}>
              <Styled.Circle $animate={false} />
            </Styled.Background>
          </Styled.BackgroundWrapper>
        )}

        <LegalTerms active={step === '1'} onNext={onLegalTermsHandle} />
        <CreateAccount active={step === '2'} animate={animation} onNext={onSignUpStepHandle} />
        <SignUp active={step === '3'} onNext={onOtpVerifyStepHandle} />
        <VerificationMain active={step === '4'}/>
      </>
    </Layout>
  );
}

export default Register;
