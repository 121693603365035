import {
  bulkCreateCriteria,
  bulkDeleteCriteria,
  bulkUpdateCriteria,
} from "api";
import { Error } from "utils/Response";
import {
  UpdateDecisionCriterias,
  DeleteDecisionCriterias,
  AssignDecisionCriterias,
  GetDecisionDetails,
} from "../GetDecisionDetails/action";
import { RequestEditDecisionLoading } from "../UpdateDecision/action";
import { ActionTypes } from "./types";

export const UpdateCriteriaRows = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    if (payload.delete && payload.delete.length) {
      await bulkDeleteCriteria(payload);
      await dispatch(DeleteDecisionCriterias(payload.delete));
    }

    if (payload.create && payload.create.length) {
      const createResponse = await bulkCreateCriteria(payload);

      if (createResponse) {
        await dispatch(AssignDecisionCriterias(createResponse.data));
      }
    }

    if (payload.update && payload.update.length) {
      const updateResponse = await bulkUpdateCriteria(payload);

      if (updateResponse) {
        await dispatch(UpdateDecisionCriterias(updateResponse.data));
      }
    }

    await dispatch(GetDecisionDetails(payload.decision, false));

    dispatch({
      type: ActionTypes.SUCCESS,
    });

    dispatch(RequestEditDecisionLoading("second"));

    /* dispatch({
      type: ActionTypes.TOGGLE_POPUP
    }); */
  } catch (error) {
    if (error?.response?.status === 400){
      function extractErrorMessage(array) {
        return array.map((obj) => {
          const fieldName = Object.keys(obj)[0]; // Get the dynamic field name
          return obj[fieldName][0]; // Extract the string value
        });
      }
      const errorMessage = extractErrorMessage(error?.response?.data);
      errorMessage.map((e) => {
        Error(e);
      })
      
    }
    Error(error?.response?.data?.error);
    
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

/* export const TogglePopup = () => async dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_POPUP
  });
}; */
