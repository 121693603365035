import { min, max, between, BREAKPOINTS } from "./mixins";

const colors = {
  peach: "#FEAB3D",
  denim: "#1271A6",
  white: "#fff",
  black: "#000",
};

export default {
  breakpoints: BREAKPOINTS,
  colors,
  scheme: {
    primary: colors.denim,
    secondary: colors.peach,
  },
  palette: {
    primary: {
      light: "#47b0eb",
      main: "#1271A6",
      dark: "#04192E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF9900",
    },
    success: {
      main: "#789f08",
    },
    common: {
      black: "#000",
      white: "#fff",
      grey: "#F5F5F5",
    },
    background: {
      main: "#f9f9f9",
    },
    link: {
      main: "#00fff0",
    },
    text: {
      primary: "#1B2A3D",
      secondary: "#8E97A3",
      secondaryLight: "#E3E5E9",
      white: "#fff",
    },
  },
  fonts: {
    primary: "Poppins",
    secondary: "Inter",
    thernary: "Prata",
  },
  min,
  max,
  between,
};
