import moment from "moment";

const ValidateCurrentValue = (
  start: string | null,
  current: string | null,
  target: string | null
) => {
  const startValue = Number(start);
  const currentValue = Number(current);
  const targetValue = Number(target);

  if (currentValue !== null && currentValue < startValue) {
    return "Current value must be greater than or equal to the start value";
  }

  if (currentValue !== null && currentValue > targetValue) {
    return "Current value must be less than or equal to the target value";
  }

  return undefined;
};

const ValidateTargetValue = (
  start: string | null,
  current: string | null,
  target: string | null
) => {
  const startValue = Number(start);
  const currentValue = Number(current);
  const targetValue = Number(target);

  if (targetValue !== null && targetValue < startValue) {
    return "Target value must be greater than or equal to the start value";
  }

  if (targetValue !== null && targetValue < currentValue) {
    return "Target value must be greater than or equal to the current value";
  }

  return undefined;
};

const ValidateStartValue = (
  start: string | null,
  current: string | null,
  target: string | null
) => {
  const startValue = Number(start);
  const currentValue = Number(current);
  const targetValue = Number(target);

  if (startValue !== null && startValue > currentValue) {
    return "Start value must be less than or equal to the current value";
  }

  if (startValue !== null && startValue > targetValue) {
    return "Start value must be less than or equal to the target value";
  }

  return undefined;
};

const ValidateStartLineValue = (
  startLine: string | null,
  categoryStartDate: string | null,
  categoryEndDate: string | null
): React.ReactNode => {
  if (!startLine) return null;

  const startLineDate = moment(startLine);
  const startDate = categoryStartDate ? moment(categoryStartDate) : null;
  const endDate = categoryEndDate ? moment(categoryEndDate) : null;

  if (startDate && startLineDate.isBefore(startDate)) {
    return (
      <>
        Please select an <strong>Action Item Start Date</strong> after the <strong>Category Start Date</strong>.
      </>
    );
  }

  if (endDate && startLineDate.isAfter(endDate)) {
    return (
      <>
        Please select an <strong>Action Item Start Date</strong> before the <strong>Category Due Date</strong>.
      </>
    );
  }

  return null;
};

const ValidateDeadLineValue = (
  deadLine: string | null,
  categoryStartDate: string | null,
  categoryEndDate: string | null,
  startLine: string | null
): React.ReactNode => {
  if (!deadLine) return null;

  const deadLineDate = moment(deadLine);
  const startDate = categoryStartDate ? moment(categoryStartDate) : null;
  const endDate = categoryEndDate ? moment(categoryEndDate) : null;
  const startLineDate = startLine ? moment(startLine) : null;

  if (startDate && deadLineDate.isBefore(startDate)) {
    return (
      <>
        Please select an <strong>Action Item Due Date</strong> after the <strong>Category Start Date</strong>.
      </>
    );
  }

  if (endDate && deadLineDate.isAfter(endDate)) {
    return (
      <>
        Please select an <strong>Action Item Due Date</strong> before the <strong>Category Due Date</strong>.
      </>
    );
  }

  if (startLineDate && deadLineDate.isBefore(startLineDate)) {
    return (
      <>
        Please select an <strong>Action Item Due Date</strong> after the <strong>Action Item Start Date</strong>.
      </>
    );
  }

  return null;
};

const ValidateCategoryStartLineValue = (
  startLine: string | null,
  planStartDate: string | null,
  planEndDate: string | null
): React.ReactNode => {
  if (!startLine) return null;

  const startLineDate = moment(startLine);
  const startDate = planStartDate ? moment(planStartDate) : null;
  const endDate = planEndDate ? moment(planEndDate) : null;

  if (startDate && startLineDate.isBefore(startDate)) {
    return (
      <>
        Please select a <strong>Category Start Date</strong> after the{" "}
        <strong>Plan Start Date.</strong>
      </>
    );
  }

  if (endDate && startLineDate.isAfter(endDate)) {
    return (
      <>
        Please select a <strong>Category Start Date</strong> before the{" "}
        <strong>Plan Due Date.</strong>
      </>
    );
  }

  return null;
};

const ValidateCatrgoryDeadLineValue = (
  deadLine: string | null,
  planStartDate: string | null,
  planEndDate: string | null,
  startLine: string | null
): React.ReactNode => {
  if (!deadLine) return null;

  const deadLineDate = moment(deadLine);
  const startDate = planStartDate ? moment(planStartDate) : null;
  const endDate = planEndDate ? moment(planEndDate) : null;
  const startLineDate = startLine ? moment(startLine) : null;

  if (startDate && deadLineDate.isBefore(startDate)) {
    return (
      <>
        Please select a <strong>Category Due Date</strong> after the{" "}
        <strong>Plan Start Date.</strong>
      </>
    );
  }

  if (endDate && deadLineDate.isAfter(endDate)) {
    return (
      <>
        Please select a <strong>Category Due Date</strong> before the{" "}
        <strong>Plan Due Date.</strong>
      </>
    );
  }

  if (startLineDate && deadLineDate.isBefore(startLineDate)) {
    return (
      <>
        Please select a <strong>Category Due Date</strong> after the{" "}
        <strong>Category Start Date.</strong>
      </>
    );
  }

  return null;
};

export {
  ValidateCurrentValue,
  ValidateTargetValue,
  ValidateStartValue,
  ValidateStartLineValue,
  ValidateDeadLineValue,
  ValidateCategoryStartLineValue,
  ValidateCatrgoryDeadLineValue,
};
