import { FC } from "react";
import { Button } from 'elements';

import successIcon from "./success.svg";

import {
  Wrapper,
  Content,
  Text,
  Hr,
  Footer
} from './styled';

interface IProps {
  decisionName: string;
  onClose: () => void;
}

const InviteSuccess: FC<IProps> = ({ decisionName, onClose }) => {
  return (
    <Wrapper>
      <Content>
        <img src={successIcon} width={50} alt="" />
        <Text>Your <b>‘‘{decisionName}”</b> Decision successfully shared with other users.</Text>
      </Content>

      <Hr />

      <Footer>
        <Button onClick={onClose}>Close</Button>
      </Footer>
    </Wrapper>
  );
};

export default InviteSuccess;
