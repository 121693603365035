import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import SpinnerElement from 'components/Spinner';

import * as Styled from './styled';
import WarningIcon from './assets/warning.svg';

const KEYWORD = "DELETE";

const DeleteAccountModal = ({ show, loading, onClose }) => {
  const [value, setValue] = useState('');

  const $cancelBtn = useRef(null);

  useEffect(() => {
    $cancelBtn && $cancelBtn.current && $cancelBtn.current.focus();
  }, [show, $cancelBtn]);

  const onChangeHandle = evt => {
    setValue(evt.target.value);
  }

  const onCloseHandle = () => {
    onClose(false);
  }

  const onContinueHandle = () => {
    if (value === KEYWORD)
      onClose(true);
  }

  return createPortal(
    <Styled.StyledModal show={show} onHide={onCloseHandle} centered>
      <Styled.CloseBtn onClick={onCloseHandle} title="Close" />

      <Styled.MainWrapper>
        <Styled.Img src={WarningIcon} width={40.55} height={37} alt="" />

        <Styled.Typography>
          <h1>Are you sure you want to delete your account? <br />You will not be able to undo this.</h1>
          <h3 className='center'>Deleting your account will</h3>

          <ul>
            <li>remove access forever</li>
            <li>cancel your existing subscription</li>
            <li>remove account data for ALL Arootah apps</li>
          </ul>

          <p className='center'>Type <b className="text-uppercase no-select">{KEYWORD}</b> below:</p>
        </Styled.Typography>

        <Styled.Form>
          <Styled.InputField placeholder="Type here" value={value} onChange={onChangeHandle} required />
          <Styled.Submit disabled={value !== KEYWORD || loading} onClick={onContinueHandle}>Delete Account</Styled.Submit>
        </Styled.Form>

        <Styled.Button $style="blue" type="button" onClick={onCloseHandle} autoFocus ref={$cancelBtn} disabled={loading}>Cancel</Styled.Button>

        {loading && (<Styled.SpinnerOverlay>
          <SpinnerElement />
        </Styled.SpinnerOverlay>)}
      </Styled.MainWrapper>
    </Styled.StyledModal>,
    document.getElementById('portal-root')
  );
};

export default DeleteAccountModal;
