import React, { useState } from "react";
import classes from "scenes/DecisionMatrix/components/compare/compare.module.scss";
import { EllipsisOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { Axios } from "../../../../api/axios";
import { apiBaseURL } from "../../../../utils/constants";



const Mitigation = ({
  con,
  editCons,
  mitigationRef,
  addMitigation,
  setAddMitigation,
  cons,
  setCons,
  mitigationLimitError,
  setEditMitigationLimitError
}) => {
  return (
    <div key={+con.id * 0.33} className={classes.prosWrapper}>
      {con?.mitigation && addMitigation !== con?.id ? (
        <div className={classes.mitigation}>
          <div>{con?.mitigation}</div>
        </div>
      ) : (
        addMitigation === con?.id && (
          <div>
            <textarea
            rows="1"
            onBlur={() => {
              editCons(con?.id);
            }}
            onChange={(e) =>{
              setEditMitigationLimitError(e.target.value.replaceAll('\n', '').length > 2000)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                editCons(con?.id);
                // setShowInput('')
              }
            }}
            // onChange={(e) => localUpdateCons(e.target.value)}
            ref={mitigationRef}
            value={mitigationRef?.curent?.value}
            placeholder={"Enter a mitigation (Optional)"}
            className={classes.inputMitigation}
            type="text" 
            autoFocus={true}
            style={{borderColor:addMitigation === con?.id && mitigationLimitError ? '#f51e14' : null, marginBottom:addMitigation === con?.id && mitigationLimitError ? '7px' : null}}   
          >
          </textarea>
          {addMitigation === con?.id && mitigationLimitError && (<div style={{color:'#f51e14', fontSize:12, marginLeft:15}}>Mitigation cannot be greater than 2000 characters.</div>)}
          </div>
        )
      )}
      {/* <hr className={classes.br} /> */}
    </div>
  );
};

export { Mitigation };
