import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import c from 'classnames';
import {useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Area from '../../components/Area';


import { reorder } from '../../../../utils/utility';

import styles from './styles.module.scss';
import Headline from '../../components/Headline';
import { getUserArea, prioritizeAreas } from 'modules/actions/MissionStatementActions';
import StepsFooter from 'scenes/MissionStatement/components/StepsFooter';

const list = {
    AVAILABLE: 'availableList',
    SELECTED: 'selectedList'
};

const PrioritizeAreas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userCurentWorkspaceId, userAreas } = useSelector(state => state.missionStatement);
    const { state } = useLocation();
    const [areasForShow, setAreaForShow] = useState([]);


    useEffect(() => {
        if (state && state.curentUserWorkspaceId && areasForShow.length === 0) {
            dispatch(getUserArea(state.curentUserWorkspaceId))
        }
    }, [userCurentWorkspaceId])

    useEffect(() => {
        if (userAreas.data) {
            setAreaForShow([])
            if (userAreas.data.length) {
                setAreaForShow([...userAreas.data.map((area, i) => ({ ...area, weight: userAreas.data.length - i }))])
            }
        }

    }, [userAreas])



    const onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination)
            return;

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                areasForShow,
                source.index,
                destination.index
            );

            if (source.droppableId !== list.SELECTED) {
                setAreaForShow([...items.map((area, i) => ({ ...area, weight: items.length - i }))]);
                dispatch(prioritizeAreas(items.map((area,i) => ({id: area.id, priority: areasForShow.length - i, weight:areasForShow.length - i}))));

            }
        }
    };

    const next = () => {
        navigate("/mission-statement/categories", { state: { curentUserWorkspaceId: state.curentUserWorkspaceId } })
    }
    const previous = () => {
        navigate("/mission-statement/areas", { state: { curentUserWorkspaceId: state.curentUserWorkspaceId } })
    }
    const onLaterClickHandle = () => {
        navigate('/');
    }

    return (
        <div className={styles.content}>
            <div className={styles.wrapper}>
                <Headline
                    data={{
                        first: {
                            complete: true,
                            active: true,
                            text: 'Select Areas',
                            step: 1
                        },
                        second: {
                            step: 2,
                            text: 'Prioritize Areas',
                            active: true
                        },
                        third: {
                            step: 3,
                            text: 'Categories & Subcategories'
                        }
                    }}
                />

                <div className={styles.head}>
                    <h1 className={styles.title}>Prioritize the areas of your life</h1>
                    <p className={styles.text}>Drag areas in order from most important to you (top) to least important
                        (bottom).</p>
                </div>

                <div className={styles.body}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className={styles.group}>
                            <div className={styles.list}>
                                <Droppable droppableId={list.AVAILABLE}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={c(styles.droppable, { [styles.over]: snapshot.isDraggingOver })} {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {areasForShow.map((area, index) => <Area area={area} showWeight
                                                index={index} type="reorder"
                                                key={area.id} />)}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </DragDropContext>
                </div>
            </div>
            <StepsFooter
                onLaterClickHandle={onLaterClickHandle}
                previousStep={previous}
                nextStep={next}
            />
        </div>
    );
}

export default PrioritizeAreas;

// import React, {useEffect, useState} from 'react';
// import {message, Spin, Upload} from 'antd';
// import classes from "./uploadImage.module.scss";
// import {useDispatch} from "react-redux";
// import {updateOption} from "../../../../modules/actions/DecisionMatrixActions";
//
//
// const getBase64 = (img, callback) => {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// };
//
//
// const UploadImage = ({option, decisionId, setImage}) => {
//
//     const [loading, setLoading] = useState(false);
//     const [imageUrl, setImageUrl] = useState();
//     const [previewImage, setPreviewImage] = useState('');
//     const dispatch = useDispatch();
//     const [imgForAdding, setImgForAdding] = useState(null);
//
//     useEffect(() => {
//         if (imgForAdding) {
//             if (option) {
//                 dispatch(updateOption({id: option.id, body: imgForAdding}))
//             }
//
//         }
//     }, [imgForAdding])
//
//     const beforeUpload = (file) => {
//         let reader = new FileReader()
//         reader.readAsDataURL(file)
//         let formData = new FormData()
//         formData.append('image', file)
//         setImage(formData)
//         setImgForAdding(formData)
//         const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//         if (!isJpgOrPng) {
//             message.error('You can only upload JPG/PNG file!');
//         }
//         const isLt2M = file.size / 1024 / 1024 < 2;
//         if (!isLt2M) {
//             message.error('Image must smaller than 2MB!');
//         }
//         return isJpgOrPng && isLt2M;
//     };
//
//
//     const handleChange = (info) => {
//         if (info.file.status === 'uploading') {
//             setLoading(true);
//             return;
//         }
//         if (info.file.status === 'done') {
//             // Get this url from response in real world.
//             getBase64(info.file.originFileObj, (url) => {
//                 setLoading(false);
//                 // console.log(url)
//                 setImageUrl(url);
//             });
//         }
//     };
//
//     const loadingImg = () => {
//         return (<Spin className={classes.centered}/>)
//     }
//
//     return (
//         <div className={classes.img} onClick={(e) => e.stopPropagation()}>
//             {<Upload
//                 name="avatar"
//                 // listType="picture-card"
//                 className={classes.img}
//                 style={{width: '42px', height: '42px', border: 'none'}}
//                 // className="avatar-uploader"
//                 showUploadList={false}
//                 action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
//                 beforeUpload={beforeUpload}
//                 onChange={handleChange}
//                 // onPreview={handlePreview}
//             >
//                 {previewImage ? <img src={previewImage} alt="avatar" style={{width: '100%'}}/> :
//                     <img src={require('./drag-drop-file.gif')} className={classes.img}
//                          style={{objectFit: 'contain'}} alt=""/>}
//
//             </Upload>}
//         </div>
//
//     );
// };
//
// export {UploadImage};
