import axios from "axios";
// import { setupCache } from "axios-cache-adapter";
// import Cookies from "js-cookie";
import { apiBaseURL } from "../utils/constants";
import { getToken } from "utils/utility";
// import { getCookie, removeCookie } from "utils/StorageVariables";

// const project = getProject(window.location.pathname);
// Create `axios-cache-adapter` instance
/* const cache = setupCache({
  maxAge: 24 * 60 * 60 * 1000,
});
const secure_token = getCookie("secure_token");
export const getToken = (name = "lmos-token") => {
  return Cookies.get(name);
}; */

const Axios = axios.create({
  baseURL: apiBaseURL,
  withCredentials: true
  // timeout: 80000,
  // adapter: cache.adapter
});

Axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token)
      config.headers.Authorization = `Token ${token}`;
    return config;
  },
  (error) => Promise.reject(onError)
);

Axios.interceptors.response.use(
  (response) => {
    // return response.data
    return response;
  },
  (error) => {
    if (error && error?.response?.status === 403) {
      // Cookies.remove("lmos-token");
      window.location.pathname = "/";
    }
    if (error && error.response && error.response.status === 401) {
      // Cookies.remove("lmos-token");
    }
    return Promise.reject(error);
  }
);

export { Axios };
