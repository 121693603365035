import { ActionTypes } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  edit: { first: 0, second: 0 }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return { ...state, loading: true };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        edit: { ...state.edit, first: 2 }
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.EDIT: {
      let edit = {};

      if (action.payload === 'reset')
        edit = { first: 0, second: 0 };
      else if (action.payload === 'init')
        edit = { first: 1, second: 1 };
      else if (action.payload === 'second')
        edit = { ...state.edit, second: 2 };

      return {
        ...state,
        loading: false,
        error: action.payload,
        edit
      };
    }

    default:
      return state;
  }
};
