import React from 'react';
import styles from './Goal.module.scss'
import {Popover, Progress} from "antd";
import {DashOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {deleleUserGoal} from "../../../../modules/actions/MissionStatementActions";
import {useNavigate} from "react-router-dom";

const Goal = ({goal, setUserGoals, userGoals}) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const d = new Date();
    const{gap_current_value,gap_target_value} = goal

    const goalProgres = (+gap_current_value)/(+gap_target_value) * 100

    const planProgres = Math.round(((d.getTime() - Date.parse(goal?.startline)) / (Date.parse(goal?.deadline) - Date.parse(goal?.startline))) * 100)



    const content = (
        <div>
            <p onClick={() => {
                setUserGoals([...userGoals.filter(g => goal.statement !== g.statement)])
                // dispatch(deleleUserGoal(goalId))
            }}>Delete</p>
            <p onClick={() => {
                navigate('set-goal', {state: {goalForUpdate: goal}})
            }}>Edit</p>
        </div>
    );

    return (
        <div className={styles.goal}>
            <div className={styles.header}>
                <div className={styles.title}>{goal?.goalStatement}</div>
                <div className={styles.menu}>
                    <Popover content={content} trigger="hover">
                        <DashOutlined rotate={90} style={{marginBottom: '5px'}}/>
                    </Popover>
                </div>
            </div>
            <div className={styles.progresBar}>
                <div className={styles.name}>Goal Progress</div>
                <Progress style={{display: "flex", color: 'green'}} percent={goalProgres} size="small"/>
            </div>
            <div className={styles.progresBar}>
                <div className={styles.name}>Plan progress</div>
                <Progress style={{display: "flex", color: 'green'}} percent={planProgres} size="small"/>
            </div>
            <div className={styles.footer}>
                <div className={styles.update}>Last update: 2 hrs ago</div>
                <div className={styles.time}>Due in 1 day</div>
            </div>

        </div>
    );
};

export default Goal;