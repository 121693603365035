import styles from './styles.module.scss';

const SelectAreas = ({ progress }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.bar} style={{ width: `${progress}%` }}>
        <span>{progress}%</span>
      </div>
    </div>
  );
}

export default SelectAreas;
