import { bulkUpdateOptionCriteria } from "api";
import { Error } from "utils/Response";
import { GetCons } from "../GetCons/action";
import { SetDecisionDetails } from "../GetDecisionDetails/action";
import { GetPros } from "../GetPros/action";
import { ActionTypes } from "./types";

export const BulkUpdateOptionCriteria =
  (Data, optionId, isPros = false, isCons = false, onCloseAIModal, loading) =>
  async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      dispatch(SetLoading({ [loading]: true }));
      const { data, status } = await bulkUpdateOptionCriteria(Data);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
        if (isPros) {
          dispatch(GetPros(optionId));
        }
        if (isCons) {
          dispatch(GetCons(optionId));
        }
        dispatch(SetDecisionDetails(data));
        onCloseAIModal && onCloseAIModal();
        dispatch(SetLoading({ [loading]: false }));
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
      dispatch(SetLoading({ [loading]: false }));
    }
  };

export const SetLoading = (loading) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LOADING,
    payload: loading,
  });
};
