import React, { memo, useState, useEffect, useRef } from "react";
import { Popover, Tooltip } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ReactComponent as Decending } from "./decending.svg";
import { ReactComponent as Assending } from "./assending.svg";
import { ReactComponent as CheckedIcon } from "./checked.svg";
import { ReactComponent as InfoIcon } from "./info.svg";

import classes from "./decisiondetails.module.scss";
import cc from "classnames";
import isEqual from "lodash.isequal";

const CriteriaHead = ({ data: c, columnsToShow, onSort }) => {
  const [hovered, setHovered] = useState(false);
  const [tooltipPlacement, setTooltipPlacement] = useState("top"); // Default tooltip position
  const titleRef = useRef(null); // Ref for the title
  const containerRef = useRef(null)

  useEffect(() => {
    const handleMouseOver = () => {
      if (titleRef.current) {
        const titleRect = titleRef.current.getBoundingClientRect();

        const containerRect = document.documentElement.getBoundingClientRect();
        const containerleft = containerRef.current.getBoundingClientRect()


        // Check if the title is overflowing to the right
        if (titleRect.right > containerRect.right) {
          setTooltipPlacement("left"); // Overflowing to the right
        }

        // Check if the title is overflowing to the left
        else if (titleRect.right < containerRect.left) {
          setTooltipPlacement("right"); // Overflowing to the left
        }
        // If it's not overflowing to either side
        else {
          setTooltipPlacement("top"); // Default position
        }
      }
    };

    if (hovered) {
      handleMouseOver(); // Call the function when mouse is over
    }
  }, [hovered]);





  return (
    <div className="d-flex" ref={containerRef}>
      <div
        className={cc(classes.column, classes.criteria, {
          [classes.short]: columnsToShow.criteriaDescription,
        })}
      >
        <div className={classes.box3Title}>
          <Tooltip
            autoAdjustOverflow={true}
            color="white"
            overlayStyle={{
              maxWidth:"325px",
              borderRadius: "2px"
            }}
            overlayInnerStyle={{
              color: "#404040",
              padding:"15px",
              display:"flex",
              alignItems:"center",
              justifyContent:"center",
              height: "100%",
              borderTop: "3px solid #FEAB3D",
              fontFamily:"inter",
              fontSize:"12px",
              fontWeight:400,
              lineHeight:"20px"
            }}
            open={hovered}
            placement={tooltipPlacement} // Dynamic placement based on overflow
            title={c?.title}
          >
            <div className="d-flex" id="container">
              <span
                ref={titleRef} // Assign the ref to the title element
                className={
                  columnsToShow.criteriaDescription
                    ? classes.descShort
                    : classes.descLong
                }
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
              >
                {c.title}
              </span>
              <div className={classes.optionWeight}>{c?.weight}%</div>
            </div>
          </Tooltip>

          {!columnsToShow.criteriaDescription && (
            <div className={classes.infoPopover}>
              {c.description && (
                <>
                  <Popover
                    overlayClassName="decision_detail_popover"
                    content={
                      <div className={classes.infoPopoverContent}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: c.description.replaceAll("\n", "<br />"),
                          }}
                        />
                      </div>
                    }
                    placement="top"
                  >
                    <InfoIcon />
                  </Popover>
                </>
              )}
            </div>
          )}

          <Popover
            overlayClassName="decision_detail_popover"
            placement="bottomLeft"
            content={
              <div className="popoverCriteriaWrapper">
                <p className="label">SORT SCORE</p>
                <div className="sortContainer">
                  <div
                    className={`cursor-pointer popoverCriteriaDiv ${
                      columnsToShow?.isAscending && "activeButton"
                    }`}
                    onClick={() => onSort("weight", "asc", true)}
                  >
                    <span className="assendingIcon">
                      <Assending />
                    </span>
                    Ascending
                    <CheckedIcon />
                  </div>
                  <div
                    className={`cursor-pointer popoverCriteriaDiv ${
                      columnsToShow?.isAscending === false && "activeButton"
                    }`}
                    onClick={() => onSort("weight", "desc", false)}
                  >
                    <span className="decendingIcon">
                      <Decending />
                    </span>
                    Descending
                    <CheckedIcon />
                  </div>
                </div>
              </div>
            }
            trigger="click"
          >
            <EllipsisOutlined className={classes.popover} rotate={90} />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default memo(
  CriteriaHead,
  (prevProps, nextProps) => {
    const prevPropsWithoutFunction = Object.entries(prevProps).reduce(
      (acc, [key, value]) => {
        if (key !== "onSort" && typeof value !== "function") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const nextPropsWithoutFunction = Object.entries(nextProps).reduce(
      (acc, [key, value]) => {
        if (key !== "onSort" && typeof value !== "function") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    return isEqual(prevPropsWithoutFunction, nextPropsWithoutFunction);
  }
);
