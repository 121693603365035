import React, { useEffect, useState, useRef, Fragment } from "react";
import styled, { css } from "styled-components";

const RoundedProgress = ({
  label = "",
  size = 36,
  progress = 1,
  strokeWidth = 3,
  innerStrokeWidth = 2,
  circleOneStroke = "#F2F2F2",
  circleTwoStroke = "#448FFF",
  fill = "#fff",
}) => {
  const circleRef = useRef(null);
  const [offset, setOffset] = useState(0);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2 - 1;
  const radius2 = size / 2 - innerStrokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);

  return (
    <Wrapper>
      {label && (
        <Label>
          <h3>
            {progress} <span>%</span>
          </h3>
          <h5>{label}</h5>
        </Label>
      )}

      <svg
        width={size}
        height={size}
        fill={fill}
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius2}
          strokeWidth={innerStrokeWidth}
        />
        <circle
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
    </Wrapper>
  );
};

export default RoundedProgress;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 40px; */
  /* height: 40px; */
`;

const Label = styled.div`
  position: absolute;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  font-family: "Inter", sans-serif;
  width: 55px;
  text-align: center;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    color: #000000;

    span {
      font-size: 10px;
      margin-left: -4px;
    }
  }

  h5 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: #737373;
  }
`;
