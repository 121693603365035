import { deleteCriteria } from "api";
import { Error } from "utils/Response";
// import { GetCriteria } from "../GetCriteria/action";
import { DeleteDecisionCriteriasFromReducer } from "../GetDecisionDetails/action";
import { ActionTypes } from "./types";

export const DeleteCriteria = (Data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await deleteCriteria(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 204) {
      // dispatch(GetCriteria(decisionId));
      dispatch(DeleteDecisionCriteriasFromReducer(Data));
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
