import React, { useState } from "react";
import { createPortal } from 'react-dom';
import classes from "./uploadModal.module.scss";
import { Spinner } from "elements";

import { UploadImage } from "scenes/DecisionMatrix";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "./close.svg";
import { UpdateOption } from "redux/DecisionMatrix/UpdateOptions/action";

const UploadModal = ({ option, setIsUpload, decisionId, setOption }) => {
  const [imageX, setImage] = useState({
    image: "",
    size: "",
    type: "image/jpeg",
  });

  const [imgForAdding, setImgForAdding] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const dispatch = useDispatch();

  /* const { success: userOption, loading: userOptionLoading } = useSelector(
    (state) => state.getOptionReducer
  ); */

  const { loading: imgLoader/* , success */ } = useSelector(
    (state) => state.updateOptionsReducer
  );

  /* const { success: decision, loading: decisionLoading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  ); */

  let { image, size, type } = imageX;

  const isJpgOrPng = type === "image/jpeg" || type === "image/png" || type === "image/webp";

  return createPortal(
    <div className={classes.bg}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <span>Upload Picture</span>
          <button
            type="button"
            onClick={() => {
              setIsUpload(false);
            }}
            className={classes.close}
            title="Close"
          >
            <CloseIcon />
          </button>
        </div>

        <div className={classes.body}>
          {imgLoader && <div className={classes.loader}>
            <Spinner size={32} />
            </div>
          }

          <span className={classes.warning}>
            {size >= 2 && (
              <span className={classes.centred}>
                <div>Image must smaller than 2MB!</div>
                <div>Select another image!</div>
              </span>
            )}
            {!isJpgOrPng && (
              <span>
                <div>You can only upload JPG/PNG/WEBP file!</div>
              </span>
            )}
          </span>
          {option.image ? (
            <img src={option.image} alt="" />
          ) : (
            <UploadImage
              setImgForAdding={setImgForAdding}
              imgForAdding={imgForAdding}
              setImage={setImage}
              loading={loading}
              setLoading={setLoading}
              option={option}
            />
          )}
        </div>

        <div className={classes.footer}>
          {imgForAdding || option.image ? (
            <button
              className={classes.delete}
              disabled={loading || imgLoader}
              onClick={() => {
                if (option.image) {
                  dispatch(
                    UpdateOption(
                      {
                        id: option.id,
                        body: { image: null },
                      },
                      decisionId
                    )
                  );
                }
                setImage({ ...imageX, image: null });
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 500);
                setImgForAdding(null);
              }}
            >
              DELETE
            </button>
          ) : (
            ""
          )}
          {image && imgForAdding && size < 2 && isJpgOrPng && (
            <button
              className={classes.upload}
              disabled={loading || imgLoader}
              onClick={() => {
                dispatch(
                  UpdateOption({ id: option.id, body: image }, decisionId)
                );
                setIsClose(true);
                setLoading(true);
                // if(option.image){
                // setIsUpload(false)
                // }
              }}
            >
              UPLOAD
            </button>
          )}

          {(!image || (size >= 2 && !option.image)) && (
            <button
              className={classes.upload}
              onClick={() => {
                setIsUpload(false);
              }}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('portal-root')
  );
};

export { UploadModal };
