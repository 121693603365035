export const getCookie = (key) => {
  return sessionStorage.getItem(key);
};
export const setCookie = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const getLocalItem = (key) => {
  return localStorage.getItem(key);
};
export const setLocalItem = (key, value) => {
  return localStorage.setItem(key, value);
};
export const removeLocalItem = (key, value) => {
  return localStorage.removeItem(key, value);
};

export const removeCookie = (key) => {
  return sessionStorage.removeItem(key);
};

export const removeAllCookie = () => {
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("organization");
  sessionStorage.removeItem("department");
  sessionStorage.removeItem("departmentName");
  sessionStorage.removeItem("previousOrderId");
  sessionStorage.removeItem("selectedSalesEpoch");
  sessionStorage.removeItem("selectedPurchaseItemEpoch");
  sessionStorage.removeItem("selectedSalesItem");
  sessionStorage.removeItem("selectedPurchaseItem");
  sessionStorage.removeItem("selectedBuyer");
  sessionStorage.removeItem("selectedSeller");

  return;
};
