import React, { memo, useState, useRef, useEffect } from "react";
import isEqual from 'lodash.isequal';

import {
  Select
} from "../../components";

// Styles
import classes from "./decisiondetails.module.scss";
import cc from "classnames";

const OptionCriteria = ({ optionCriteria, secondStageScoreIdsToCheck, option, criteriaTitleMap, optionCriteriaArr, activeCriterias, columnsToShow, isShortList, isDecisionSample, onCriteriaChange, onRubricSave, disabled, updateItems }) => {
  const [expandedFacts, setExpandedFacts] = useState({});
  const childPRef = useRef(null);
  const handleClick = evt => {
    if (!evt.target.classList.contains("facts-toggle")) {
      onCriteriaChange({
        showPopup: true,
        optionId: option.id,
        optionName: option.name,
        decisionId: option.decision,
        criteriaId: optionCriteria.criteria,
        optionCriteria,
        criteriaName: criteriaTitleMap[
          optionCriteria?.criteria
        ],
        rubric:
          activeCriterias?.find(
            (criteria) =>
              criteria?.id ===
              optionCriteria?.criteria
          )?.rubric,
      });
    }
  }

  const onFactsToggleHandle = (_, optionCriteriaArr) => () => {
    let expandedFactsCopy = { ...expandedFacts };

    for (let { id } of optionCriteriaArr) {
      expandedFactsCopy = {
        ...expandedFactsCopy,
        [id]: !expandedFactsCopy[id],
      };
    }

    setExpandedFacts(expandedFactsCopy);
     // Defer height calculation to allow for DOM update
     setTimeout(() => {
      if (childPRef.current) {
        const height = childPRef.current.offsetHeight;
        updateItems({height:height, id:option?.id});
      }
    }, 0);
  };

  const onFactsMouseHandle = (e) => {
    if (
      e.target.offsetHeight < e.target.scrollHeight ||
      e.target.offsetWidth < e.target.scrollWidth
    )
      e.target.parentElement
        .querySelector("button")
        .classList.add(classes.active);
  };

  useEffect(() => {
    if (childPRef.current) {
      const height = childPRef.current.offsetHeight;
      updateItems({height:height, id:option?.id});
    }
  }, [optionCriteria?.facts]);


  /* const handleSelectIndexChange = data => {
    setSelectIndex(data);
  } */

  return (
    <div className={classes.columnWrapper}>
      <div
        className={cc(classes.column, {
          [classes.full]:
            columnsToShow.criteriaDescription,
        })}
      >
        {!columnsToShow.criteriaDescription && (
          <div
          ref={childPRef}
            className={cc(classes.box3, {
              [classes.high]:
                option?.highlighted,
              [classes.expand]:
                expandedFacts[
                optionCriteria.id
                ],
            })}
            style={{ width: "243px" }}
            title="Edit this criteria field"
            onClick={handleClick}
          >
            <p
              style={{ color: "#878787" }}
              onMouseEnter={onFactsMouseHandle}
            >
              {optionCriteria?.facts ||
                "Description..."}
            </p>
            <button
              type="button"
              title={
                expandedFacts[
                  optionCriteria.id
                ]
                  ? "Collapse"
                  : "Expand"
              }
              className="facts-toggle"
              onClick={onFactsToggleHandle(
                optionCriteria.id,
                optionCriteriaArr
              )}
            >
              {expandedFacts[
                optionCriteria.id
              ]
                ? "Show less"
                : "Show more"}
            </button>
          </div>
        )}
        <div
          className={cc(classes.boxScore, {
            [classes.high]:
              option?.highlighted,
            [classes.full]:
              columnsToShow.criteriaDescription,
          })}
        >
          <Select
            optionCriteria={optionCriteria}
            isShortList={isShortList}
            isDecisionSample={isDecisionSample}
            rubric={
              activeCriterias?.find(
                (criteria) =>
                  criteria?.id ===
                  optionCriteria?.criteria
              )?.rubric
            }
            isHighlighted={
              isShortList &&
                secondStageScoreIdsToCheck
                  .filter(
                    (item) =>
                      item.criteria ===
                      optionCriteria.criteria
                  )
                  .find(
                    (arr) =>
                      arr.id ===
                      optionCriteria.id
                  )?.id
                ? true
                : false
            }
            disabled={disabled}
            activeCriterias={activeCriterias}
            onRubricSave={onRubricSave}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(OptionCriteria, (prevProps, nextProps) => {
  const prevPropsWithoutFunction = Object.entries(prevProps).reduce((acc, [key, value]) => {
    if (key !== 'onCriteriaChange' && key !== 'onRubricSave' && typeof value !== 'function') {
      acc[key] = value;
    }
    return acc;
  }, {});

  const nextPropsWithoutFunction = Object.entries(nextProps).reduce((acc, [key, value]) => {
    if (key !== 'onCriteriaChange' && key !== 'onRubricSave' && typeof value !== 'function') {
      acc[key] = value;
    }
    return acc;
  }, {});

  return isEqual(prevPropsWithoutFunction, nextPropsWithoutFunction);
});

/* export default memo(OptionCriteria, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}); */
