import { Button, Checkbox, Col, Divider, Row, Collapse } from "antd";
import { COLORS } from "constants/colors";
import React from "react";
import { ReactComponent as Health } from "assets/svg/sortgroup/Health.svg";
import { ReactComponent as Partner } from "assets/svg/sortgroup/Partner.svg";
import { ReactComponent as Growth } from "assets/svg/sortgroup/Growth.svg";
import { ReactComponent as Career } from "assets/svg/sortgroup/Career.svg";
import { ReactComponent as Contribution } from "assets/svg/sortgroup/Contribution.svg";
import { ReactComponent as Finance } from "assets/svg/sortgroup/Finance.svg";
import { ReactComponent as Social } from "assets/svg/sortgroup/Social.svg";
import { ReactComponent as Leisure } from "assets/svg/sortgroup/Leisure.svg";
import { ReactComponent as Environment } from "assets/svg/sortgroup/Environment.svg";
import { groupby, statuses } from "constants/";

const { Panel } = Collapse;

export default function SortByGroup({
  selectedStatus,
  selectedLocations,
  handleChange,
  sorting,
  groupBy,
  onSave,
  setSelectedStatus,
  filterRef,
  handleArchive,
  isArchive,
  handleGetPlan,
}) {
  const list = [
    {
      title: "Health",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Health />,
    },
    {
      title: "Partner",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Partner />,
    },
    {
      title: "Growth",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Growth />,
    },
    {
      title: "Career",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Career />,
    },
    {
      title: "Contribution",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Contribution />,
    },
    {
      title: "Finance",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Finance />,
    },
    {
      title: "Social",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Social />,
    },
    {
      title: "Leisure",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Leisure />,
    },
    {
      title: "Environment",
      sub: [
        { title: "Category 1" },
        { title: "Category 2" },
        { title: "Category 3" },
      ],
      icon: <Environment />,
    },
  ];

  const handleStatus = (title) => {
    if (isArchive) {
      handleChange("isArchive", false);
      handleGetPlan(false);
    }
    if (selectedStatus?.some((e) => e === title)) {
      const removed = selectedStatus?.filter((e) => e !== title);
      setSelectedStatus(removed);
    } else {
      setSelectedStatus([...selectedStatus, title]);
    }
  };
  const handleLocation = (title) => {
    if (selectedLocations?.some((e) => e === title)) {
      const removed = selectedLocations?.filter((e) => e !== title);
      handleChange("selectedLocations", removed);
    } else {
      handleChange("selectedLocations", [...selectedLocations, title]);
    }
  };

  const clearFilter = () => {
    handleChange("selectedLocations", []);
    handleChange("isArchive", false);
    handleGetPlan(false);
    setSelectedStatus([]);
  };

  return (
    <Col
      className={"sortByDiv"}
      onMouseDown={(e) => {
        // alert('dd')
        handleChange("openFilter", true);
        // e.preventDefault();
        // e.stopPropagation();
      }}
    >
      <div className="text_darkGray mb-3">
        {groupBy ? "Group By" : "Status"}
      </div>
      {groupBy &&
        groupby?.map((item, index) => (
          <div key={index} className={"checkboxDivFilter"}>
            <Checkbox
              // checked={selectedStatus?.some(e => e === item)}
              className="mb-1 text_darkGray"
              // onChange={() => handleStatus(item)}
            >
              {item}
            </Checkbox>
          </div>
        ))}
      {!groupBy &&
        statuses?.map((item, index) => (
          <div key={index} className={"checkboxDivFilter"}>
            <Checkbox
              checked={selectedStatus?.some((e) => e === item?.value)}
              className="text_darkGray statusFilter"
              onClick={() => handleStatus(item?.value)}
            >
              {item?.key}
            </Checkbox>
          </div>
        ))}
      {!groupBy && (
        <>
          {/* <Divider type="horizontal" className="bg_white" /> */}
          <Checkbox
            checked={isArchive}
            className="mb-1 text_darkGray statusFilter"
            onClick={handleArchive}
          >
            Archive
          </Checkbox>
        </>
      )}
      {!groupBy && !sorting && (
        <>
          <Divider type="horizontal" />
          {list?.map((item, index) => (
            <Collapse
              defaultActiveKey={[]}
              // activeKey={activeKey}
              expandIconPosition="right"
              ghost
            >
              <Panel
                style={{ padding: 0 }}
                header={
                  <div key={index} className={"checkboxCollapseFilter"}>
                    <Checkbox
                      checked={selectedLocations?.some((e) => e === item.title)}
                      className="text_darkGray"
                      onChange={() => handleLocation(item.title)}
                    >
                      {item.title}
                    </Checkbox>
                  </div>
                }
                key={index}
              >
                {item.sub.map((subItem, subInd) => (
                  <Collapse
                    defaultActiveKey={[]}
                    // activeKey={activeKey}
                    expandIconPosition="right"
                    ghost
                  >
                    <Panel
                      header={
                        <Checkbox
                          className={"checkboxDivFilterSub"}
                          checked={selectedLocations?.some(
                            (e) => e === item.title + subItem.title
                          )}
                          onChange={() =>
                            handleLocation(item.title + subItem.title)
                          }
                        >
                          {subItem.title}
                        </Checkbox>
                      }
                      className="ml-1"
                      key={subInd}
                    ></Panel>
                  </Collapse>
                ))}
              </Panel>
            </Collapse>
          ))}
        </>
      )}
      <Row justify="space-between" align="middle" className="mt-2">
        <div className="text_darkGray c-pointer" onClick={clearFilter}>
          Clear all filters
        </div>
        <Button
          type="primary"
          //  className="whiteButton"
          onClick={onSave}
        >
          {groupBy ? "Group" : "Close"}
        </Button>
      </Row>
    </Col>
  );
}
