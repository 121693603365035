import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Spinner } from "elements";

import { useProject, useToken, useAllocatorAccess } from 'utils/utility';

import styles from './PrivateRoute.module.scss';

import { ALLOCATOR_TOOL, DECISION_COACH } from "../../utils/routes";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Loader() {
  return (
    <div className={styles.centered}>
      <Spinner />
    </div>
  )
}

export function PrivateRoute ({ children }) {
  const { token, success, loading } = useToken();
  const { project } = useProject();
  const isAllocatorUser = useAllocatorAccess();

  const query = useQuery();

  const isLoggedIn = token;
  const secure_token = query.get("secure_token");

  if (isLoggedIn && isAllocatorUser && project === DECISION_COACH)
    return <Navigate to={`/${ALLOCATOR_TOOL}`} replace />

  if (loading || !success)
    return <Loader />;

  if (!isLoggedIn && !secure_token)
    return <Navigate to={`/sign-in?app=${project === 'project-planner' ? 'pp' : project === 'decision-manager' ? 'dm' : ''}`} replace />

  return children;
}

export function GuestRoute({ children }) {
  const { token, loading, success } = useToken();
  const savedApp = Cookies.get("lmos-default-app");

  if (loading || !success)
    return <Loader />

  if (token)
    return <Navigate to={`/${savedApp === 'pp' ? 'project-planner' : 'decision-matrix'}`} replace />

  return children;
}
