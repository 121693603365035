import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Content = styled.div`
  height: 125px;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 4px;
  overflow-y: auto;
  padding-right: 12px;
  max-height: 125px;

  ::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #D7DADE;
    border-radius: 100px;
  }
`;

export const Hr = styled.hr<{ $mt?: number; $mb?: number; }>`
  margin: 12px 0;
  border: none;
  height: 1px;
  background: var(--Neutral-200, #D9D9D9);

  // ${({ $mb }) => $mb || $mb === 0 && `margin-bottom: ${$mb}px;`}
  // ${({ $mt }) => $mt || $mt === 0 && `margin-top: ${$mt}px;`}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ListPlaceholder = styled.span`
  color: var(--Neutral-500, #737373);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Email Item

export const Item = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--Primary-50, #ECF3F6);
  height: 34px;
  gap: 8px;
  padding: 0 5px;
`;

export const EmailText = styled.span`
  flex: 1;
  overflow: hidden;
  color: var(--Neutral-500, #737373);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const DeleteBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
  border: none;
  padding: 6px;

  img {
    transition: transform 0.3s;
  }

  &:hover {
    img {
      transform: scale(1.25);
    }
  }
`;
