import React, { useEffect, useState } from "react";
import classes from "./AIAssistantModal.module.scss";
import { ReactComponent as AssistantIcon } from "./assistant.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import FormInput from "components/FormInput/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Row } from "antd";
import {
  GenerateAICriteria,
  SetAICriteria,
} from "redux/DecisionMatrix/GenerateAICriteria/action";
import Skeleton from "scenes/DecisionMatrix/components/Skeleton/skeleton";
import { websocketConnection } from "utils/websocket";

const AIGenerateCriteriaModal = ({
  onClose,
  decision,
  decisionID,
  handleApply,
}) => {
  const dispatch = useDispatch();
  const [selectedCriterias, setSelectedCriterias] = useState([]);
  const [isCriteriaLoading, setIsCriteriaLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState();
  const [isTryAgain, setIsTryAgain] = useState(true)
  // const { loading, success, error } = useSelector(
  //   (state) => state.generateAICriteriaReducer
  // );
  useEffect(() => {
    // websocketConnection?.connect();
    websocketConnection?.setMessageCallback(handleMessage);

    return () => {
      // websocketConnection.close();
      setResponse("");
      setErrors("");
    };
  }, []);
  // useEffect(() => {
  //   // if (isPurposeLoading || isObjectiveLoading) {
  //   let timer;
  //   // if (websocketConnection) {
  //   timer = setInterval(() => {
  //     websocketConnection.send(
  //       JSON.stringify({
  //         pong: "pong",
  //       })
  //     );
  //   }, 1000);
  //   // }
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // }
  // }, []);
  const handleMessage = (message) => {
    // Handle the received message
    let res = JSON.parse(message);
    if (res?.ping || res?.pong) return;
    setResponse(res?.message); // Set the received message as the response
    setErrors(res?.message?.error?.message);
    setIsCriteriaLoading(false);
  };

  const handleSave = () => {
    handleApply(selectedCriterias);
  };
  useEffect(() => {
    return () => {
      dispatch(SetAICriteria(null));
      setSelectedCriterias([]);
    };
  }, []);

  const handleSuggest = () => {
    // dispatch(GenerateAICriteria(`?decision=${decisionID}`));
    setIsTryAgain(false)
    setErrors("");
    setIsCriteriaLoading(true);
    websocketConnection.send(
      JSON.stringify({
        endpoint: "generate_criteria",
        model_id: decisionID,
        title: decision,
      })
    );
  };

  const handleCheck = (item) => {
    if (
      selectedCriterias?.some((e) => e?.criteria_name === item?.criteria_name)
    ) {
      const removed = selectedCriterias?.filter(
        (e) => e?.criteria_name !== item?.criteria_name
      );
      setSelectedCriterias(removed);
    } else {
      setSelectedCriterias([...selectedCriterias, { ...item }]);
    }
  };
  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.heading}>
              <AssistantIcon />
              <span>AI Assistant</span>
              <span className={classes.beta}>Beta</span>
            </div>
            <button
              type="button"
              onClick={onClose}
              className={classes.close}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>
          <div className={classes.body}>
            <p>Criteria Suggestion</p>
            <p className={classes.suggestText}>
              Select each criteria that fulfills your optimal decision.
            </p>
            <div className={classes.bodyCriteria}>
              <Row gutter={8}>
                <Col span={19}>
                  <div className={`${classes.inputField}`}>
                    <FormInput
                      height="44px"
                      name={"selectedCriteria"}
                      placeholder={"Decision"}
                      error={errors ? errors : null}
                      disabled={true}
                      // setFieldValue={()=>set}
                      value={decision}
                    />
                  </div>
                </Col>
                <Col span={5}>
                  <button
                    type="button"
                    disabled={isCriteriaLoading}
                    className={classes.suggest}
                    onClick={handleSuggest}
                  >
                    {isCriteriaLoading ? "Suggesting" : "Suggest"}
                  </button>
                </Col>
              </Row>
              {isCriteriaLoading ? (
                <Skeleton />
              ) : (
                <Row gutter={2}>
                  {response?.Criteria?.length > 0 && (
                    <>
                      {response?.Criteria?.map((item, index) => (
                        <Col key={index} span={12}>
                          <div className={classes.checkboxDiv1}>
                            <Checkbox
                              checked={selectedCriterias?.some(
                                (e) => e?.criteria_name === item?.criteria_name
                              )}
                              onClick={() => handleCheck(item)}
                              className="checkbox"
                            />
                            <div onClick={() => handleCheck(item)}>
                              {item?.criteria_name}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              )}
            </div>
          </div>

          <div className={classes.footer}>
            <button
              disabled={isTryAgain}
              type="button"
              className={classes.tryagain}
              onClick={handleSuggest}
              style={{ opacity: isTryAgain ? 0.5 : 1 }}
            >
              Try Again
            </button>
            <button
              type="button"
              disabled={selectedCriterias.length === 0}
              className={classes.applyCriteria}
              onClick={handleSave}
            >
              Apply Criteria
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { AIGenerateCriteriaModal };
