import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styled';

import DeleteAccountModal from '../DeleteAccountModal';
import { deleteAccount } from 'modules/actions/AuthActions';

const DeleteAccount = ({ data }) => {
  const dispatch = useDispatch();

  const {
    deleteAccount: { loading }
  } = useSelector((state) => state.auth);

  const [isOpen, setIsOpen] = useState(false);

  const onDeleteConfirmHandle = isDelete => {
    if (isDelete)
      dispatch(deleteAccount(data.public_id || data.id));
    else
      setIsOpen(false);
  }

  return (
    <Styled.Wrapper>
      <Styled.Button onClick={() => setIsOpen(true)} type="button">Delete Account</Styled.Button>
      <DeleteAccountModal show={isOpen} loading={loading} onClose={onDeleteConfirmHandle} />
    </Styled.Wrapper>
  );
};

export default DeleteAccount;
