import React from "react";
import { Link, useParams } from "react-router-dom";

import { sidebarItems } from "constants";

import { ReactComponent as DecisionTool } from "assets/svg/profileIcon.svg";
import { ReactComponent as DashboardIcon } from "assets/svg/dashboard.svg";

import styles from "./Sidebar.module.scss";
import c from "classnames";

import { getProject, PROJECT } from "utils/constants";
import { getToken } from "utils/utility";

export default function Sidebar({ pathname }) {
  // Local Variable
  const menuItems = sidebarItems;

  const project = getProject(pathname);
  const params = useParams();
  const token = getToken();

  return (
    <div
      className={c(styles.main, { [styles.dm]: (project === PROJECT.DM || project === PROJECT.AT) })}
      // collapsed={!sidebarOpen}
      // collapsible
      // collapsedWidth={90}
      // trigger={null}
      // width={274}
    >
      <div className={styles.nav}>
        {menuItems
        .filter((item) => item.project === project && (item.public || token))
        .map(({ title, path, extraParams, key }) => (
          <Link
            key={key}
            to={path}
            className={c(styles.navItem, {
              [styles.active]:
                pathname.replace(/\/+$/, '') === path.replace(/\/+$/, '') ||
                extraParams?.some(param => pathname.includes(param)),
            }, { [styles.disabled]: !token })}
          >
            {title === "Dashboard" ? <DashboardIcon /> : <DecisionTool />}

            <span>{title}</span>
          </Link>
        ))}
      </div>

      {/* <Menu mode="inline" selectedKeys={[pathname]}>
        {menuItems.filter(item => item.project === project).map(({ title, path }) => {
          return (
            <Menu.Item className={styles.menuitem}  key={path}>
              <Link
                to={path}
                className={!sidebarOpen ? styles.navBoxOpen : styles.navBox}
              >
                {title === "Dashboard" ? (
                  <DashboardIcon
                    style={{ marginRight: sidebarOpen ? 32 : 0 }}
                    className={
                      pathname === path ? styles.activeIcon : styles.sideicon
                    }
                  />
                ) : (
                  <DecisionTool
                    className={
                      pathname === path ? styles.activeIcon : styles.sideicon
                    }
                    style={{ marginRight: sidebarOpen ? 32 : 0 }}
                    alt="icon"
                  />
                )}
                <span
                  className={
                    !sidebarOpen ? styles.sidebartitle : styles.sidebartitleOpen
                  }
                  style={{ color: pathname === path ? "#fff" : "#0A3661" }}
                >
                  {title}
                </span>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu> */}

      {/* <div className={styles.triggericon}>
        <img src={Trigger} />
      </div> */}
    </div>
  );
}
