import React, { useEffect, useState } from "react";
import Layout from "../../../components/Auth/Layout";
import * as Styled from "./styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createCollaboratorRequest } from "../../../modules/actions/PlanActions";
import { toast } from "react-toastify";

const RequestAccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("id");

  const [animate, setAnimate] = useState(false);
  const [message, setMessage] = useState("");

  const { loading } = useSelector((state) => state.plan.plan);

  const {
    user: { data: userData },
  } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  const handleSubmit = () => {
    dispatch(
      createCollaboratorRequest(
        {
          project_plan: projectId,
          message: message,
        },
        navigate
      )
    );
  };

  return (
    <Layout bg="login" ppLogo>
      <Styled.Wrapper>
        <Styled.Continaer $animate={animate} $animateDelay={100}>
          <Styled.Title $animate={animate} $animateDelay={100}>
            Request Access
          </Styled.Title>

          <Styled.SubTitle $animate={animate} $animateDelay={100}>
            You need permission to view or collaborate on this project plan.
          </Styled.SubTitle>

          <Styled.label $animate={animate} $animateDelay={100}>
            Full Name
          </Styled.label>
          <Styled.value $animate={animate} $animateDelay={100}>
            {userData?.first_name} {userData?.last_name}
          </Styled.value>

          <Styled.label $animate={animate} $animateDelay={100}>
            Email
          </Styled.label>
          <Styled.value $animate={animate} $animateDelay={100}>
            {userData?.email}
          </Styled.value>

          <Styled.label $animate={animate} $animateDelay={100}>
            Add message
          </Styled.label>

          <Styled.StyledTextarea
            name="message"
            placeholder="Add a message to the project owner"
            $animate={animate}
            $animateDelay={100}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />

          <Styled.SubmitWrapper $animate={animate} $animateDelay={400}>
            <Styled.SubmitStyled
              type="submit"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            >
              Request Access
            </Styled.SubmitStyled>
          </Styled.SubmitWrapper>

          <Styled.Cancel
            $animate={animate}
            $animateDelay={100}
            onClick={() => navigate("/project-planner")}
          >
            Cancel
          </Styled.Cancel>
        </Styled.Continaer>
      </Styled.Wrapper>
    </Layout>
  );
};

export default RequestAccess;
