import React, { useEffect, useState } from "react";
import classes from "./AIAssistantModal.module.scss";
import { ReactComponent as AssistantIcon } from "./assistant.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import FormInput from "components/FormInput/FormInput";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateAIDescriptionDefinition,
  SetAIDescriptionDefinition,
} from "redux/DecisionMatrix/GenerateAIDescriptionDefinition/action";
import {
  GenerateAIPurposeDefinition,
  SetAIPurposeDefinition,
} from "redux/DecisionMatrix/GenerateAIPruposeDefinition/action";
import Skeleton from "scenes/DecisionMatrix/components/Skeleton/skeleton";
import { websocketConnection } from "utils/websocket";
import styled from "styled-components";
// import WebSocketConnection from "utils/websocket";

const PurposeModal = ({
  onClose,
  handleApplyAi,
  decisionID,
  defaultValue,
  decisionTitle,
}) => {
  // const {
  //   success: purpose,
  //   loading,
  //   error,
  // } = useSelector((state) => state.aiPurposeDefinitionReducer);
  // const {
  //   success: objective,
  //   loading: loadingObjective,
  //   error: objectiveError,
  // } = useSelector((state) => state.aiObjectiveDefinitionReducer);
  const dispatch = useDispatch();

  const [changeValue, setChangevalue] = useState(defaultValue);
  const [suggestionData, setSuggestionData] = useState(null);
  const [response, setResponse] = useState("");
  const [isPurposeLoading, setIsPurposeLoading] = useState(false);
  const [isObjectiveLoading, setIsObjectiveLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [isTryAgain, setIsTryAgain] = useState(true)
  const handleSelectChange = (event) => {
    setChangevalue(event.target.value);
  };
  useEffect(() => {
    if (changeValue === "Objectives") {
      if (!response) return;
      setSuggestionData(response?.Objective);
    } else {
      if (!response) return;
      setSuggestionData(response?.Purpose);
    }
  }, [changeValue, response]);

  useEffect(() => {
    return () => {
      dispatch(SetAIPurposeDefinition(null));
      dispatch(SetAIDescriptionDefinition(null));
      setSuggestionData(null);
    };
  }, []);

  useEffect(() => {
    // websocketConnection.connect();
    websocketConnection.setMessageCallback(handleMessage);

    return () => {
      // websocketConnection.close();
      setResponse("");
      setErrors("");
    };
  }, []);
  // useEffect(() => {
  //   // if (isPurposeLoading || isObjectiveLoading) {
  //   let timer;
  //   // if (websocketConnection) {
  //   timer = setInterval(() => {
  //     websocketConnection.send(
  //       JSON.stringify({
  //         pong: "pong",
  //       })
  //     );
  //   }, 1000);
  //   // }
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // }
  // }, [changeValue]);
  const handleMessage = (message) => {
    // Handle the received message
    let res = JSON.parse(message);
    if (res.ping || res.pong) return;
    setResponse(res?.message); // Set the received message as the response
    setErrors(res?.message?.error?.message);
    setIsPurposeLoading(false);
    setIsObjectiveLoading(false);
  };

  const handleSuggest = () => {
    setIsTryAgain(false)
    if (changeValue === "Objectives") {
      setErrors("");
      setIsObjectiveLoading(true);
      websocketConnection.send(
        JSON.stringify({
          endpoint: "generate_objective",
          model_id: decisionID,
        })
      );
      // dispatch(GenerateAIDescriptionDefinition(`?decision=${decisionID}`));
    } else {
      setErrors("");
      setIsPurposeLoading(true);
      websocketConnection.send(
        JSON.stringify({
          endpoint: "generate_purpose",
          model_id: decisionID,
        })
      );
      // dispatch(GenerateAIPurposeDefinition(`?decision=${decisionID}`));
    }
  };
  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.heading}>
              <AssistantIcon />
              <span>AI Assistant</span>
              <span className={classes.beta}>Beta</span>
            </div>
            <button
              type="button"
              onClick={onClose}
              className={classes.close}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>
          <div className={classes.body}>
            <p>{decisionTitle}</p>
            <p className={classes.suggestText}>Suggest Purpose/Objective</p>
            <Row gutter={8}>
              <Col span={9}>
                <div className={`${classes.selectInput}`}>
                  <FormInput
                    height="44px"
                    name={"selectedCriteria"}
                    type="select"
                    onChange={handleSelectChange}
                    defaultValue={changeValue}
                    error={(errors && errors) || null}
                    options={[
                      { value: "Purpose", label: "Purpose", id: 123 },
                      { value: "Objectives", label: "Objectives", id: 1235 },
                    ]}
                  />
                </div>
              </Col>
              <Col span={5}>
                <button
                  type="button"
                  className={classes.suggest}
                  onClick={() => handleSuggest(changeValue)}
                >
                  {isObjectiveLoading || isPurposeLoading
                    ? "Suggesting"
                    : "Suggest"}
                </button>
              </Col>
            </Row>
            {isPurposeLoading || isObjectiveLoading ? (
              <SkeletonWrapper>
                <Skeleton width={"80%"} />
              </SkeletonWrapper>
            ) : (
              <div className={`${classes.inputField}`}>
                <FormInput
                  onChange={(e) => setSuggestionData(e.target.value)}
                  // disabled={true}
                  style={{ minHeight: "66px", background: "#F4F4F4" }}
                  placeholder={"Enter your description"}
                  type="textarea"
                  className="mt-4"
                  value={suggestionData}
                />
              </div>
            )}
          </div>

          <div className={classes.footer}>
            <button
              disabled={isTryAgain}
              type="button"
              className={classes.tryagain}
              // onClick={handleSave}
              onClick={() => handleSuggest(changeValue)}
              style={{ opacity: isTryAgain ? 0.5 : 1 }}
            >
              Try Again
            </button>
            <button
              type="button"
              onClick={() =>
                handleApplyAi(
                  changeValue === "Objectives"
                    ? { key: "description", value: suggestionData }
                    : { key: "purpose", value: suggestionData }
                )
              }
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { PurposeModal };
const SkeletonWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  background-color: #f4f4f4;
`;
