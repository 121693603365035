export const sortBy = (filteredPlans, selectedSortBy, isAscending) => {
  if (selectedSortBy === "Name") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? a?.name.localeCompare(b?.name)
        : b?.name.localeCompare(a?.name)
    );
  } else if (selectedSortBy === "Startline") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? new Date(a?.start_line) - new Date(b?.start_line)
        : new Date(b?.start_line) - new Date(a?.start_line)
    );
  } else if (selectedSortBy === "Deadline") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? new Date(a?.dead_line) - new Date(b?.dead_line)
        : new Date(b?.dead_line) - new Date(a?.dead_line)
    );
  } else if (selectedSortBy === "Goal Progress") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? Number(a?.goal_progress * 100 || 0) -
          Number(b?.goal_progress * 100 || 0)
        : Number(b?.goal_progress * 100 || 0) -
          Number(a?.goal_progress * 100 || 0)
    );
  } else if (selectedSortBy === "Plan Progress") {
    filteredPlans.sort((a, b) =>
      isAscending
        ? Number(a?.plan_progress * 100 || 0) -
          Number(b?.plan_progress * 100 || 0)
        : Number(b?.plan_progress * 100 || 0) -
          Number(a?.plan_progress * 100 || 0)
    );
  }

  return filteredPlans;
};
