import React, { useLayoutEffect } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

import Headline from "../../components/Headline";

import Button from "../../components/Button";

import styles from "./styles.module.scss";
import cc from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import {
  getUserArea,
  getUserCategories,
  getUserSubCategories,
} from "../../../../modules/actions/MissionStatementActions";
import StepsFooter from "scenes/MissionStatement/components/StepsFooter";
import { getProjectNotifications } from "../../../../modules/actions/UserActions";
import Goal from "scenes/MissionStatement/components/Goal/Goal";
import { getToken } from "firebase/messaging";
import { getCookie } from "utils/StorageVariables";

const Goals = () => {
  const navigate = useNavigate();
  let { pathname } = useLocation();

  const { userAreas, userCategories, userSubCategories, userGoals } =
    useSelector((state) => state.missionStatement);

  const workspaceId = localStorage.getItem("workspaceId");
  const dispatch = useDispatch();

  const [activeCategory, setActiveCategory] = useState("Areas");
  const [selectedArea, setSelectedArea] = useState();
  const [itemsForShow, setItemsForShow] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [userGoalsForShow, setUserGoalsForShow] = useState([]);

  useEffect(() => {
    if (userGoals.data.length) {
      setUserGoalsForShow([...userGoals.data]);
    }
  }, [userGoals]);

  const isLoggedIn = getToken();

  useLayoutEffect(() => {
    if (getCookie("secure_token")) return;
    if (isLoggedIn) {
      dispatch(getProjectNotifications());
    }
  }, []);

  useEffect(() => {
    if (workspaceId) {
      dispatch(getUserArea(workspaceId));
    }
  }, [workspaceId]);

  useEffect(() => {
    if (activeCategory === "Categories") {
      dispatch(getUserCategories(selectedArea.id));
    }
  }, [activeCategory]);

  useEffect(() => {
    if (activeCategory === "Areas") {
      setItemsForShow([...userAreas.data]);
      setSelectedItem(userAreas.data[0]);
      setSelectedArea(userAreas.data[0]);
    }
    if (activeCategory === "Categories") {
      setItemsForShow([...userCategories.data]);
      setSelectedItem(userCategories.data[0]);
    }
    if (activeCategory === "Subcategories") {
      setItemsForShow([]);
      setItemsForShow([...userSubCategories.data]);
      setSelectedItem(userSubCategories.data[0]);
    }
  }, [userCategories, userAreas, userSubCategories, activeCategory]);

  useEffect(() => {
    if (selectedItem && activeCategory === "Categories") {
      dispatch(getUserSubCategories(selectedItem.id));
    }
  }, [selectedItem]);

  const selectItem = (area) => {
    setSelectedItem(area);
  };

  const nextStep = () => {};

  const prevStep = () => {
    navigate("/mission-statement/categories", {
      state: { curentUserWorkspaceId: workspaceId },
    });
  };

  const onLaterClickHandle = () => {
    navigate("/");
  };

  const showGoal = (active) => {
    setActiveCategory(active);
  };

  const toGoalForm = () => {
    navigate("set-goal");
  };
  return (
    <div>
      <div
        className={cc({ [styles.wrapActive]: pathname.includes("set-goal") })}
      >
        <div style={{ overflow: "hidden" }} className={styles.content}>
          <div className={styles.wrapper}>
            <Headline
              data={{
                first: {
                  step: 3,
                  text: "Categories & Subcategories",
                  active: true,
                  complete: true,
                },
                second: {
                  step: 4,
                  text: "Goals",
                  active: true,
                },
                third: {
                  step: 5,
                  hide: true,
                },
              }}
            />
            <div className={styles.head}>
              <h1 className={styles.title}>Goals</h1>
              <p className={styles.text}>
                Create goals for areas of categories where needed.
              </p>
            </div>

            <div className={styles.body}>
              <div className={styles.areas}>
                <h2 className={styles.title}>Areas</h2>
                <div>
                  {itemsForShow.map((item) => (
                    <button
                      type="button"
                      onClick={() => selectItem(item)}
                      className={cc(styles.area, {
                        [styles.active]: selectedItem.id === item.id,
                      })}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className={styles.content}>
                <div className={cc(styles.appbar, {})}>
                  <div
                    onClick={() => showGoal("Areas")}
                    className={cc(styles.item, {
                      [styles.active]: activeCategory === "Areas",
                    })}
                  >
                    Areas
                  </div>
                  <div
                    onClick={() => showGoal("Categories")}
                    className={cc(styles.item, {
                      [styles.active]: activeCategory === "Categories",
                    })}
                  >
                    Categories
                  </div>
                  {userSubCategories.data.length &&
                  activeCategory !== "Areas" ? (
                    <div
                      onClick={() => showGoal("Subcategories")}
                      className={cc(styles.item, {
                        [styles.active]: activeCategory === "Subcategories",
                      })}
                    >
                      Subcategories
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.goal}>
                  <p className={styles.title}>{selectedItem?.name}</p>
                  <div className={styles.goals}>
                    {userGoalsForShow.map((goal) => (
                      <Goal
                        goal={goal}
                        setUserGoals={setUserGoalsForShow}
                        userGoalsForShow={userGoalsForShow}
                      />
                    ))}
                  </div>

                  <Button
                    className={styles.button}
                    type="outline"
                    onClick={toGoalForm}
                  >
                    <PlusOutlined />
                    Create Goal
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <StepsFooter
            onLaterClickHandle={onLaterClickHandle}
            previousStep={prevStep}
            disabled={true}
            nextStep={nextStep}
          />
        </div>
      </div>
      <div
        className={styles.formWrapper}
        style={
          pathname.includes("set-goal") ? { zIndex: "0" } : { zIndex: "-1" }
        }
      >
        <Outlet update={true} />
      </div>
    </div>
  );
};

export default Goals;
