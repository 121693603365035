import {GET_USER_PROFILE_REQUEST} from "./AuthReducer";

export const GET_SYSTEM_WORKSPACES_REQUEST = 'GET_SYSTEM_WORKSPACES_REQUEST';
export const GET_SYSTEM_WORKSPACES_SUCCESS = 'GET_SYSTEM_WORKSPACES_SUCCESS';
export const GET_SYSTEM_WORKSPACES_ERROR = 'GET_SYSTEM_WORKSPACES_ERROR';

export const GET_USER_WORKSPACES_REQUEST = 'GET_USER_WORKSPACES_REQUEST';
export const GET_USER_WORKSPACES_SUCCESS = 'GET_USER_WORKSPACES_SUCCESS';
export const GET_USER_WORKSPACES_ERROR = 'GET_USER_WORKSPACES_ERROR';

export const GET_SYSTEM_AREAS_REQUEST = 'GET_SYSTEM_AREAS_REQUEST';
export const GET_SYSTEM_AREAS_SUCCESS = 'GET_SYSTEM_AREAS_SUCCESS';
export const GET_SYSTEM_AREAS_ERROR = 'GET_SYSTEM_AREAS_ERROR';

export const GET_HIERARCHY_REQUEST = 'GET_HIERARCHY_REQUEST';
export const GET_HIERARCHY_SUCCESS = 'GET_HIERARCHY_SUCCESS';
export const GET_HIERARCHY_ERROR = 'GET_HIERARCHY_ERROR';

export const ASSIGN_WORKSPACE_REQUEST = 'ASSIGN_WORKSPACE_REQUEST';
export const ASSIGN_WORKSPACE_SUCCESS = 'ASSIGN_WORKSPACE_SUCCESS';
export const ASSIGN_WORKSPACE_ERROR = 'ASSIGN_WORKSPACE_ERROR';

export const ASSIGN_AREA_REQUEST = 'ASSIGN_AREA_REQUEST';
export const ASSIGN_AREA_SUCCESS = 'ASSIGN_AREA_SUCCESS';
export const ASSIGN_AREA_ERROR = 'ASSIGN_AREA_ERROR';

export const REMOVE_AREA_REQUEST = 'REMOVE_AREA_REQUEST';
export const REMOVE_AREA_SUCCESS = 'REMOVE_AREA_SUCCESS';
export const REMOVE_AREA_ERROR = 'REMOVE_AREA_ERROR';

export const REMOVE_CATEGORY_REQUEST = 'REMOVE_CATEGORY_REQUEST';
export const REMOVE_CATEGORY_SUCCESS = 'REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_ERROR = 'REMOVE_CATEGORY_ERROR';
export const REMOVE_CATEGORY_LOCAL_SUCCESS = 'REMOVE_CATEGORY_LOCAL_SUCCESS';

export const REMOVE_SUBCATEGORY_REQUEST = 'REMOVE_SUBCATEGORY_REQUEST';
export const REMOVE_SUBCATEGORY_SUCCESS = 'REMOVE_SUBCATEGORY_SUCCESS';
export const REMOVE_SUBCATEGORY_ERROR = 'REMOVE_SUBCATEGORY_ERROR';

export const PRIORITIZE_AREAS_REQUEST = 'PRIORITIZE_AREAS_REQUEST';
export const PRIORITIZE_AREAS_SUCCESS = 'PRIORITIZE_AREAS_SUCCESS';
export const PRIORITIZE_AREAS_ERROR = 'PRIORITIZE_AREAS_ERROR';

export const GET_USER_AREAS_REQUEST = 'GET_USER_AREAS_REQUEST';
export const GET_USER_AREAS_SUCCESS = 'GET_USER_AREAS_SUCCESS';
export const GET_USER_AREAS_ERROR = 'GET_USER_AREAS_ERROR';

export const GET_SYSTEM_CATEGORIES_BY_AREA_REQUEST = 'GET_SYSTEM_CATEGORIES_BY_AREA_REQUEST';
export const GET_SYSTEM_CATEGORIES_BY_AREA_SUCCESS = 'GET_SYSTEM_CATEGORIES_BY_AREA_SUCCESS';
export const GET_SYSTEM_CATEGORIES_BY_AREA_ERROR = 'GET_SYSTEM_CATEGORIES_BY_AREA_ERROR';

export const GET_USER_CATEGORIES_BY_AREA_REQUEST = 'GET_USER_CATEGORIES_BY_AREA_REQUEST';
export const GET_USER_CATEGORIES_BY_AREA_SUCCESS = 'GET_USER_CATEGORIES_BY_AREA_SUCCESS';
export const GET_USER_CATEGORIES_BY_AREA_ERROR = 'GET_USER_CATEGORIES_BY_AREA_ERROR';

export const GET_USER_SUBCATEGORIES_BY_AREA_REQUEST = 'GET_USER_SUBCATEGORIES_BY_AREA_REQUEST';
export const GET_USER_SUBCATEGORIES_BY_AREA_SUCCESS = 'GET_USER_SUBCATEGORIES_BY_AREA_SUCCESS';
export const GET_USER_SUBCATEGORIES_BY_AREA_ERROR = 'GET_USER_SUBCATEGORIES_BY_AREA_ERROR';


export const GET_ROLES_BY_CATEGORY_REQUEST = 'GET_ROLES_BY_CATEGORY_REQUEST';
export const GET_ROLES_BY_CATEGORY_SUCCESS = 'GET_ROLES_BY_CATEGORY_SUCCESS';
export const GET_ROLES_BY_CATEGORY_ERROR = 'GET_ROLES_BY_CATEGORY_ERROR';

export const GET_VALUES_BY_CATEGORY_REQUEST = 'GET_VALUES_BY_CATEGORY_REQUEST';
export const GET_VALUES_BY_CATEGORY_SUCCESS = 'GET_VALUES_BY_CATEGORY_SUCCESS';
export const GET_VALUES_BY_CATEGORY_ERROR = 'GET_VALUES_BY_CATEGORY_ERROR';

export const ASSIGN_CATEGORY_REQUEST = 'ASSIGN_CATEGORY_REQUEST';
export const ASSIGN_CATEGORY_SUCCESS = 'ASSIGN_CATEGORY_SUCCESS';
export const ASSIGN_CATEGORY_ERROR = 'ASSIGN_CATEGORY_ERROR';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';


export const ASSIGN_SUB_CATEGORY_REQUEST = 'ASSIGN_SUB_CATEGORY_REQUEST';
export const ASSIGN_SUB_CATEGORY_SUCCESS = 'ASSIGN_SUB_CATEGORY_SUCCESS';
export const ASSIGN_SUB_CATEGORY_ERROR = 'ASSIGN_SUB_CATEGORY_ERROR';

export const UPDATE_SUB_CATEGORY_REQUEST = 'UPDATE_SUB_CATEGORY_REQUEST';
export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS';
export const UPDATE_SUB_CATEGORY_ERROR = 'UPDATE_SUB_CATEGORY_ERROR';

export const USER_CURENT_WORKSPACE_ID = 'USER_CURENT_WORKSPACE_ID';
export const USER_CURENT_WORKSPACE_NAME = 'USER_CURENT_WORKSPACE_NAME';

export const ASSIGN_GOAL_ERROR = 'ASSIGN_GOAL_ERROR';
export const ASSIGN_GOAL_SUCCESS = 'ASSIGN_GOAL_SUCCESS';
export const ASSIGN_GOAL_REQUEST = 'ASSIGN_GOAL_REQUEST';

export const GET_USER_GOALS_ERROR = 'GET_USER_GOALS_ERROR';
export const GET_USER_GOALS_SUCCESS = 'GET_USER_GOALS_SUCCESS';
export const GET_USER_GOALS_REQUEST = 'GET_USER_GOALS_REQUEST';

export const DELETE_USER_GOALS_ERROR = 'DELETE_USER_GOALS_ERROR';
export const DELETE_USER_GOALS_SUCCESS = 'DELETE_USER_GOALS_SUCCESS';
export const DELETE_USER_GOALS_REQUEST = 'DELETE_USER_GOALS_REQUEST';


const block = {
    loading: false,
    error: '',
    success: false
}

const initialState = {
    systemWorkspaces: {...block, data: []},
    systemAreas: {...block, data: []},
    hierarchy: {...block, data: []},
    userAreas: {...block, data: []},
    categories: {...block, data: []},
    categoryRoles: {...block, data: []},
    categoryValues: {...block, data: []},
    userWorkspaces: {...block, data: null},
    // curentUserWorkspaceId: null,
    curentUserWorkspaceName: null,
    userCategories: {...block, data: []},
    userSubCategories: {...block, data: []},
    userGoals: {...block, data: []}
}

export const MissionStatementReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SYSTEM_WORKSPACES_REQUEST:
            return {...state, systemWorkspaces: {...state.systemWorkspaces, loading: true}}
        case GET_SYSTEM_WORKSPACES_SUCCESS:
            return {
                ...state,
                systemWorkspaces: {...state.systemWorkspaces, data: action.data, loading: false, success: true}
            }
        case GET_SYSTEM_WORKSPACES_ERROR:
            return {
                ...state,
                systemWorkspaces: {...state.systemWorkspaces, loading: false, error: action.error}
            }

        case GET_SYSTEM_AREAS_REQUEST:
            return {...state, systemAreas: {...state.systemAreas, loading: true}}
        case GET_SYSTEM_AREAS_SUCCESS:
            return {
                ...state,
                systemAreas: {...state.systemAreas, data: action.data, loading: false, success: true}
            }
        case GET_SYSTEM_AREAS_ERROR:
            return {
                ...state,
                systemAreas: {...state.systemAreas, loading: false, error: action.error}
            }

        case GET_HIERARCHY_REQUEST:
            return {...state, hierarchy: {...state.hierarchy, loading: true}}
        case GET_HIERARCHY_SUCCESS:
            return {
                ...state,
                hierarchy: {...state.hierarchy, data: action.data, loading: false, success: true}
            }
        case GET_HIERARCHY_ERROR:
            return {
                ...state,
                hierarchy: {...state.hierarchy, loading: false, error: action.error}
            }
        case GET_USER_AREAS_SUCCESS:
            return {
                ...state,
                userAreas: {...state.userAreas, data: action.data, loading: false, success: true}
            }

        case GET_USER_AREAS_REQUEST:
            return {...state, userAreas: {...state.userAreas, loading: true}}
        case GET_USER_AREAS_ERROR:
            return {
                ...state,
                userAreas: {...state.userAreas, loading: false, error: action.error}
            }

        case GET_USER_CATEGORIES_BY_AREA_SUCCESS:
            return {
                ...state,
                userCategories: {...state.userCategories, data: action.data, loading: false, success: true}
            }

        case GET_USER_CATEGORIES_BY_AREA_REQUEST:
            return {...state, userCategories: {...state.userCategories, loading: true}}
        case GET_USER_CATEGORIES_BY_AREA_ERROR:
            return {
                ...state,
                userCategories: {...state.userCategories, loading: false, error: action.error}
            }


        case GET_USER_SUBCATEGORIES_BY_AREA_SUCCESS:
            return {
                ...state,
                userSubCategories: {
                    ...state.userSubCategories,
                    data: [...action.data],
                    loading: false,
                    success: true
                }
            }

        case GET_USER_SUBCATEGORIES_BY_AREA_REQUEST:
            return {...state, userSubCategories: {...state.userSubCategories, loading: true}}
        case GET_USER_SUBCATEGORIES_BY_AREA_ERROR:
            return {
                ...state,
                userSubCategories: {...state.userSubCategories, loading: false, error: action.error}
            }


        case PRIORITIZE_AREAS_SUCCESS:
            return {
                ...state,
                userAreas: {...state.userAreas, data: action.data}
            }
        case GET_SYSTEM_CATEGORIES_BY_AREA_SUCCESS:
            return {

                ...state,
                categories: {...state.categories, data: action.data}
            }
        case GET_ROLES_BY_CATEGORY_REQUEST:
            return {
                ...state,
                categoryRoles: {...state.categoryRoles, loading: true}
            }
        case GET_ROLES_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryRoles: {...state.categoryRoles, data: action.data, loading: false, success: true}
            }
        case GET_ROLES_BY_CATEGORY_ERROR:
            return {
                ...state,
                categoryRoles: {...state.categoryRoles, loading: false, error: action.error}
            }
        case GET_VALUES_BY_CATEGORY_REQUEST:
            return {
                ...state,
                categoryValues: {...state.categoryValues, loading: true}
            }
        case GET_VALUES_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryValues: {...state.categoryValues, data: action.data, loading: false, success: true}
            }
        case GET_VALUES_BY_CATEGORY_ERROR:
            return {
                ...state,
                categoryValues: {...state.categoryValues, loading: false, error: action.error}
            }


        case GET_USER_PROFILE_REQUEST:
            return {...state, userWorkspaces: {...state.userWorkspaces, loading: true}}
        case GET_USER_WORKSPACES_SUCCESS:
            return {
                ...state,
                userWorkspaces: {...state.userWorkspaces, data: action.data, loading: false, success: true}
            }
        case GET_USER_WORKSPACES_ERROR:
            return {
                ...state,
                userWorkspaces: {...state.userWorkspaces, loading: false, error: action.error}
            }


        case ASSIGN_CATEGORY_REQUEST:
            return {...state, userCategories: {...state.userCategories, loading: true}}

        case ASSIGN_CATEGORY_SUCCESS:
            return {
                ...state,
                userCategories: {
                    ...state.userCategories,
                    data: [...state.userCategories.data, action.data],
                    loading: false,
                    success: true
                }
            }
        case ASSIGN_CATEGORY_ERROR:
            return {
                ...state,
                userCategories: {...state.userCategories, loading: false, error: action.error}
            }
        case ASSIGN_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                userSubCategories: {
                    ...state.userSubCategories,
                    data: [...new Set([...state.userSubCategories.data, action.data])],
                    loading: false,
                    success: true
                }
            }

        case USER_CURENT_WORKSPACE_ID:
            return {
                ...state,
                curentUserWorkspaceId: action.payload
            }
        case REMOVE_CATEGORY_LOCAL_SUCCESS:
            return {
                ...state,
                userCategories: {
                    ...state.userCategories,
                    data: [...state.userCategories.data.filter(c => c.id !== action.payload.id)],
                    loading: false,
                    success: true
                }
            }


        case USER_CURENT_WORKSPACE_NAME:
            return {
                ...state,
                curentUserWorkspaceName: action.payload
            }


        case UPDATE_CATEGORY_SUCCESS:
            const index = state.userCategories.data.findIndex(c => c.id === action.payload.id)
            const leng = state.userCategories.data.length
            let updatedCategories = [...state.userCategories.data]
            updatedCategories[index] = action.payload
            return {
                ...state,
                userCategories: {...state.userCategories, data: updatedCategories, loading: false, success: true}
            }


        case ASSIGN_GOAL_REQUEST:
            return {
                ...state,
                userGoals: {...state.userGoals, data: [...state.userGoals.data, action.payload], loading: true}
            }
        case ASSIGN_GOAL_SUCCESS:
            return {
                ...state,
                userGoals: {
                    ...state.userGoals,
                    data: [...state.userGoals.data, action.data],
                    loading: false,
                    success: true
                }
            }
        case ASSIGN_GOAL_ERROR:
            return {
                ...state,
                userGoals: {...state.userGoals, loading: false, error: action.error}
            }


        case GET_USER_GOALS_REQUEST:
            return {...state, userGoals: {...state.userGoals, loading: true}}
        case GET_USER_GOALS_SUCCESS:
            return {
                ...state,
                userGoals: {...state.userGoals, data: action.data, loading: false, success: true}
            }
        case GET_USER_GOALS_ERROR:
            return {
                ...state,
                userGoals: {...state.userGoals, loading: false, error: action.error}
            }

        default:
            return state
    }
}
