import React from 'react';
import styles from './styles.module.scss';

const MobilePlaceholder = () => {
  return (
    <div className={styles.wrapper}>
      <img width={368} src="https://i.ibb.co/2vTmSjx/mobile-placeholder.png" />
      <p>Mobile experience coming soon...</p>
      <span>Please switch to desktop to create your decisions</span>
    </div>
  );
};

export { MobilePlaceholder }
