import { duplicateDecision } from "api";
import { Error } from "utils/Response";
import { GetDecision, SetDecision } from "../GetDecision/action";
import { ActionTypes } from "./types";

export const DuplicateDecision = (decisionId, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await duplicateDecision(decisionId);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
      dispatch(SetDecision(data));
    }

    if (callback) {
      callback(data);
    }
  } catch (error) {
    dispatch(GetDecision(false));
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
