import { getDescriptionsSummary } from "api";
import { Error } from "utils/Response";
import { ActionTypes } from "./types";

export const GetDescriptionsSummary = (optionId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await getDescriptionsSummary(optionId);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
