import {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import c from 'classnames';
import {Link, useNavigate} from 'react-router-dom';
import {DragDropContext, Droppable} from "react-beautiful-dnd";

import Area from '../../components/Area';
import Button from '../../components/Button';
import ProgressBar from '../../components/ProgressBar';

import {move, reorder} from '../../../../utils/utility';

import arrowRightIcon from '../../../../assets/svg/ArrowRightWhite.svg';
import arrowLeftIcon from '../../../../assets/svg/ArrowLeftBlue.svg';

import plusIcon from '../../../../assets/svg/plusIcon.svg';
import checkedAllIcon from '../../../../assets/svg/CheckedAll.svg';
import uncheckedAllIcon from '../../../../assets/svg/UncheckedAll.svg';

import styles from './styles.module.scss';
import Headline from '../../components/Headline';

import {
    assignArea,
    getSystemAreas,
    removeArea,
    assignWorkspace, getUserWorkspaces, getUserArea, userCurentWorkspaceId, curentUserWorkspaceName
} from 'modules/actions/MissionStatementActions';
import StepsFooter from 'scenes/MissionStatement/components/StepsFooter';

const list = {
    AVAILABLE: 'availableList',
    SELECTED: 'selectedList'
};

const CreateArea = ({isOpen, onClose, selectedAreas, setSelectedAreas}) => {
    const $input = useRef(null);

    const [value, setValue] = useState('');

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                $input.current.focus();
            }, 300);
        }
    }, [isOpen]);


    const dispatchAddingAreas = () => {
        const area = {
            name: value,
            is_custom: true
        }
        setValue('')
        onClose(!isOpen)
        setSelectedAreas([{...area, id: 1}, ...selectedAreas])
    }

    const onChangeHandle = event => {
        setValue(event.target.value);
    }

    const onBlurHandle = () => {
        !value && onClose();
    }

    return (
        <div className={c(styles.createArea, {[styles.active]: isOpen})}>
            <div className={styles.createAreaContent}>
                <input type="text" value={value} ref={$input} placeholder="Area name..." onChange={onChangeHandle}
                       onBlur={onBlurHandle}/>
                <Button disabled={!value.split('').length} onClick={dispatchAddingAreas}>add</Button>
            </div>

        </div>
    );
}

function compare(arr1, arr2) {
    const added = arr2.filter(a2 => !arr1.find(a1 => a1.name === a2.name));
    const removed = arr1.filter(a1 => !arr2.find(a2 => a1.name === a2.name));

    return {
        added,
        removed
    }
}

const SetupAreas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        systemWorkspaces,
        systemAreas,
        userWorkspaces,
        userAreas
    } = useSelector(state => state.missionStatement);

    const [scope, setScope] = useState(1);
    const [availableAreas, setAvailableAreas] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [isCreateArea, setIsCreateArea] = useState(false);
    const [curentUserWorkspaceId, setCurentUserWorkspaceId] = useState(null);

    useEffect(() => {
        dispatch(getUserWorkspaces())
    }, [])

    useEffect(() => {
        if (systemWorkspaces.data.length) {
            if ( userWorkspaces.data?.length === 0) {
                systemWorkspaces.data.map((w) => {
                    const workspace = {is_custom: false, name: w.name}
                    dispatch(assignWorkspace(workspace))
                })
            }
        }
    }, [systemWorkspaces, userWorkspaces, scope])

    useEffect(() => {
        dispatch(getSystemAreas({id: scope}));
    }, [scope]);

    useEffect(() => {
        setSelectedAreas([])
        if (userWorkspaces.data?.length && systemWorkspaces.data.length) {
            let systemWorkspacename = systemWorkspaces.data.find(w => w.id === +scope)
            let workspaceName = userWorkspaces.data.find(w => w.name === systemWorkspacename.name)
            localStorage.setItem('workspaceId',workspaceName.id)
            dispatch(getUserArea(workspaceName.id))
        }
    }, [userWorkspaces, systemWorkspaces, scope])

    useEffect(() => {
        setSelectedAreas([])

        if (userAreas.data.length) {
            setSelectedAreas([...userAreas.data])
        }
    }, [userAreas, scope])

    useEffect(() => {
        if (systemAreas.data && selectedAreas)
            setAvailableAreas(systemAreas.data.filter(a1 => !selectedAreas.find(a2 => a2.name.toLowerCase() === a1.name.toLowerCase())));
    }, [selectedAreas, systemAreas]);

    const next = async () => {
        let systemWorkspacename = systemWorkspaces.data.find(w => w.id === +scope)
        let workspaceName = userWorkspaces.data.find(w => w.name === systemWorkspacename.name)
        setCurentUserWorkspaceId(workspaceName.id)
        let areaForDelete = userAreas.data.filter(ua => !selectedAreas.find(us => ua.name === us.name))
        let areaForAssign = selectedAreas.filter(a => !a.parent_workspace)
        await dispatchAddingAreas(areaForAssign)
        await dispatchRemovingAreas(areaForDelete)
        setTimeout(() => {
            navigate('/mission-statement/prioritize-areas', {state: {curentUserWorkspaceId: workspaceName.id}})
        }, 1500)

    }
    useEffect(() => {
        dispatch(userCurentWorkspaceId(curentUserWorkspaceId))
    }, [])

    const onScopeChangeHandle = evt => {
        setScope(evt.target.value);
    }

    useEffect(() => {
        if (systemWorkspaces && systemWorkspaces.data.length) {
            let workspaceName = systemWorkspaces.data.find(w => w.id === scope).name
            dispatch(curentUserWorkspaceName(workspaceName))
        }

    }, [systemWorkspaces, scope])

    const onDragEnd = result => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination)
            return;

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                source.droppableId === list.AVAILABLE ? availableAreas : selectedAreas,
                source.index,
                destination.index
            );

            if (source.droppableId === list.SELECTED) {
                setSelectedAreas(items);
            } else {
                setAvailableAreas(items);
            }
        } else {
            const result = move(
                source.droppableId === list.AVAILABLE ? availableAreas : selectedAreas,
                destination.droppableId === list.AVAILABLE ? availableAreas : selectedAreas,
                source,
                destination
            );

            const result2 = compare(selectedAreas, result[list.SELECTED]);

            if (result2.added.length && selectedAreas.length < 10) {
                setSelectedAreas([...selectedAreas, result2.added])
                // dispatchAddingAreas(result2.added);
            }

            if (result2.removed.length) {
                setSelectedAreas([...selectedAreas, result2.removed])
                dispatchRemovingAreas(result2.removed)
                // dispatchRemovingAreas(result2.removed);
            }

            setAvailableAreas(result[list.AVAILABLE]);
            setSelectedAreas(result[list.SELECTED]);

        }
    };

    const onAddClickHandle = () => {
        if (selectedAreas.length < 10) {
            setSelectedAreas([...selectedAreas, ...availableAreas.filter(area => area.checked)].slice(0, 10).map(area => ({
                ...area,
                checked: false
            })))
        }
        setAvailableAreas([...availableAreas.filter(a1 => !selectedAreas.find(a2 => a1.name === a2.name))])
    }

    const onRemoveClickHandle = () => {
        setAvailableAreas([...availableAreas, ...selectedAreas.filter(area => area.checked)].map(area => ({
            ...area,
            checked: false
        })));
        setSelectedAreas(selectedAreas.filter(area => !area.checked));
        dispatchRemovingAreas(selectedAreas.filter(area => area.checked))
    }

    const onAreaAvailableChangeHandle = (target, id) => {
        setAvailableAreas(availableAreas.map(area => area.id === id ? {
            ...area,
            checked: area.id === id ? target.checked : area.checked
        } : area));
    }

    const onAreaSelectedChangeHandle = (target, id) => {
        setSelectedAreas(selectedAreas.map(area => area.id === id ? {
            ...area,
            checked: area.id === id ? target.checked : area.checked
        } : area));
    }

    const onSelectUnselectAllHandle = () => {
        const status = !!availableAreas.filter(area => area.checked).length;
        const count = 10 - selectedAreas.length
        setAvailableAreas(availableAreas.slice(0, count).map(area => ({...area, checked: !status})));
    }

    const onSelectAllHandle = () => {
        setSelectedAreas(selectedAreas.map(area => ({...area, checked: true})));
    }

    const onUnselectAllHandle = () => {
        setSelectedAreas(selectedAreas.map(area => ({...area, checked: false})));
    }

    const onCreateAreaHandle = () => {
        setIsCreateArea(!isCreateArea);
    }

    const onLaterClickHandle = () => {
        navigate('/');
    }

    const previous = () => {
        navigate("/mission-statement/journey")
    }

    const dispatchAddingAreas = areas => {
        let systemWorkspacename = systemWorkspaces.data.find(w => w.id === +scope)
        if (userWorkspaces.data.length) {
            let workspaceName = userWorkspaces.data.find(w => w.name === systemWorkspacename.name)
            areas.forEach(area => {
                if(area.id !==1){
                    dispatch(assignArea({
                        parent_workspace: workspaceName.id,
                        name: area.name,
                        is_custom: false
                    }));
                }
                else {
                    dispatch(assignArea({
                        parent_workspace: workspaceName.id,
                        name: area.name,
                        is_custom: true
                    }));
                }

            });
        }

    }

    const dispatchRemovingAreas = areas => {
        areas.forEach(area => {
            dispatch(removeArea(area.id));
        });
    }

    return (
        <div className={styles.content}>
            <div className={styles.wrapper}>
                <Headline
                    data={{
                        first: {
                            hide: true
                        },
                        second: {
                            step: 1,
                            text: 'Select Areas',
                            active: true
                        },
                        third: {
                            step: 2,
                            text: 'Prioritize Areas'
                        }
                    }}
                />

                <div className={styles.head}>
                    <h1 className={styles.title}>Select top 10 areas that apply to you</h1>
                    <p className={styles.text}>Create and move areas from the available areas (left) to the selected
                        areas (right) to select areas.</p>

                    <ProgressBar progress={100 * (selectedAreas.length / 10)}/>
                    <p className={styles.counter}>({selectedAreas.length}/10 Areas Selected)</p>
                </div>

                <div className={styles.body}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className={styles.group}>
                            <div className={c(styles.toolbar, styles.available)}>
                                <div className={styles.scope}>
                                    <span>Scope:</span>
                                    <select value={scope} onChange={onScopeChangeHandle}>
                                        {systemWorkspaces.data && systemWorkspaces.data.map(workspace => <option
                                            value={workspace.id} key={workspace.id}>{workspace.name}</option>)}
                                    </select>
                                </div>

                                <div>
                                    <Button className={styles.addArea} type="outline"
                                            disabled={selectedAreas.length > 9} size="sm" withIcon
                                            onClick={onCreateAreaHandle}>
                                        <img src={plusIcon} width="20"/>
                                        Create new area
                                    </Button>

                                    <Button className={styles.button} type="outline"
                                            disabled={selectedAreas.length >= 10} size="sm" withIcon
                                            onClick={onSelectUnselectAllHandle}>
                                        {availableAreas.filter(area => area.checked).length
                                            ? (
                                                <>
                                                    <img src={uncheckedAllIcon} width="20"/>
                                                    Unselect all
                                                </>
                                            )
                                            : (
                                                <>
                                                    <img src={checkedAllIcon} width="20"/>
                                                    Select all
                                                </>
                                            )
                                        }
                                    </Button>
                                </div>
                            </div>

                            <h2 className={styles.title}>Choose from the areas below</h2>
                            <div className={styles.list}>
                                <CreateArea isOpen={isCreateArea} scope={scope} setSelectedAreas={setSelectedAreas}
                                            selectedAreas={selectedAreas} systemWorkspaces={systemWorkspaces}
                                            onClose={onCreateAreaHandle}/>
                                <Droppable droppableId={list.AVAILABLE}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={c(styles.droppable, {[styles.over]: snapshot.isDraggingOver})} {...provided.droppableProps}
                                            style={selectedAreas.length >= 10 ? {pointerEvents: 'none'} : {display: "block"}}
                                            ref={provided.innerRef}>
                                            {availableAreas.filter(area => !selectedAreas.find(selectArea => area.name === selectArea.name)).map((area, index) =>
                                                <Area area={area} index={index}
                                                      showCheckbox
                                                      onChange={onAreaAvailableChangeHandle}
                                                      key={area.id}/>)}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>

                        <div className={styles.actions}>
                            <div className={styles.buttonAdd}>
                                <Button style={{width: 140}} disabled={isCreateArea || selectedAreas.length === 10}
                                        onClick={onAddClickHandle}>
                                    ADD
                                    <img src={arrowRightIcon} width="24"/>
                                </Button>
                            </div>
                            <Button type="outline" style={{width: 140}} onClick={onRemoveClickHandle}>
                                <img src={arrowLeftIcon} width="24"/>
                                REMOVE
                            </Button>
                        </div>

                        <div className={styles.group}>
                            <div className={c(styles.toolbar, styles.selected)}>
                                <Button className={styles.button} type="outline" size="sm" withIcon
                                        onClick={onSelectAllHandle}>
                                    <img src={checkedAllIcon} width="20"/>
                                    Select all
                                </Button>
                                <Button className={styles.button} type="outline" size="sm" withIcon
                                        onClick={onUnselectAllHandle}>
                                    <img src={uncheckedAllIcon} width="20"/>
                                    Unselect all
                                </Button>
                            </div>

                            <h2 className={styles.title}>Your areas</h2>
                            <div className={styles.list}>
                                <Droppable droppableId={list.SELECTED}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={c(styles.droppable, {[styles.over]: snapshot.isDraggingOver})} {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {selectedAreas.map((area, index) => <Area area={area} index={index}
                                                                                      showCheckbox showIndex
                                                                                      onChange={onAreaSelectedChangeHandle}
                                                                                      key={area.id}/>)}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </DragDropContext>
                </div>
            </div>
            <StepsFooter
                onLaterClickHandle={onLaterClickHandle}
                previousStep={previous}
                nextStep={next}
                disabled={selectedAreas?.length < 10}
            />
        </div>
    );
}

export default SetupAreas;
