import { updateCons } from "api";
import { Error } from "utils/Response";
import { GetCons } from "../GetCons/action";
import { ActionTypes } from "./types";

export const UpdateCons =
  (Data, consId, id, setEditConsInput) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const { data, status } = await updateCons(Data, consId);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
        dispatch(GetCons(id));
        setEditConsInput(null);
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };
