import styled from "styled-components";
import { fadeInUpMixin } from "../../../components/Auth/animated";
import { Submit } from "../SignUp/styled";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Continaer = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  ${fadeInUpMixin}
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  margin-bottom: 12px;
  ${fadeInUpMixin}
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
  color: #4b545b;
  ${fadeInUpMixin}
`;

export const SubmitWrapper = styled.div`
  width: 300px;
  ${fadeInUpMixin}
`;

export const SubmitStyled = styled(Submit)`
  width: 100%;
  margin-top: 12px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 15px;
  bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #1271a6;
  font-family: "Roboto", sans-serif;
  ${fadeInUpMixin}
`;
export const Terms = styled.div`
  cursor: pointer;
`;
export const Privacy = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 100%;
    background-color: #1271a6;
    left: -10px;
  }
`;
