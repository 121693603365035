import { all, call, put, takeLatest } from "redux-saga/effects";
import { Axios } from "../../api/axios";
import { getSimplifiedError } from "../../utils/error";
import { toast } from "react-toastify";

import {
  GET_SYSTEM_WORKSPACES_REQUEST,
  GET_SYSTEM_WORKSPACES_SUCCESS,
  GET_SYSTEM_WORKSPACES_ERROR,
  GET_SYSTEM_AREAS_REQUEST,
  GET_SYSTEM_AREAS_SUCCESS,
  GET_SYSTEM_AREAS_ERROR,
  GET_HIERARCHY_REQUEST,
  GET_HIERARCHY_SUCCESS,
  GET_HIERARCHY_ERROR,
  ASSIGN_AREA_REQUEST,
  ASSIGN_AREA_SUCCESS,
  ASSIGN_AREA_ERROR,
  REMOVE_AREA_REQUEST,
  REMOVE_AREA_SUCCESS,
  REMOVE_AREA_ERROR,
  PRIORITIZE_AREAS_REQUEST,
  PRIORITIZE_AREAS_SUCCESS,
  PRIORITIZE_AREAS_ERROR,
  GET_SYSTEM_CATEGORIES_BY_AREA_SUCCESS,
  GET_SYSTEM_CATEGORIES_BY_AREA_ERROR,
  GET_SYSTEM_CATEGORIES_BY_AREA_REQUEST,
  GET_ROLES_BY_CATEGORY_SUCCESS,
  GET_ROLES_BY_CATEGORY_ERROR,
  GET_ROLES_BY_CATEGORY_REQUEST,
  GET_VALUES_BY_CATEGORY_SUCCESS,
  GET_VALUES_BY_CATEGORY_ERROR,
  GET_VALUES_BY_CATEGORY_REQUEST,
  ASSIGN_CATEGORY_REQUEST,
  ASSIGN_CATEGORY_SUCCESS,
  ASSIGN_CATEGORY_ERROR,
  ASSIGN_SUB_CATEGORY_REQUEST,
  ASSIGN_SUB_CATEGORY_ERROR,
  ASSIGN_SUB_CATEGORY_SUCCESS,
  ASSIGN_WORKSPACE_SUCCESS,
  ASSIGN_WORKSPACE_ERROR,
  ASSIGN_WORKSPACE_REQUEST,
  GET_USER_WORKSPACES_REQUEST,
  GET_USER_WORKSPACES_ERROR,
  GET_USER_WORKSPACES_SUCCESS,
  GET_USER_AREAS_SUCCESS,
  GET_USER_AREAS_ERROR,
  GET_USER_AREAS_REQUEST,
  GET_USER_CATEGORIES_BY_AREA_SUCCESS,
  GET_USER_CATEGORIES_BY_AREA_ERROR,
  GET_USER_CATEGORIES_BY_AREA_REQUEST,
  GET_USER_SUBCATEGORIES_BY_AREA_REQUEST,
  GET_USER_SUBCATEGORIES_BY_AREA_SUCCESS,
  REMOVE_СATEGORY_SUCCESS,
  REMOVE_СATEGORY_ERROR,
  REMOVE_СATEGORY_REQUEST,
  REMOVE_CATEGORY_SUCCESS,
  REMOVE_CATEGORY_ERROR,
  REMOVE_SUBCATEGORY_SUCCESS,
  REMOVE_SUBCATEGORY_ERROR,
  REMOVE_CATEGORY_REQUEST,
  REMOVE_SUBCATEGORY_REQUEST,
  UPDATE_SUB_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_ERROR,
  UPDATE_SUB_CATEGORY_REQUEST,
  ASSIGN_GOAL_SUCCESS,
  ASSIGN_GOAL_ERROR,
  ASSIGN_GOAL_REQUEST,
  GET_USER_GOALS_REQUEST,
  GET_USER_GOALS_SUCCESS,
  GET_USER_GOALS_ERROR,
} from "../reducers/MissionStatementReducer";
import { DELETE_GOAL_REQUEST } from "../reducers/PlanReducer";

async function getSystemWorkspaces() {
  return await Axios.get(`/api/v1/hierarchy/system_workspaces/`);
}

function* handleGetSystemWorkspaces() {
  try {
    const { data: response } = yield call(getSystemWorkspaces);
    if (response) {
      yield put({
        type: GET_SYSTEM_WORKSPACES_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_SYSTEM_WORKSPACES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSystemAreas(id) {
  return await Axios.get(`/api/v1/hierarchy/system_area/?workspace=${id}`);
}

function* handleGetSystemAreas({ payload }) {
  try {
    const { data: response } = yield call(getSystemAreas, payload.id);
    if (response) {
      yield put({
        type: GET_SYSTEM_AREAS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_SYSTEM_AREAS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getHierarchy() {
  return await Axios.get(`/api/v1/hierarchy/user_area/my_workspaces/`);
}

function* handleGetHierarchy() {
  try {
    const { data: response } = yield call(getHierarchy);

    if (response) {
      yield put({
        type: GET_HIERARCHY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_HIERARCHY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function removeArea(payload) {
  return await Axios.delete(`/api/v1/hierarchy/user_area/${payload}/`);
}

function* handleRemoveArea({ payload }) {
  try {
    const { data: response } = yield call(removeArea, payload);

    if (response) {
      yield put({
        type: REMOVE_AREA_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: REMOVE_AREA_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function removeCategory(payload) {
  return await Axios.delete(`/api/v1/hierarchy/user_category/${payload}/`);
}

function* handleRemoveCategory({ payload }) {
  try {
    const { data: response } = yield call(removeCategory, payload);
    if (response) {
      yield put({
        type: REMOVE_CATEGORY_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: REMOVE_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function removeSubCategory(payload) {
  return await Axios.delete(`/api/v1/hierarchy/user_sub_category/${payload}/`);
}

function* handleRemoveSubCategory({ payload }) {
  try {
    const { data: response } = yield call(removeSubCategory, payload);

    if (response) {
      yield put({
        type: REMOVE_SUBCATEGORY_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: REMOVE_SUBCATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function prioritizeAreas(payload) {
  return await Axios.patch(
    `/api/v1/hierarchy/user_area/set_priorities/`,
    payload
  );
}

function* handlePrioritizeAreas({ payload }) {
  try {
    const { data: response } = yield call(prioritizeAreas, payload);
    if (response) {
      yield put({
        type: PRIORITIZE_AREAS_SUCCESS,
        data: response.slice(0, 10),
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: PRIORITIZE_AREAS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCategory(payload) {
  return await Axios.patch(
    `/api/v1/hierarchy/user_category/${payload.id}/`,
    payload.body
  );
}

function* handleUpdateCategory({ payload }) {
  try {
    const { data: response } = yield call(updateCategory, payload);
    if (response) {
      yield put({
        type: UPDATE_CATEGORY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: UPDATE_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateSubCategory(payload) {
  return await Axios.patch(
    `/api/v1/hierarchy/user_sub_category/${payload.id}/`,
    payload.body
  );
}

function* handleUpdateSubCategory({ payload }) {
  try {
    const { data: response } = yield call(updateSubCategory, payload);
    if (response) {
      yield put({
        type: UPDATE_SUB_CATEGORY_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: UPDATE_SUB_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSystemCategoriesByArea(id) {
  return await Axios.get(`/api/v1/hierarchy/system_category/?area=${id}`);
}

function* handleGetSystemCategoriesByArea({ payload }) {
  try {
    const { data: response } = yield call(
      getSystemCategoriesByArea,
      payload.id
    );
    if (response) {
      yield put({
        type: GET_SYSTEM_CATEGORIES_BY_AREA_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_SYSTEM_CATEGORIES_BY_AREA_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getUserArea(id) {
  return await Axios.get(`/api/v1/hierarchy/user_area/?parent_workspace=${id}`);
}

function* handlegetUserArea({ payload }) {
  try {
    const { data: response } = yield call(getUserArea, payload);
    if (response) {
      yield put({
        type: GET_USER_AREAS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_USER_AREAS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getUserCategories(id) {
  return await Axios.get(`/api/v1/hierarchy/user_category/?parent_area=${id}`);
}

function* handlegetUserCategories({ payload }) {
  try {
    const { data: response } = yield call(getUserCategories, payload);
    if (response) {
      yield put({
        type: GET_USER_CATEGORIES_BY_AREA_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_USER_CATEGORIES_BY_AREA_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getUserSubCategories(payload) {
  return await Axios.get(
    `/api/v1/hierarchy/user_sub_category/?parent_category=${payload}`
  );
}

function* handlegetUserSubCategories({ payload }) {
  try {
    const { data: response } = yield call(getUserSubCategories, payload);
    if (response) {
      yield put({
        type: GET_USER_SUBCATEGORIES_BY_AREA_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_USER_CATEGORIES_BY_AREA_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getRolesByCategory(id) {
  return await Axios.get(
    `/api/v1/hierarchy/system_category_roles/?category=${id}`
  );
}

function* handleGetRolesByCategory({ payload }) {
  try {
    const { data: response } = yield call(getRolesByCategory, payload);
    if (response) {
      yield put({
        type: GET_ROLES_BY_CATEGORY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_ROLES_BY_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getValuesByCategory(id) {
  return await Axios.get(
    `/api/v1/hierarchy/system_category_values/?category=${id}`
  );
}

function* handleGetValuesByCategory({ payload }) {
  try {
    const { data: response } = yield call(getValuesByCategory, payload);
    if (response) {
      yield put({
        type: GET_VALUES_BY_CATEGORY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_VALUES_BY_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function assignArea(payload) {
  return await Axios.post(`/api/v1/hierarchy/user_area/`, payload);
}

function* handleAssignArea({ payload }) {
  try {
    const { data: response } = yield call(assignArea, payload);
    if (response) {
      yield put({
        type: ASSIGN_AREA_SUCCESS,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: ASSIGN_AREA_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function assignCategorys(payload) {
  return Axios.post(`/api/v1/hierarchy/user_category/`, payload);
}

function* handleAssignCategory({ payload }) {
  try {
    const { data: response } = yield call(assignCategorys, payload);
    if (response) {
      yield put({
        type: ASSIGN_CATEGORY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: ASSIGN_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function assignSubCategory(payload) {
  return await Axios.post(`/api/v1/hierarchy/user_sub_category/`, payload);
}

function* handleAssignSubCategory({ payload }) {
  try {
    const { data: response } = yield call(assignSubCategory, payload);
    if (response) {
      yield put({
        type: ASSIGN_SUB_CATEGORY_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: ASSIGN_SUB_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function assignWorkspace(payload) {
  return await Axios.post(`/api/v1/hierarchy/user_workspace/`, payload);
}

function* handleAssignWorkspace({ payload }) {
  try {
    const { data: response } = yield call(assignWorkspace, payload);
    if (response) {
      yield put({
        type: ASSIGN_WORKSPACE_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: ASSIGN_WORKSPACE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getUserWorkspaces() {
  return await Axios.get(`/api/v1/hierarchy/user_workspace/`);
}

function* handleGetUserWorkspaces() {
  try {
    const { data: response } = yield call(getUserWorkspaces);
    if (response) {
      yield put({
        type: GET_USER_WORKSPACES_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_USER_WORKSPACES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function assignGoal(payload) {
  return await Axios.post(`/api/v1/goals_blueprint/goals/`, payload);
}

function* handleAssignGoal({ payload }) {
  try {
    const { data: response } = yield call(assignGoal, payload);
    if (response) {
      yield put({
        type: ASSIGN_GOAL_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: ASSIGN_GOAL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getUserGoals() {
  return await Axios.get(`/api/v1/goals_blueprint/goals/`);
}

function* handleGetUserGoals() {
  try {
    const { data: response } = yield call(getUserGoals);
    if (response) {
      yield put({
        type: GET_USER_GOALS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_USER_GOALS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteUserGoal(payload) {
  return await Axios.delete(`/api/v1/hierarchy/user_area/${payload}/`);
}

function* handleDeleteUserGoal() {
  try {
    const { data: response } = yield call(deleteUserGoal);
    if (response) {
      yield put({
        type: GET_USER_WORKSPACES_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    const errorFormatted = getSimplifiedError(error).toString();

    toast(
      errorFormatted === "Invalid value" ? "Token expired" : errorFormatted,
      { type: "error" }
    );

    yield put({
      type: GET_USER_WORKSPACES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_SYSTEM_WORKSPACES_REQUEST, handleGetSystemWorkspaces),
  takeLatest(GET_SYSTEM_AREAS_REQUEST, handleGetSystemAreas),
  takeLatest(
    GET_SYSTEM_CATEGORIES_BY_AREA_REQUEST,
    handleGetSystemCategoriesByArea
  ),
  takeLatest(GET_HIERARCHY_REQUEST, handleGetHierarchy),
  takeLatest(GET_ROLES_BY_CATEGORY_REQUEST, handleGetRolesByCategory),
  takeLatest(GET_VALUES_BY_CATEGORY_REQUEST, handleGetValuesByCategory),
  takeLatest(GET_USER_WORKSPACES_REQUEST, handleGetUserWorkspaces),
  takeLatest(GET_USER_AREAS_REQUEST, handlegetUserArea),
  takeLatest(GET_USER_CATEGORIES_BY_AREA_REQUEST, handlegetUserCategories),
  takeLatest(
    GET_USER_SUBCATEGORIES_BY_AREA_REQUEST,
    handlegetUserSubCategories
  ),
  takeLatest(GET_USER_GOALS_REQUEST, handleGetUserGoals()),
  takeLatest(ASSIGN_AREA_REQUEST, handleAssignArea),
  takeLatest(ASSIGN_WORKSPACE_REQUEST, handleAssignWorkspace),
  takeLatest(ASSIGN_CATEGORY_REQUEST, handleAssignCategory),
  takeLatest(ASSIGN_SUB_CATEGORY_REQUEST, handleAssignSubCategory),
  takeLatest(ASSIGN_GOAL_REQUEST, handleAssignGoal),
  takeLatest(REMOVE_AREA_REQUEST, handleRemoveArea),
  takeLatest(REMOVE_CATEGORY_REQUEST, handleRemoveCategory),
  takeLatest(REMOVE_SUBCATEGORY_REQUEST, handleRemoveSubCategory),
  takeLatest(DELETE_GOAL_REQUEST, handleDeleteUserGoal),
  takeLatest(PRIORITIZE_AREAS_REQUEST, handlePrioritizeAreas),
  takeLatest(UPDATE_CATEGORY_REQUEST, handleUpdateCategory),
  takeLatest(UPDATE_SUB_CATEGORY_REQUEST, handleUpdateSubCategory),
]);
