import React from "react";
import styles from "./styles.module.scss";

import TaskList from "./TaskList";

export default function TaskListView({ title, item, index }) {
  const noProject = title === "No Project Plan";

  return (
    <>
      <h2 className={styles.heading}>{noProject ? "Unassigned" : title}</h2>

      {index === 0 && (
        <ul
          className={styles.listHeader}
          style={{
            borderLeftColor: noProject ? "#A3A3A3" : "#1c8b7e",
          }}
        >
          <li>{noProject ? "Task Name" : "Action Item Name"}</li>
          <li>Weight</li>
          <li>Status</li>
          <li>{noProject ? "Start Date" : "Start"}</li>
          <li>{noProject ? "Due Date" : "Deadline"}</li>
          <li>Duration</li>
          <li>Assignee</li>
        </ul>
      )}

      {item?.map((task, index) => {
        return <TaskList key={index} task={task} noProject={noProject} />;
      })}
    </>
  );
}
