import Cookies from "js-cookie";
import TagManager from "react-gtm-module";

export const CHECK_AUTH_REQUEST = "CHECK_AUTH_REQUEST";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const CHECK_AUTH_ERROR = "CHECK_AUTH_ERROR";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_LEAVE = "SIGNUP_LEAVE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_ERROR = "GOOGLE_LOGIN_ERROR";

export const FACEBOOK_LOGIN_REQUEST = "FACEBOOK_LOGIN_REQUEST";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_ERROR = "FACEBOOK_LOGIN_ERROR";

export const APPLE_LOGIN_REQUEST = "APPLE_LOGIN_REQUEST";
export const APPLE_LOGIN_SUCCESS = "APPLE_LOGIN_SUCCESS";
export const APPLE_LOGIN_ERROR = "APPLE_LOGIN_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_RESET_VALUES = "FORGOT_PASSWORD_RESET_VALUES";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const GET_USER_FROM_LOCAL_STORAGE_REQUEST =
  "GET_USER_FROM_LOCAL_STORAGE_REQUEST";
export const GET_USER_FROM_LOCAL_STORAGE_SUCCESS =
  "GET_USER_FROM_LOCAL_STORAGE_SUCCESS";
export const GET_USER_FROM_LOCAL_STORAGE_ERROR =
  "GET_USER_FROM_LOCAL_STORAGE_ERROR";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";

export const CHANGE_USER_PROFILE_REQUEST = "CHANGE_USER_PROFILE_REQUEST";
export const CHANGE_USER_PROFILE_SUCCESS = "CHANGE_USER_PROFILE_SUCCESS";
export const CHANGE_USER_PROFILE_ERROR = "CHANGE_USER_PROFILE_ERROR";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";

export const SET_MODAL = "SET_MODAL";
export const RESET_BLOCK_AUTH = "RESET_BLOCK_AUTH";

const block = {
  loading: false,
  error: "",
  success: false,
};

const initialState = {
  token: { ...block, data: "" },
  signup: { ...block },
  login: { ...block },
  user: { ...block, data: { token: "" } },
  googleLogin: { ...block },
  facebookLogin: { ...block },
  appleLogin: { ...block },
  forgotPassword: { ...block },
  changePassword: { ...block },
  logout: { ...block },
  inviteUser: { ...block },
  resetPassword: { ...block },
  deleteAccount: { ...block },
  isModal: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_AUTH_REQUEST:
      return {
        ...state,
        token: {
          ...state.token,
          loading: action.params?.silent ? false : true,
        },
      };
    case CHECK_AUTH_SUCCESS:
      const token = action.data.token;

      return {
        ...state,
        token: {
          ...state.token,
          data: token,
          loading: false,
          success: true,
        },
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...action.data.user,
          },
          loading: false,
          success: true,
        },
      };
    case CHECK_AUTH_ERROR:
      return {
        ...state,
        token: { ...state.token, loading: false, error: action.error },
        user: { ...state.user, loading: false, error: action.error },
      };

    case SET_MODAL:
      return { ...state, isModal: action.payload };
    case SIGNUP_REQUEST:
      return { ...state, signup: { ...state.signup, loading: true } };
    case SIGNUP_LEAVE:
      return {
        ...state,
        signup: { ...state.signup, loading: false, success: false },
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signup: { ...state.signup, loading: false, success: true },
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        signup: { ...state.signup, loading: false, error: action.error },
      };

    case LOGIN_REQUEST:
      return { ...state, login: { ...state.login, loading: true } };
    case LOGIN_SUCCESS:
      TagManager.dataLayer({
        dataLayer: {
          event: "identify",
          user: action.data,
        },
      });

      return {
        ...state,
        token: {
          data: action.data.token,
          loading: false,
          success: true,
        },
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...action.data.user,
          },
          loading: false,
          success: true,
        },
        login: { ...state.login, loading: false, success: true },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        login: { ...state.login, loading: false, error: action.error },
      };

    case GOOGLE_LOGIN_REQUEST:
      return { ...state, googleLogin: { ...state.googleLogin, loading: true } };
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        googleLogin: { ...state.googleLogin, loading: false, success: true },
      };
    case GOOGLE_LOGIN_ERROR:
      return {
        ...state,
        googleLogin: {
          ...state.googleLogin,
          loading: false,
          error: action.error,
        },
      };

    case FACEBOOK_LOGIN_REQUEST:
      return {
        ...state,
        facebookLogin: { ...state.facebookLogin, loading: true },
      };
    case FACEBOOK_LOGIN_SUCCESS:
      return {
        ...state,
        facebookLogin: {
          ...state.facebookLogin,
          loading: false,
          success: true,
        },
      };
    case FACEBOOK_LOGIN_ERROR:
      return {
        ...state,
        facebookLogin: {
          ...state.facebookLogin,
          loading: false,
          error: action.error,
        },
      };

    case APPLE_LOGIN_REQUEST:
      return { ...state, appleLogin: { ...state.appleLogin, loading: true } };
    case APPLE_LOGIN_SUCCESS:
      return {
        ...state,
        appleLogin: { ...state.appleLogin, loading: false, success: true },
      };
    case APPLE_LOGIN_ERROR:
      return {
        ...state,
        appleLogin: {
          ...state.appleLogin,
          loading: false,
          error: action.error,
        },
      };

    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, loading: true },
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          success: true,
        },
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case FORGOT_PASSWORD_RESET_VALUES:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          success: false,
          error: "",
        },
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: { ...state.changePassword, loading: true },
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          success: true,
        },
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: action.error,
        },
      };

    case INVITE_USER_REQUEST:
      return {
        ...state,
        inviteUser: { ...state.inviteUser, loading: true },
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteUser: {
          ...state.inviteUser,
          loading: false,
          success: true,
        },
      };
    case INVITE_USER_ERROR:
      return {
        ...state,
        inviteUser: {
          ...state.inviteUser,
          loading: false,
          error: action.error,
        },
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: { ...state.resetPassword, loading: true },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          success: true,
        },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          error: action.error,
        },
      };
    case GET_USER_FROM_LOCAL_STORAGE_REQUEST:
      return {
        ...state,
        user: { ...state.user, loading: true },
      };
    case GET_USER_FROM_LOCAL_STORAGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          data: action.data,
          success: true,
        },
      };
    case GET_USER_FROM_LOCAL_STORAGE_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: action.error,
        },
      };

    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        user: { ...state.user, loading: true },
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: action.error,
        },
      };

    case CHANGE_USER_PROFILE_REQUEST:
      return {
        ...state,
        user: { ...state.user, loading: true },
      };
    case CHANGE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          success: true,
          data: {
            ...state.user.data,
            ...action.data,
          },
        },
      };
    case CHANGE_USER_PROFILE_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: action.error,
        },
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      };
    case LOGOUT_SUCCESS:
      localStorage.clear();

      return {
        ...initialState,
        user: {
          ...state.user,
          data: {},
          loading: false,
          success: true,
        },
        token: {
          ...state.token,
          data: "",
          success: true,
          loading: false,
        },
        logout: {
          success: true,
        },
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        user: { ...state.user, loading: false, error: true },
      };

    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          loading: true,
        },
      };

    case DELETE_ACCOUNT_SUCCESS:
      localStorage.clear();

      return {
        ...initialState,
        deleteAccount: {
          ...state.deleteAccount,
          loading: false,
          success: true,
        },
        user: {
          data: {
            token: "",
          },
        },
        logout: {
          success: true,
        },
      };

    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          loading: false,
          error: true,
        },
      };

    case RESET_BLOCK_AUTH:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...initialState[action.payload.blockType],
        },
      };

    default:
      return state;
  }
};
