import { FC, useEffect, useRef, useState } from "react";
import { InputField, Button } from "elements";

import LinkIcon from "./link.svg";

import * as Styled from "./styled";
import { useDispatch } from "react-redux";

// @ts-ignore
import { Axios } from "api/axios";

interface IProps {
  project: any;
}

const PublicLink: FC<IProps> = ({ project }) => {
  // const [hashedId, setHashedId] = useState("");
  const [buttonText, setButtonText] = useState("Copy Link");
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /* Axios.post(`/api/v1/decision_matrix/decisions/get-hash/`, {
      decision_id: decision?.id,
    }).then((data: any) => {
      if (data?.data?.hashed_id) {
        setHashedId(data.data.hashed_id);
        setIsLoading(false);
      }
    }); */
  }, []);

  // console.log('[project]', project)

  const defaultValue = `${window.location.origin}/shared-project/${project?.encrypted_id}`;

  const handleCopyClick = () => {
    // Select the text in the input field
    inputRef.current?.select();

    // Copy text to clipboard
    navigator.clipboard.writeText(defaultValue);

    // Change button text
    setButtonText("Copied!");

    // Set timeout to change text back after 2 seconds
    setTimeout(() => {
      setButtonText("Copy Link");
    }, 2000);
  };

  return (
    <Styled.Content>
      <Styled.InputWrapper>
        <InputField
          value={isLoading ? "Loading..." : defaultValue}
          readOnly
          disabled={isLoading || !project?.encrypted_id}
          ref={inputRef}
          parentStyle={{ flex: 1 }}
        />

        <Button
          onClick={handleCopyClick}
          disabled={isLoading || !project?.encrypted_id}
          style={{
            width: 103,
            justifyContent: "space-between",
            whiteSpace: "nowrap",
          }}
        >
          {buttonText}
          <img src={LinkIcon} alt="" />
        </Button>
      </Styled.InputWrapper>

      <Styled.Disclaimer>
        NOTE: Anyone on the internet with the link can view
      </Styled.Disclaimer>
    </Styled.Content>
  );
};

export default PublicLink;
