import React from 'react';
import styles from './styles.module.scss'
import Button from "../Button"

const StepsFooter = ({
                         onLaterClickHandle,
                         previousStep,
                         nextStep,
                         disabled,
                         selectedAreas
                     }) => {

    return (
        <div className={styles.footer}>
            <Button type="link" size="sm" onClick={onLaterClickHandle}>
                Configure Later
            </Button>
            <div className={styles.footerNavigation}>
                <Button
                    size="sm"
                    type="outline"
                    className={styles.back}
                    onClick={previousStep}
                >
                    BACK
                </Button>
                <Button size="sm"
                        onClick={nextStep}
                        disabled={disabled}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default StepsFooter;