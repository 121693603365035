// @ts-ignore
import { getSharedDecisions } from "api";

import { ActionTypes } from "./types";

import Notify from "utils/notify";

export const GetSharedDecisions = (callback?: (data: any[]) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data } = await getSharedDecisions();

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (callback) {
      callback(data);
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
