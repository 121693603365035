import React, { useEffect, useMemo, useRef, useState } from "react";
import classes from "./compareOptionHeader.module.scss";
import useWindowSize from "utils/useWindowSize";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChevronIcon } from "./chevron.svg";
import { Dropdown, Popover, Tooltip } from "antd";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as Print } from "./print.svg";
import { ReactComponent as Cup } from "./cup.svg";

import moment from "moment";
import { ReactComponent as CupIcon } from "./cup.svg";
import ReactToPrint from "react-to-print";
import Placeholder from "./Placeholder.jpg";
import {
  GetDecisionDetails,
  SetDecisionDetails,
} from "redux/DecisionMatrix/GetDecisionDetails/action";
import PrintComparisonOptions from "../PrintCompareOptions";
import styled from "styled-components";
import { useProject } from "../../../../utils/utility";
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const CompareOption = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <PrintComparisonOptions
        finalizeOption={props?.finalizeOption}
        decisionId={props?.decisionId}
        decision={props?.decision}
        activeCriterias={props?.activeCriterias}
      />
    </div>
  );
});
const CompareOptionHeader = ({
  setIsShowFinalizeDecisin,
  isPrint
}) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finalizeOption, setFinalizeOption] = useState({});
  const [activeCriterias, setActiveCriterias] = useState([]);
  const [topOptions, setTopOptions] = useState();
  const { decisionId } = useParams();

  const { project } = useProject();

  const query = useQuery();
  const is_sample = query.get("is_sample");

  const { success: decision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  const [activeButton, setActiveButton] = useState("finalize_decision");

  useEffect(() => {
    if (!decisionId) return;
    dispatch(GetDecisionDetails(decisionId, true, is_sample));
    return () => {
      dispatch(SetDecisionDetails(null));
    };
  }, [decisionId]);

  useEffect(() => {
    let topOptions = decision?.options
      ?.filter((item) => item?.shortlist)
      ?.sort((a, b) => b.adjusted_score - a.adjusted_score)
      ?.slice(0, 3);
    setTopOptions(topOptions);
  }, [decision]);

  const handleButtonClick = (tab) => {
    setActiveButton(tab);
  };

  const toEditDecision = (e, decision) => {
    e.stopPropagation();
    navigate(`/decision-manager/edit-decision/${decision?.id}`);
  };
  const compareOptionRef = useRef();
  useEffect(() => {
    if (decision && decision.criteria)
      setActiveCriterias(
        decision?.criteria
          ?.filter((c) => c.active)
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => b.weight - a.weight)
      );
  }, [decision]);
  const items = [
    {
      key: "1",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={(e) => toEditDecision(e, decision)}
          >
            Edit
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv "
            onClick={(e) => {
              setIsModalOpen(true);
            }}
          >
            Delete
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      {" "}
      {width > 1199 && (
        <div
          className={classes.wrapper}
          style={{
            backgroundImage: `url(https://res.cloudinary.com/talaash/image/upload/v1676390506/Header_ws5ree.png)`,
          }}
        >
          <div className={classes.leftContent}>
            <div className={classes.leftTopContent}>
              <button
                className={classes.back}
                onClick={() => {
                  if (pathname.split("/")?.includes("option-comparison")) {
                    navigate(`/${project}/${decisionId}`);
                    return;
                  }
                  navigate(`/${project}`);
                }}
              >
                <ChevronIcon />
              </button>
              <div className={classes.titleWrapper}>
                <div className={classes.title}>
                  <p>Compare Options</p>
                  <Dropdown
                    overlayClassName="decision_header_dropdown"
                    menu={{ items }}
                    trigger={["click"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <OptionsIcon className={classes.optionIcon} />
                    </a>
                  </Dropdown>
                </div>
              </div>

              <div className="me-2">
                <div className={classes.printBtn}>
                  <p className="ps-1">
                    {isPrint ? (
                      <ReactToPrint
                        trigger={() => (
                          <div className="cursor-pointer popoverDivPrint w-100">
                            <Print />
                            &nbsp; Print Comparison
                          </div>
                        )}
                        content={() => compareOptionRef.current}
                      />
                    ) : (
                      <div className="cursor-not-allowed popoverDivPrint w-100" style={{ opacity: 0.5 }}>
                        <Print />
                        &nbsp; Print Comparison
                      </div>
                    )}

                    {/* <ReactToPrint
                      trigger={() => (
                        <div className="cursor-pointer popoverDivPrint w-100">
                          <Print />
                          &nbsp; Print Comparison
                        </div>
                      )}
                      content={() => compareOptionRef.current}
                    /> */}
                  </p>
                  <div style={{ display: "none" }}>
                    <CompareOption
                      ref={compareOptionRef}
                    //   finalizeOption={finalizeOption}
                    //   ref={compareOptionRef}
                    //   decisionId={decisionId}
                    //   decision={decision}
                    //   activeCriterias={activeCriterias}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.rightContentTop}>
            <div className={classes.content}>
              <div className={classes.topOptionSection}>
                <div className={classes.winnion}>
                  <div style={{ width: "125px" }}>
                    <CupIcon />
                    <span>SHORTLIST TOP 3</span>
                  </div>
                  <div style={{ width: "150px", marginLeft: "10px" }}>
                    {topOptions?.map((item) => (
                      <div className={classes.optionContainer}>
                        <img src={item?.image || Placeholder} alt="img" />
                        {/* <div className={classes.content}>
                          <h4>{item?.name}</h4>
                          <p>
                            {item?.adjusted_score}% &nbsp;{" "}
                            {item?.weighted_score}
                          </p>
                        </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className={
                  activeButton === "finalize_decision"
                    ? classes.button
                    : classes.inActive
                }
                onClick={() => {
                  setIsShowFinalizeDecisin(true);
                  handleButtonClick("finalize_decision");
                }}
              >
                FINALIZE DECISION
              </button>
            </div>
          </div>
          {/* )} */}
        </div>
      )}
      <Header>
        <LeftSide>
          {" "}
          <CustomTabButton isActive>Details</CustomTabButton>
          {/* <CustomTabButton isDisable>Verdict</CustomTabButton> */}
          {/* <CustomTabButton isDisable>Criteria</CustomTabButton> */}
        </LeftSide>
      </Header>
    </div>
  );
};

export default CompareOptionHeader;

const Header = styled.div`
  width: 100%;
  background-color: #fff;
`;
const LeftSide = styled.div`
  max-width: 10%;
  display: flex;
  gap: 1rem;
  margin: 0 0.8rem 0;
  min-width: 190px;
`;
const CustomTabButton = styled.button`
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: ${({ isActive }) => isActive && `2px solid #1271a6 `};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ isActive }) => ` ${isActive ? 500 : 400}`};
  line-height: 32px;
  color: ${({ isActive }) => `${isActive ? "#1271a6" : "#737373"}`};
  cursor: pointer;
  margin-top: 0.5rem;
  user-select:${({ isDisable }) => isDisable && "none"}

  &:focus {
    color: #1271a6;
    border-bottom: 2px solid #1271a6;
    font-weight: 500;
  }
`;
