import { ActionTypes } from "./types";

export const SetSelectedOption2 = (Data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: Data,
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
