import { FC, useEffect, useRef, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { InputField, Button } from "elements";

import InviteUsers from "./InviteUsers";

import Placeholder from "./Placeholder.jpg";
import LinkIcon from "./link.svg";

import * as Styled from "./styled";

// @ts-ignore
import { Axios } from "api/axios";

// @ts-ignore
import { useAllocatorAccess } from "utils/utility";
import InviteSuccess from "./InviteSuccess";

interface IDecision {
  id: number;
  options: any[];
  collaborators: any[];
  is_new: boolean;
  created_at: string;
  updated_at: string;
  problem: string;
  description: string;
  purpose: string;
  scoring_rubric: string;
  startline: string;
  deadline: string;
  duration: number;
  priority: number;
  is_sample: boolean;
  user: any;
  goal: any;
  final_option: any;
}

interface IProps {
  isOpen: boolean;
  decision: IDecision;
  onClose: () => void;
}

const useEscapeKeyListener = (onClose: () => void) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);
};

const PublicLinkModal: FC<IProps> = ({ isOpen, decision, onClose }) => {
  const [hashedId, setHashedId] = useState("");
  const [buttonText, setButtonText] = useState("Copy Link");
  const [isLoading, setIsLoading] = useState(true);
  const [isInviteSuccess, setIsInviteSuccess] = useState(false);

  const isAllocatorUser = useAllocatorAccess();

  const inputRef = useRef<HTMLInputElement>(null);

  useEscapeKeyListener(onClose);

  useEffect(() => {
    if (isOpen) {
      Axios.post(`/api/v1/decision_matrix/decisions/get-hash/`, {
        decision_id: decision?.id,
      }).then((data: any) => {
        if (data?.data?.hashed_id) {
          setHashedId(data.data.hashed_id);
          setIsLoading(false);
        }
      });
    }

    // dispatch(UpdateOptionCriteria(body, id, false, false));
  }, [isOpen]);

  const defaultValue = `${window.location.origin}/shared-decision/${hashedId}`;

  const handleCopyClick = () => {
    // Select the text in the input field
    inputRef.current?.select();

    // Copy text to clipboard
    navigator.clipboard.writeText(defaultValue);

    // Change button text
    setButtonText("Copied!");

    // Set timeout to change text back after 2 seconds
    setTimeout(() => {
      setButtonText("Copy Link");
    }, 2000);
  };

  const handleInviteSuccess = () => {
    setIsInviteSuccess(true);
  };

  const handleClose = () => {
    setIsInviteSuccess(false);
    onClose();
  };

  if (!isOpen) return null;

  const DecisionDetails = () => (
    <Styled.DetailContainer>
      <Styled.ImgContainer
        src={decision?.final_option?.image || Placeholder}
        alt=""
      />
      <Styled.SharedContent>
        <Styled.Problem>{decision?.problem}</Styled.Problem>
        {decision?.priority && (
          <Styled.PriorityBox
            high={decision?.priority === 3}
            medium={decision?.priority === 2}
            low={decision?.priority === 1}
          >
            <Styled.PriorityText
              high={decision?.priority === 3}
              medium={decision?.priority === 2}
              low={decision?.priority === 1}
            >
              Priority: {decision?.priority === 1 && "Low"}{" "}
              {decision?.priority === 2 && "Medium"}{" "}
              {decision?.priority === 3 && "High"}
            </Styled.PriorityText>
          </Styled.PriorityBox>
        )}
      </Styled.SharedContent>
    </Styled.DetailContainer>
  );

  const SharableLink = () => (
    <>
      <Styled.InputWrapper>
        <InputField
          value={isLoading ? "Loading..." : defaultValue}
          readOnly
          disabled={isLoading}
          ref={inputRef}
          parentStyle={{ flex: 1 }}
        />

        <Button
          onClick={handleCopyClick}
          disabled={isLoading}
          style={{
            width: 103,
            justifyContent: "space-between",
            whiteSpace: "nowrap",
          }}
        >
          {buttonText}
          <img src={LinkIcon} alt="" />
        </Button>
      </Styled.InputWrapper>

      <Styled.Disclaimer>
        NOTE: Anyone on the internet with the link can view
      </Styled.Disclaimer>
    </>
  );

  return (
    <Styled.Background>
      <Styled.Modal>
        <Styled.Content>
          <Styled.Header>
            <Styled.Heading>Share Decision</Styled.Heading>
            <CloseOutlined onClick={onClose} />
          </Styled.Header>

          {isInviteSuccess ? null : <DecisionDetails />}

          {isAllocatorUser ? (
            isInviteSuccess ? (
              <InviteSuccess
                decisionName={decision?.problem}
                onClose={handleClose}
              />
            ) : (
              <InviteUsers
                decisionId={decision?.id}
                onSuccess={handleInviteSuccess}
              />
            )
          ) : (
            <SharableLink />
          )}
        </Styled.Content>
      </Styled.Modal>
    </Styled.Background>
  );
};

export default PublicLinkModal;
