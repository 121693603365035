import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 10px 12px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #A0C6DB);
  background: var(--Primary-50, #ECF3F6);
  padding: 12px 20px;
  width: 100%;
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.span`
  color: var(--Primary-800, #072D42);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;
