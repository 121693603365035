import Modal from 'react-bootstrap/Modal';
import styled, { css } from 'styled-components';

import CloseIcon from './assets/close.svg';

export const StyledModal = styled(Modal)`
  & .modal {
    padding-left: 0;
    z-index: 9999;
  }

  &.modal-backgrop {
    z-index: 9999;
  }

  & .modal-dialog {
    max-width: 608px !important;
    width: 100%;

    ${({ theme }) => theme.max('sm')`
      margin: 0;
    `}
  }

  & .modal-content {
    border: none;
    border-radius: 0;

    ${({ theme }) => theme.max('md')`
      margin: 0 16px;
    `}
  }

  & .dialogClass {
    max-width: 399px;
    margin-top: 78px;

    ${({ theme }) => theme.max('sm')`
      margin-top: 49px;
    `}
  }
`;

export const MainWrapper = styled.div`
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

export const CloseBtn = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url(${CloseIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  top: 16px;
  right: 16px;
`;

export const Typography = styled.div`
  .center {
    text-align: center;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .no-select {
    user-select: none;
  }

  h1 {
    color: #1B2A3D;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 9px;
  }

  h3 {
    color: #06203A;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 9px;
  }

  ul {
    margin-bottom: 7px;

    li {
      color: rgba(6, 32, 58, 0.80);
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 2px 0;
    }
  }

  p {
    color: #06203A;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 300px;
  margin-bottom: 19px;
`;

export const InputField = styled.input`
  width: 100%;
  border: none;
  color: #171717;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #E5E5E5;
  outline: none;

  &:placeholder {
    color: #A3A3A3;
  }

  &:focus {
    border-bottom-color: #71AACA;
  }
`;

export const Submit = styled.button`
  padding: 6px;
  border: none;
  background: transparent;
  color: #F00000;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  &:disabled {
    color: #FA8B8B;
  }
`;

export const Img = styled.img`
  margin-top: 48px;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Button = styled.button`
  padding: 6px 12px;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;

  ${({ $style }) => $style && $style === 'blue' && css`
    background: var(--primary-500-main, #1271A6);
    color: var(--generic-white, #FFF);
  `}

  ${({ $style }) => $style && $style === 'orange' && css`
    background: var(--warning-50, #FFF4E4);
    color: #FF9900;
  `}
`;

export const SpinnerOverlay = styled.div`
  background: rgba(255,255,255,.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
`;
