import { useEffect, useState } from "react"
import c from "classnames"
import {  useLocation, useNavigate, Outlet } from "react-router-dom"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import Headline from "../../components/Headline"
import { Category } from "../../components/Category"
import {  reorder } from "../../../../utils/utility"
import styles from "./styles.module.scss"
import cc from "classnames"
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import {
    assignCategory, getHierarchy,
    getSystemCategoriesByArea, getUserArea, getUserCategories, updateCategory,
} from "../../../../modules/actions/MissionStatementActions";
import StepsFooter from "scenes/MissionStatement/components/StepsFooter"
import Checkbox from "scenes/MissionStatement/components/Checkbox/Checkbox"


const list = {
    AVAILABLE: "availableList",
    SELECTED: "selectedList",
}

const Categories = () => {
    const navigate = useNavigate()
    const {
        userAreas,
        categories,
        systemAreas,
        userCategories,
        curentUserWorkspaceName,
        hierarchy,
        curentUserWorkspaceId

    } = useSelector(state => state.missionStatement);

    const dispatch = useDispatch();


    const [selectedArea, setSelectedArea] = useState()
    const [availableAreas, setAvailableAreas] = useState([])
    const [comleteAreas2, setComleteAreas2] = useState([])
    const [systemCategories, setSystemCategories] = useState([])
    const [areaId, setAreaId] = useState('');
    const [userCategoriesState, setUserCategories] = useState([]);
    const [userHierachyState, setUserHierachy] = useState('');


    let { pathname, state } = useLocation();
    let update = state?.update ? state.update : 'dsf'


    useEffect(() => {
        dispatch(getHierarchy())
    }, [])

    useEffect(() => {
        if (curentUserWorkspaceId) {
            dispatch(getUserArea(curentUserWorkspaceId))
        }
    }, [])

    useEffect(() => {
        if (hierarchy.data && hierarchy.data.length) {
            setUserHierachy(hierarchy.data.slice(0, 10).filter(w => w.areas.length).find(w => w.workspace === curentUserWorkspaceName)?.areas)
        }
    }, [hierarchy, curentUserWorkspaceName])


    useEffect(() => {
        if (userHierachyState) {
            let c = []
            userHierachyState.filter(a => a.categories.length).forEach((a) => {
                c.push({ id: a.id })

            })
            setComleteAreas2([...c])
            setAvailableAreas([...availableAreas])
        }

    }, [userHierachyState])


    useEffect(() => {
        if (selectedArea) {
            dispatch(getUserCategories(selectedArea.id))
        }

    }, [selectedArea, update])

    useEffect(() => {
        setUserCategories([])
        if (userCategories.data && userCategories.data.length) {
            setUserCategories([...userCategories.data])

        }
    }, [userCategories, selectedArea])

    useEffect(() => {
        if (userCategoriesState.length) {
            setComleteAreas2([{ id: selectedArea.id }, ...comleteAreas2.filter(c => c.id !== selectedArea?.id)])
            setAvailableAreas([...availableAreas])
        } else {
            setComleteAreas2([...comleteAreas2.filter(c => c.id !== selectedArea?.id)])
        }
    }, [userCategoriesState, selectedArea])


    useEffect(() => {
        if (systemAreas.data.length && selectedArea && !selectedArea.is_custom) {
            const areaId = systemAreas.data.find(area => area.name === selectedArea.name)
            setAreaId(areaId.id)
        }
    }, [systemCategories, selectedArea])

    useEffect(() => {
        setTimeout(() => {
            if (!userAreas.data[0]) {
                navigate('/mission-statement/areas')
            }
        }, 2000)

        if (userAreas.data[0]) {
            setAvailableAreas(userAreas.data.slice(0, 10))
            setSelectedArea(userAreas.data[0])
        }
    }, [userAreas])

    useEffect(() => {
        if (selectedArea && !selectedArea.is_custom) {
            dispatch(getSystemCategoriesByArea({ id: areaId }))
        }
    }, [areaId])

    useEffect(() => {

        if (categories.data[0]) {
            setSystemCategories(categories.data)
        }
        if (selectedArea && selectedArea.is_custom) {
            setSystemCategories([])
        }
    }, [categories, areaId, selectedArea])


    const onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination)
            return;

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                userCategoriesState,
                source.index,
                destination.index
            );

            if (source.droppableId !== list.SELECTED) {
                setUserCategories([...items.map((area, i) => ({ ...area, weight: 10 - i }))]);

                items.map((area,i) => (dispatch(updateCategory({
                    ...area,
                    weight: 10 - i,
                    priority:10 - i
                }))))
            }
        }
    };

    const onAreaClickHandle = (id) => () => {
        setSelectedArea(id)
    }

    const onLaterClickHandle = () => {
        navigate("/")
    }

    const selectCategory = (category) => {

        if (userCategoriesState.length < 10) {
            dispatch(assignCategory({
                ...category,
                parent_area: +selectedArea.id,
                name: category.name,
                is_custom: true,
                roles: [],
                values: []
            }))
        }
    }

    const previous = () => {
        navigate("/mission-statement/prioritize-areas", { state: { curentUserWorkspaceId } })
    }
    const toAddCategoryForm = () => {
        // setIsShowForm(true)
        navigate('add-category', { state: { selectedAreaId: selectedArea.id } })
    }

    const nextStep = () => {
        navigate("/mission-statement/goals", { state: { curentUserWorkspaceId } })
    }

    return (
        <div>
            <div className={cc({ [styles.wrapActive]: pathname.includes('add') })}>
                <div style={{ overflow: "hidden" }} className={styles.content}>
                    <div className={styles.wrapper}>
                        <Headline
                            data={{
                                first: {
                                    step: 2,
                                    text: "Prioritize Areas",
                                    active: true,
                                    complete: true,
                                },
                                second: {
                                    step: 3,
                                    text: "Categories & Subcategories",
                                    active: true,
                                },
                                third: {
                                    step: 4,
                                    text: "Goals",
                                    last: true
                                }
                            }}
                        />
                        <div className={styles.head}>
                            <h1 className={styles.title}>Categories</h1>
                            <p className={styles.text}>
                                Add or create up to 10 categories for each area.
                            </p>
                        </div>

                        <div className={styles.body}>
                            <div className={styles.areas}>
                                <h2 className={styles.title}>Areas</h2>
                                <div>
                                    {availableAreas.map((area) => (
                                        <button
                                            key={area.id}
                                            type="button"
                                            className={cc(styles.area, {
                                                [styles.complite]: comleteAreas2.find(a => area.id === a.id),
                                                [styles.active]: selectedArea.id === area.id,
                                            })}
                                            onClick={onAreaClickHandle(area)}
                                        >
                                            {area.name}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <div className={styles.group}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        {selectedArea && < h2
                                            className={styles.title}>{availableAreas.filter(area => area.id === selectedArea.id)[0].name}
                                            ({userCategoriesState.length}/10)

                                        </h2>}
                                        <div style={{ display: "flex" }} onClick={toAddCategoryForm}>
                                            <PlusOutlined style={{ color: '#1F85CF' }} disabled={!userHierachyState?.length < 10} />
                                            <h2 className={styles.addCategory}>CREATE NEW CATEGORY</h2>
                                        </div>
                                    </div>
                                    <div className={styles.list}>
                                        <Droppable droppableId={list.AVAILABLE}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={c(styles.droppable, {
                                                        [styles.over]: snapshot.isDraggingOver,
                                                    })}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {userCategoriesState.map((c, index) => (
                                                        <div><Category
                                                            subCategories={[]}
                                                            systemCategories={systemCategories}
                                                            area={c}
                                                            showCheckbox
                                                            showWeight
                                                            index={index}
                                                            type="reorder"
                                                            key={c.id}
                                                        />
                                                        </div>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                    {userCategoriesState.length ? <hr style={{ color: "blue", height: '2px' }} /> : ''}
                                    <div className={styles.list}>
                                        {systemCategories.filter(c => !userCategoriesState.find(uc => uc.name === c.name)).map((c, index) => (
                                            <div className={styles.systemCategory} key={index}>
                                                <Checkbox label={c.name} onCheckedChange={() => selectCategory(c)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </DragDropContext>
                        </div>
                    </div>
                    <StepsFooter
                        onLaterClickHandle={onLaterClickHandle}
                        previousStep={previous}
                        disabled={comleteAreas2?.length < 10}
                        nextStep={nextStep}
                    />
                </div>
            </div>
            <div className={styles.formWrapper} style={pathname.includes('add') ? { zIndex: '0' } : { zIndex: "-1" }}
            // onClick={() => onCloseModal()}
            >
                <Outlet update={true} />
            </div>
        </div>
    )
}

export default Categories
