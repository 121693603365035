import { getDecisionDetails, getPublicDecisionDetails, updateDecision, getSharedDecisionDetails } from "api";
import { Error } from "utils/Response";
import { ActionTypes } from "./types";
import { objectToParams } from "utils/utility";

export const GetDecisionDetails = (id, isLoading = true, is_sample = false, outer = true, callback) =>
  async (dispatch) => {
    let res = {
      outer,
      is_sample,
    };

    if (!res?.is_sample) {
      delete res.is_sample;
    }

    if (!outer) {
      delete res.outer;
    }

    let query = objectToParams({ ...res });

    try {
      if (isLoading) {
        dispatch({ type: ActionTypes.LOADING });
      }

      const { data } = await (id.toString().length > 10
        ? getPublicDecisionDetails(id, query)
        : getDecisionDetails(id, query));

      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
      if (callback) {
        callback(error);
      }
    }
  };

export const GetSharedDecisionDetails = (decisionId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data } = await getSharedDecisionDetails(decisionId);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const SetDecisionDetails = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};

export const SetDecisionCriterias =
  (criteria, spread = true) =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_CRITERIA,
      payload: criteria,
      spread,
    });
  };

export const AssignDecisionCriterias = (payload) => async (dispatch) => {
  dispatch({
    type: ActionTypes.ASSIGN_CRITERIA,
    payload,
  });
};

export const UpdateDecisionCriterias = (payload) => async (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CRITERIA,
    payload,
  });
};

export const DeleteDecisionCriterias = (payload) => async (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_CRITERIA,
    payload,
  });
};

export const SetDecisionOptions =
  (options, spread = true) =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_OPTIONS,
      payload: options,
      spread,
    });
  };

export const SetDeleteDecisionOptions = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DELETE_OPTIONS,
    payload: id,
  });
};

export const DeleteDecisionCriteriasFromReducer = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_DECISION_CRITERIAS,
    payload: id,
  });
};

export const ClosePopup = (id) => async (dispatch) => {
  const { status } = await updateDecision({ id, body: { is_new: false } });

  if (status === 200 || status === 201) {
    dispatch({
      type: ActionTypes.CLOSE_INITIAL_POPUP,
    });
  }
};
