import React from 'react';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label = '', checked = false, onCheckedChange }) => {
    return (
        <label className={styles.container}>
            {label}
            <input type="checkbox" checked={checked} onChange={onCheckedChange} />
            <span className={styles.checkmark}></span>
        </label>
    );
};

export default Checkbox;