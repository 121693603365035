import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './utils/firebase';
import { registerFSMDevice } from 'modules/actions/UserActions';

const Notification = () => {
    const dispatch = useDispatch();

    const { user: { data: userData } } = useSelector((state) => state.auth);

    const [notification, setNotification] = useState({ title: '', body: '' });
    const notify = () => toast(<ToastDisplay />);

    function ToastDisplay () {
        return (
            <div
                style={{
                    // background: '#FFFFFF',
                    // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                    // borderRadius: 20,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <div className='ml-3'>
                    <p>
                        <b>{notification?.title}</b>
                    </p>
                    <p>{notification?.body}</p>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    useEffect(() => {
        if (userData)
            requestToken();
    }, [userData]);

    const requestToken = async () => {
        const token = await requestForToken();

        if (token && userData.name) {
            const form = {
                "name": userData.name,
                "registration_id": token,
                "active": true,
                "type": "web"
            }

            dispatch(registerFSMDevice(form))
        }
    };

    onMessageListener()
        .then(payload => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
        })
        .catch(err => console.log('failed: ', err));

    return <Toaster />;
};

export default Notification;
