import React, { useEffect, useState } from "react";
import Layout from "../../../components/Auth/Layout";
import * as Styled from "./styled";
import { ReactComponent as AccessRequestedIcon } from "./accessRequested.svg";
import { useNavigate } from "react-router-dom";

const AccessRequested = () => {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  return (
    <Layout bg="login" ppLogo>
      <Styled.Wrapper>
        <Styled.Continaer $animate={animate} $animateDelay={100}>
          <AccessRequestedIcon
            style={{
              marginRight: "30px",
            }}
          />

          <Styled.Title $animate={animate} $animateDelay={100}>
            Access Requested
          </Styled.Title>

          <Styled.SubTitle $animate={animate} $animateDelay={150}>
            You’ll get a notification if your request is approved.{" "}
          </Styled.SubTitle>

          <Styled.SubmitWrapper $animate={animate} $animateDelay={400}>
            <Styled.SubmitStyled onClick={() => navigate("/project-planner")}>
              Back to Dashboard
            </Styled.SubmitStyled>
          </Styled.SubmitWrapper>

          {/* <Styled.Footer $animate={animate} $animateDelay={100}>
            <Styled.Terms onClick={() => navigate("/terms-and-conditions")}>
              Terms and Conditions
            </Styled.Terms>
            <Styled.Privacy onClick={() => navigate("/privacy-policy")}>
              Privacy Policy
            </Styled.Privacy>
          </Styled.Footer> */}
        </Styled.Continaer>
      </Styled.Wrapper>
    </Layout>
  );
};

export default AccessRequested;
