import { addOptionRows } from "api";
import { Error } from "utils/Response";
import {
  // GetDecisionDetails,
  SetDecisionOptions,
} from "../GetDecisionDetails/action";
import { ActionTypes } from "./types";

export const AddOptionRows = (Data, setValue) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await addOptionRows(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
      dispatch(SetDecisionOptions(data, false));
      // dispatch(GetDecisionDetails(Data?.decision_id, false));
      setValue(1);
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
