import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import * as Styled from "./styled";

import { ReactComponent as DMLogo } from "assets/svg/logoDM_1_4.svg";
import { ReactComponent as PPLogo } from "assets/svg/logoPP_1_3.svg";
import { ReactComponent as DefaultLogo } from "assets/svg/logoArootah.svg";
import AppSwitcher from "components/AppSwitcher";

const Layout = ({ bg, children, ppLogo = false }) => {
  const location = useLocation();
  const [animate, setAnimate] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const appParam = queryParams.get("app");

  useEffect(() => {
    setAnimate(false);

    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, [bg]);

  return (
    <Styled.Wrapper>
      <Helmet>
        <title>
          {appParam === "pp"
            ? "Project Planner"
            : appParam === "dm"
            ? "Decision Manager App - Optimize Your Decisions with Multi-Criteria Approach"
            : "Arootah Apps"}{" "}
          | AROOTAH
        </title>
      </Helmet>
      <Styled.Background $bg={bg} $animate={animate} $animateDuration={500} />

      <Styled.Header>
        <Styled.HeaderLeft>
          {ppLogo ? (
            <PPLogo width={190} />
          ) : appParam === "pp" ? (
            <PPLogo width={190} />
          ) : appParam === "dm" ? (
            <DMLogo />
          ) : (
            <DefaultLogo />
          )}
        </Styled.HeaderLeft>
        {!!appParam && (
          <AppSwitcher
            app={
              appParam === "dm"
                ? "decision-manager"
                : appParam === "pp"
                ? "project-planner"
                : ""
            }
          />
        )}
      </Styled.Header>

      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export default Layout;
