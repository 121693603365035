import { all } from "redux-saga/effects";

import AuthSaga from "./AuthSaga";
import UserSaga from "./UserSaga";
import PlanSaga from "./PlanSaga";
import MissionStatementSaga from "./MissionStatementSaga";

export function* sagas() {
  yield all([AuthSaga, UserSaga, PlanSaga, MissionStatementSaga]);
}
