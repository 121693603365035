import React, { createRef, useEffect, useState } from "react";
import { InputField } from "elements";
import { useDispatch } from "react-redux";

import {
  updatePlanCategory,
  createPlanCategory,
} from "modules/actions/PlanActions";

const WeightTitle = ({
  text,
  isNew,
  setIsNew,
  sortedCategories,
  setSortedCategories,
}) => {
  const $inputRef = createRef();
  const dispatch = useDispatch();

  const [title, setTitle] = useState(text?.name || "");
  const [isEdit, setIsEdit] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsEdit(false);
      setIsNew(false);
      changeName();
    } else if (e.key === "Escape") {
      setIsEdit(false);
      setIsNew(false);
      setTitle(text?.name);
    }
  };

  const handleBlur = () => {
    setIsEdit(false);
    setIsNew(false);
    changeName();
  };

const handleChangeInput = (e) => {
  let input = e.target.value;

  if (input.startsWith(" ")) {
    input = input.trimStart();
  }

  if (input.length > 255) {
    input = input.slice(0, 255);
  }
  
  setTitle(input);
};

  const changeName = () => {
    const updateData = {
      ...text,
      name: title,
    };

    if (!isNew && text?.name !== title) {
      dispatch(updatePlanCategory(text?.id, updateData));
    }

    if (isNew && title) {
      const payload = {
        name: title,
        start_line: text?.start_line,
        dead_line: text?.dead_line,
        project_plan: text?.project_plan,
      };

      dispatch(createPlanCategory(payload, handleClose));

      const findSorted = sortedCategories.find((item) => item.id === text?.id);
      setSortedCategories((prev) => [
        ...prev.filter((item) => item.id !== text?.id),
        {
          ...findSorted,
          name: title,
        },
      ]);
    } else if (isNew && !title) {
      const removeData = sortedCategories.filter(
        (item) => item.id !== text?.id
      );

      setSortedCategories(removeData);

      setIsNew(false);
    }
  };

  const handleClose = () => {
    setIsNew(false);
  };

  useEffect(() => {
    if (isEdit && $inputRef.current) {
      $inputRef.current.focus();
    }
  }, [isEdit, $inputRef]);

  useEffect(() => {
    setTitle(text?.name);
  }, [text?.name]);

  useEffect(() => {
    if (isNew && text?.newItem) {
      setIsEdit(true);
    }
  }, [isNew]);

  return (
    <div
      style={{
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        setIsEdit(true);
      }}
    >
      {isEdit ? (
        <InputField
          name="name"
          value={title}
          placeholder="Item name"
          ref={$inputRef}
          onBlur={handleBlur}
          onChange={handleChangeInput}
          onKeyDown={handleKeyDown}
          data-testid="name-input"
        />
      ) : (
        <span>{text?.name}</span>
      )}
    </div>
  );
};

export default WeightTitle;
