import { createDecision } from "api";
import { Error } from "utils/Response";
import { SetDecision } from "../GetDecision/action";
import { ActionTypes } from "./types";

export const CreateDecision = (Data, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await createDecision(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 201) {
      dispatch(SetDecision(data));

      if (callback)
        callback(data);
    }
  } catch (error) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
