import { updatePros } from "api";
import { Error } from "utils/Response";
import { GetPros } from "../GetPros/action";
import { ActionTypes } from "./types";

export const UpdatePros =
  (Data, prosId, id, setEditProsInput) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const { data, status } = await updatePros(Data, prosId);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
        dispatch(GetPros(id));
        setEditProsInput(null);
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };
