import { Button, Col, Progress, Row } from 'antd'
import { COLORS } from 'constants/colors'
import React from 'react'
import { ReactComponent as Health } from 'assets/svg/sortgroup/Health.svg'
import { ReactComponent as Partner } from 'assets/svg/sortgroup/Partner.svg'
import { ReactComponent as Growth } from 'assets/svg/sortgroup/Growth.svg'
import { ReactComponent as Career } from 'assets/svg/sortgroup/Career.svg'
import { ReactComponent as Contribution } from 'assets/svg/sortgroup/Contribution.svg'
import { ReactComponent as Finance } from 'assets/svg/sortgroup/Finance.svg'
import { ReactComponent as Social } from 'assets/svg/sortgroup/Social.svg'
import { ReactComponent as Leisure } from 'assets/svg/sortgroup/Leisure.svg'
import { ReactComponent as Environment } from 'assets/svg/sortgroup/Environment.svg'

export default function SortByAreaTableView ({ allAreas, handleChange }) {
  const list = [
    { title: 'Health', icon: <Health /> },
    { title: 'Partner', icon: <Partner /> },
    { title: 'Growth', icon: <Growth /> },
    { title: 'Career', icon: <Career /> },
    { title: 'Contribution', icon: <Contribution /> },
    { title: 'Finance', icon: <Finance /> },
    { title: 'Social', icon: <Social /> },
    { title: 'Leisure', icon: <Leisure /> },
    { title: 'Environment', icon: <Environment /> }
  ]
  const handleArea = title => {
    if (title === 'View all areas') {
      handleChange('allAreas', ['View all areas'])
    } else if (allAreas?.includes(title)) {
      const removed = allAreas?.filter(e => e !== title)
      handleChange('allAreas', removed)
    } else {
      const removed = allAreas?.filter(e => e !== 'View all areas')
      handleChange('allAreas', [...removed, title])
    }
  }
  return (
    <Col className={'mb-2 p-1'}>
      {list?.map((item, index) => (
        <div
          key={index}
          className={'areaListBoxFront'}
          onClick={() => handleArea(item.title)}
          style={{
            backgroundColor: allAreas?.includes(item.title)
              ? COLORS.activeArea
              : COLORS.white,
            color: COLORS.fillButton,
            fontWeight: allAreas?.includes(item.title) ? 'bold' : 'normal'
          }}
        >
          <div className='row'>
            {item?.icon}
            <div className='ml-1'>{item.title}</div>
            <div className='areaTextBox'>Area</div>
          </div>
          <Progress
            percent={50}
            strokeWidth={13}
            trailColor={
              allAreas?.includes(item.title) ? COLORS.activeArea : COLORS.grey1
            }
            strokeColor={
              allAreas?.includes(item.title)
                ? COLORS.activeSlider
                : COLORS.slider
            }
            showInfo={false}
          />
        </div>
      ))}
    </Col>
  )
}
