import { addToShortlist } from "api";
import { Error } from "utils/Response";
import { SetDecisionDetails } from "../GetDecisionDetails/action";
import { ActionTypes } from "./types";

export const AddToShortlist =
  (Data, decisionId, resetCheckList) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING });
      const { data, status } = await addToShortlist(Data, decisionId);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
        dispatch(SetDecisionDetails(data));
      }
      resetCheckList && resetCheckList();
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };
