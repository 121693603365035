import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Formik } from "formik";
import * as yup from "yup";

import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeSlashIcon } from "assets/svg/eye-slash.svg";

import PasswordIndicator from '../../PasswordIndicator';

import * as Layout from '../styled';
import * as Styled from './styled';

import { resetPassword } from '../../../modules/actions/AuthActions';
import { ErrorFocus } from '../../../utils/ErrorFocus';

const schema = yup.object().shape({
  new_password1: yup.string()
    .required("This field is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number."
    ),
  new_password2: yup
    .string()
    .required("This field is required.")
    .oneOf([yup.ref("new_password1"), null], "Password entries do not match.")
});

const initialValues = {
  new_password1: '',
  new_password2: ''
}

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [animate, setAnimate] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const { loading } = useSelector((state) => state.auth.resetPassword);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100)
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const onSubmitHandle = ({ new_password1, new_password2 }) => {
    dispatch(resetPassword({ form: { password_1: new_password1, password_2: new_password2 }, token }, navigate));
  }

  const onPasswordVisibleClickHandle = () => {
    setPasswordVisible(!passwordVisible);
  }

  const onPasswordConfirmVisibleClickHandle = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  }

  return (
    <Layout.Screen>
      <Layout.Column $middle>
        <Styled.FormWrapper>
          <Layout.Title $animate={animate} $animateDelay={100}>Change Your Password</Layout.Title>
          <Styled.FormText $animate={animate} $animateDelay={150}>Now, choose a password that’s at least as strong as your willpower</Styled.FormText>

          <Formik
            validationSchema={schema}
            onSubmit={onSubmitHandle}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleReset, handleChange, values, errors }) => (
              <Styled.FormStyled noValidate onSubmit={handleSubmit}>
                <Styled.FormGroup $animate={animate} $animateDelay={200}>
                  <Styled.Label className="d-flex align-items-center justify-content-between">
                    New Password*
                  </Styled.Label>
                  <div className="flex-grow-1 position-relative w-100">
                    <Styled.TextBoxs
                      type={passwordVisible ? "text" : "password"}
                      name="new_password1"
                      value={values.new_password1}
                      onChange={handleChange}
                      isInvalid={errors.new_password1}
                      aria-label="Password"
                      data-testid="password-input"
                    />
                    <Styled.Eye className="form-control-eye" type="button" onClick={onPasswordVisibleClickHandle}>
                      {passwordVisible
                        ? <EyeSlashIcon />
                        : <EyeIcon /> }
                    </Styled.Eye>
                    <PasswordIndicator value={values.new_password1} secondary />
                    {errors.new_password1 ? null : <Styled.FormInfo>Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number.</Styled.FormInfo>}
                    <Styled.FormError>
                      {errors.new_password1}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.FormGroup $animate={animate} $animateDelay={250}>
                  <Styled.Label className="authLabel">Confirm Password*</Styled.Label>
                  <div className="flex-grow-1 position-relative w-100">
                    <Styled.TextBoxs
                      type={passwordConfirmVisible ? "text" : "password"}
                      name="new_password2"
                      value={values.new_password2}
                      onChange={handleChange}
                      isInvalid={errors.new_password2}
                      aria-label="Confirm password"
                      data-testid="password-confirm-input"
                    />
                    <Styled.Eye className="form-control-eye" type="button" onClick={onPasswordConfirmVisibleClickHandle}>
                      {passwordConfirmVisible
                        ? <EyeSlashIcon />
                        : <EyeIcon /> }
                    </Styled.Eye>
                    <Styled.FormError type="invalid">
                      {errors.new_password2}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.SubmitWrapper $animate={animate} $animateDelay={300}>
                  <Styled.SubmitStyled
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Reset
                  </Styled.SubmitStyled>
                </Styled.SubmitWrapper>

                <ErrorFocus />
              </Styled.FormStyled>
            )}
          </Formik>
        </Styled.FormWrapper>
      </Layout.Column>
    </Layout.Screen>
  );
}

export default ResetPassword;
