import { FC, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Modal,
} from "antd";

import { MailOutlined } from "@ant-design/icons";

// @ts-ignore
// import { inviteOwner, upateActionItem } from "modules/actions/PlanActions";

// @ts-ignore
import { inviteOwner } from "modules/actions/UserActions";

interface IInviteUserModal {
  isActive: boolean;
  emailToInvite: string;
  onClose: () => void;
}

const InviteUserModal: FC<IInviteUserModal> = ({ isActive, emailToInvite, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleInvite = () => {
    setIsLoading(true)

    const payload = { invited_owner: emailToInvite };
    dispatch(inviteOwner(payload, handleOwnerModal));
  };

  const handleOwnerModal = () => {
   /*  if (users?.some((e) => e?.email === emailToInvite)) {
      const rp = users?.find((e) => e?.email === emailToInvite);
      const row = { ...selectedRecord, responsible_person: rp?.id };

      dispatch(upateActionItem(row?.id, row, planDetails?.id, true));
    } */

    onClose();
  };

  return (
    <Modal
      footer={null}
      onCancel={onClose}
      title={false}
      open={isActive}
    >
      <div className="font-18 mb-1">Share this action</div>

      <div>
        {/* Sharing <b className='underline'>{record?.name}</b> action */}
      </div>

      <div className="ownerModalEmail">
        <div className="ownerEmailDiv">{emailToInvite}</div>
      </div>

      <Button
        icon={<MailOutlined />}
        className="inviteButton"
        type="primary"
        loading={isLoading}
        // disabled={!inviteEmail}
        onClick={handleInvite}
      >
        Invite
      </Button>
    </Modal>
  );
};

export default InviteUserModal;
