import { getOption } from "api";
import { Error } from "utils/Response";
import { ActionTypes } from "./types";

export const GetOption =
  (Data, loadingStatus = true) =>
  async (dispatch) => {
    try {
      if (loadingStatus) {
        dispatch({ type: ActionTypes.LOADING });
      }
      const { data, status } = await getOption(Data);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };

export const SetOption = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
