export const ActionTypes = {
  LOADING: "GET_DECISION_DETAILS_LOADING",
  ERROR: "GET_DECISION_DETAILS_ERROR",
  SUCCESS: "GET_DECISION_DETAILS_SUCCESS",
  SET_CRITERIA: "SET_CRITERIA",
  ASSIGN_CRITERIA: "ASSIGN_CRITERIA",
  UPDATE_CRITERIA: "UPDATE_CRITERIA",
  DELETE_CRITERIA: "DELETE_CRITERIA",
  DELETE_DECISION_CRITERIAS: "DELETE_DECISION_CRITERIAS",
  SET_OPTIONS: "SET_OPTIONS",
  SET_DELETE_OPTIONS: "SET_DELETE_OPTIONS",
  CLOSE_INITIAL_POPUP: "CLOSE_INITIAL_POPUP",
};
