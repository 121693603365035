import React from "react";
import { message, Spin, Upload } from "antd";
import classes from "./uploadImage.module.scss";
import { useSelector } from "react-redux";
import { ReactComponent as PlaceholderBordered } from "./placeholder-bordered.svg";
import { ReactComponent as AddIcon } from "./add-icon.svg";

const UploadImage = ({ setImgForAdding, imgForAdding, setImage, loading }) => {
  const { success: userOption, loading: userOptionLoading } = useSelector(
    (state) => state.getOptionReducer
  );

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    let reader = new FileReader();
    if (file.size / 1024 / 1024 < 2 && isJpgOrPng) {
      reader.onload = () => {
        setImgForAdding(reader.result);
      };
    }

    let formData = new FormData();
    formData.append("image", file);
    setImage({
      image: formData,
      size: file.size / 1024 / 1024,
      type: file.type,
    });
    reader.readAsDataURL(file);
    return false;
    // const isJPG = file.type === 'image/jpeg';

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    /* if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        } */
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {};
  const loadingImg = () => {
    return <Spin className={classes.centered} size="large" />;
  };
  return (
    <div className={classes.img} onClick={(e) => e.stopPropagation()}>
      {
        <Upload
          name="avatar"
          className={classes.img}
          style={{ width: "42px", height: "42px", border: "none" }}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imgForAdding && !userOptionLoading ? (
            <img src={imgForAdding} alt="avatar" style={{ width: "440px" }} />
          ) : !loading ? (
            <div className={classes.placeholder}>
              <PlaceholderBordered className={classes.border} />
              <AddIcon className={classes.icon} />
              <p>
                Choose an image file or <br />
                drag it here.
              </p>
              <p className={classes.imageWarning}>Image size must be smaller than 2 MB.</p>
            </div>
          ) : (
            <div className={classes.centered}>{loadingImg()}</div>
          )}
        </Upload>
      }
    </div>
  );
};

export { UploadImage };
