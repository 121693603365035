import styled from "styled-components";
import { fadeInUpMixin } from "../../../components/Auth/animated";
import { Submit } from "../SignUp/styled";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Continaer = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  ${fadeInUpMixin}
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #000000de;
  margin-bottom: 30px;
  ${fadeInUpMixin}
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin-bottom: 30px;
  ${fadeInUpMixin}
`;

export const label = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #737373;
  font-family: "Inter";
  margin-bottom: 4px;
  text-align: left;
  ${fadeInUpMixin}
`;

export const value = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  font-family: "Inter";
  margin-bottom: 20px;
  text-align: left;
  ${fadeInUpMixin}
`;

export const StyledTextarea = styled.textarea`
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #171717;
  font-family: "Inter";
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  resize: vertical;
  max-height: 120px;
  /* ${fadeInUpMixin} */
`;

export const SubmitWrapper = styled.div`
  width: 100%;
  ${fadeInUpMixin}
`;

export const SubmitStyled = styled(Submit)`
  width: 100%;
  margin-top: 12px;
`;

export const Cancel = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #4b545b;
  margin: 8px 0px;
  text-align: center;
  cursor: pointer;
`;
