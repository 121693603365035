// @ts-ignore
import { cloneDecision } from "api";

// @ts-ignore
import { Success, Error } from "utils/Response";

import { ActionTypes } from "./types";

export const CloneDecision = (payload: any, callback: (response: any) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await cloneDecision(payload);

    if (status === 200) {
      dispatch({
        type: ActionTypes.SUCCESS,
      });

      Success("Copy created! You're now on the edit page.");

      if (callback)
        callback(data);
    }
  } catch (error: any) {
    Error(error?.response?.data?.error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
