import { Col, Row, Card, Progress, Modal, Divider, Button } from "antd";
import { FC, useEffect, useState } from "react";
// import { AppLayout } from "components";
// import { ReactComponent as BackIcon } from "assets/svg/Back.svg";
import { useNavigate } from "react-router-dom";
// import { getPlanByID } from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";
// import { COLORS } from "constants/colors";

// @ts-ignore
import WeightTable from "./WeightTable";

// @ts-ignore
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
// import ActionTable from "./ActionTable";

interface IProps {
  isAdjustWeightActive: boolean;
  onClose: () => void;
  // handleChangeMain: (key: string, value: any) => void;
  // updatedWeight: any;
  // id: number;
}

const AdjustWeight: FC<IProps> = ({ isAdjustWeightActive, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // @ts-ignore
  const { planDetails } = useSelector((state) => state.plan.plan);

  const [sortedCategories, setSortedCategories] = useState(
    planDetails?.categories || []
  );

  const [state, setState] = useState({
    updatedWeight: [],
  });

  useEffect(() => {
    if (!isAdjustWeightActive) {
      setSortedCategories(planDetails?.categories || []);
      setState({
        updatedWeight: [],
      });
    }
  }, [isAdjustWeightActive]);

  return (
    <Modal
      footer={null}
      width={"90%"}
      className={"deleteModal"}
      style={{ top: 20 }}
      closable={false}
      onCancel={onClose}
      open={isAdjustWeightActive}
    >
      <Row justify="space-between" align="middle" className="pt-2 ph-2">
        <div className="font-16 text_black text_bold">Adjust Weight</div>
        <Cross onClick={onClose} className={"c-pointer"} />
      </Row>

      <Divider />

      <WeightTable
        planDetails={planDetails}
        handleChangeMain={onClose}
        sortedCategories={sortedCategories}
        setSortedCategories={setSortedCategories}
        state={state}
        setState={setState}
      />
    </Modal>
  );
};

export default AdjustWeight;
