import React, { useState, useRef } from 'react';

import { useDispatch } from 'react-redux';

import DefaultAvatar from '../../../assets/images/DefaultAvatar.png';

import { ReactComponent as PlusIcon } from "../../../assets/images/profile/plus.svg";

import * as Styled from './styled';
import { changeUserProfile } from 'modules/actions/AuthActions';

const ProfilePicture = ({ data }) => {
  const dispatch = useDispatch();
  const { id, display_picture } = data;

  const imageUploader = useRef(null);

  const onPressHandler = () => {
    imageUploader.current.click();
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const fileUploadHandler = async (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);

    let formData = new FormData();
    formData.append('profile_picture', file);

    dispatch(changeUserProfile({ form: formData, id }));
  };

  return (
    <Styled.ProfileImageWrapper onClick={onPressHandler}>
      <Styled.ProfileImage
        src={imagePreviewUrl || display_picture || DefaultAvatar}
        alt="profile"
      />

      <Styled.EditProfileIcon title="Change profile picture">
        <PlusIcon width={28} />
      </Styled.EditProfileIcon>

      <Styled.FileUploader
        ref={imageUploader}
        type="file"
        name="avatar[image]"
        accept="image/*"
        onChange={fileUploadHandler}
      />
    </Styled.ProfileImageWrapper>
  );
};

export default ProfilePicture;
