import "./App.css";
import "./styles.css";
import { BrowserRouter } from "react-router-dom";
// @ts-ignore
import MainRoutes from "./Routes";
// import configureStore, { history } from './store'
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @ts-ignore
import Notification from "Notification";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GoogleOAuthProvider } from "@react-oauth/google";

// @ts-ignore
import { ThemeProvider } from "styled-components";

// Sentry.io
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Google tag manger
import TagManager from "react-gtm-module";
// @ts-ignore
import * as amplitude from '@amplitude/analytics-browser';

// @ts-ignore
import store from "redux/store";

// @ts-ignore
import theme from "theme";
// @ts-ignore
import { websocketConnection } from "utils/websocket";
import { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

// @ts-ignore
import { isProduction } from "utils/constants";

const tagManagerArgs = {
  gtmId: "GTM-MVV2XVM",
};

// Shouldn't run on localhost
if (
  !(
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  )
) {
  Sentry.init({
    dsn: "https://8d2563a6b437440aa8acda71d72c3dfb@o4504527357345792.ingest.sentry.io/4504549812142080",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  TagManager.initialize(tagManagerArgs);

  // Init Amplitude
  if(isProduction) {
    console.log("🟢 Amplitude initialized");

    amplitude.init("efc3d3d6b9453e84530343ecdfd0857f", {
      defaultTracking: true
    }); 
  }
}

function App() {
  useEffect(() => {
    websocketConnection.connect();
  }, []);

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="207150144695-s8f0j0lsk6oun0hgvh7dndebjgvu831n.apps.googleusercontent.com">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Notification />
            <DndProvider backend={HTML5Backend}>
              <MainRoutes />
            </DndProvider>
          </ThemeProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
      <ToastContainer />
    </Provider>
  );
}

export default App;
