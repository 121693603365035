export interface CustomUnitOption {
  id: number;
  label: number;
  value: string;
}

export interface CustomLabel {
  value: string;
  custom_unit_options: CustomUnitOption[];
  id: number;
}

export interface PlanDetails {
  goal: {
    label: string;
    custom_labels: CustomLabel[];
    custom_unit: number;
    [key: string]: any;
  };
}

export const getCustomUnitName = (planDetails: PlanDetails) => {
  if (planDetails?.goal?.label === 'custom' && planDetails?.goal?.custom_unit) {
    for (const customLabel of planDetails.goal.custom_labels) {
      const customUnitOption = customLabel.custom_unit_options.find(option => option.id === planDetails.goal.custom_unit);
      if (customUnitOption) {
        return customUnitOption.value;
      }
    }
  }

  return planDetails?.goal?.label && planDetails?.goal[planDetails?.goal?.label + '_unit'];
};
