import React from 'react';
import {AppLayout} from "components/index";

const DecisionMatrixLayout = ({children}) => {
    return (
        <div>
            <AppLayout>
                {children}
            </AppLayout>
        </div>
    );
};

export {DecisionMatrixLayout};