import { getDecision } from "api";
import { Error } from "utils/Response";
import { objectToParams } from "utils/utility";
import { ActionTypes } from "./types";

export const GetDecision = (nested, is_sample) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await getDecision(
      objectToParams({
        nested,
        // is_sample
      })
    );
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const SetDecision = (data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DECISION,
    payload: data,
  });
};

export const DeleteDecisionFromReducer = (id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_DECISION,
    payload: id,
  });
};
