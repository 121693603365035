import styled from "styled-components";

export const DetailContainer = styled.div`
  display: flex;
  margin-bottom: 14px;
`;

export const SuccessContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.p`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  color: #000;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  text-align: center;
`;

export const Span = styled.span<{
  decision?: boolean;
  email?: boolean;
}>`
  color: ${({ decision, email }) => (decision ? "black" : (email ? "#2163A6" : "inherit"))};
  font-weight: ${({ decision }) => (decision ? 700 : "normal")};
`;

export const Background = styled.div`
  height: calc(100vh);
  left: 0;
  background-color: #00000075;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1440;
`;

export const Modal = styled.div`
  display: flex;
  padding: 36px 0;
  z-index: 1450;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const Problem = styled.h3`
  color: #112538;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const ImgContainer = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`;

export const Content = styled.div`
  width: 553px;
  padding: 15px 20px 20px;
  z-index: 1000;
  background: #ffffff;
  margin: 0 auto; /* Add this rule to center the content */
`;

export const PriorityBox = styled.div<{ low?: boolean; medium?: boolean; high?: boolean }>`
  margin-top: 4px;
  background-color: ${({ low, medium, high }) => (low ? "#E7F6F8" : medium ? "#fff3e4" : high ? "#ffe9e8" : "inherit")};
  padding: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 6px;
  width: 145px;
`;

export const PriorityText = styled.h4<{ low?: boolean; medium?: boolean; high?: boolean }>`
  color: ${({ low, medium, high }) => (low ? "#10A3B7" : medium ? "#fead41" : high ? "#ff2c26" : "inherit")};
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 12px;
  margin: 0;
  padding: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1rem;
  margin-bottom: 0.8rem;
`;

export const Heading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  color: #05203a;
`;

export const SharedContent = styled.div`
  padding: 4px 16px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 40px;
`;

export const Disclaimer = styled.span`
  color: var(--Text-Overline-Dark-gray, #878787);
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
`;
