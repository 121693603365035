import React, { useState, useEffect } from 'react';

import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg";

import * as Layout from '../styled';
import * as Styled from './styled';

const increaseBy = (num) => {
  let counter = num;
  return () => {
    const result = counter;
    counter += 50;
    return result;
  }
}

const Landing = () => {
  const animateDelayCounter = increaseBy(100);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  return (
    <Layout.Screen $landing>
      <Styled.FormWrapper>
        <Layout.Title $animate={animate} $animateDelay={animateDelayCounter()}>Welcome To Arootah Apps!</Layout.Title>
        <Styled.Text $animate={animate} $animateDelay={animateDelayCounter()}>Choose App where you want to Login</Styled.Text>

        <Styled.AppsList>
          <Styled.AppItem to="/sign-in?app=dm" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoDM />
            <Styled.AppItemTitle>Decision Manager</Styled.AppItemTitle>
          </Styled.AppItem>

          <Styled.AppItem to="/sign-in?app=pp" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoPP />
            <Styled.AppItemTitle>Project Manager</Styled.AppItemTitle>
          </Styled.AppItem>
        </Styled.AppsList>
      </Styled.FormWrapper>
    </Layout.Screen>
  );
}

export default Landing;
