import React, { useState } from "react";
import moment from "moment";
import classes from "./styles.module.scss";
import { Dropdown, Popover, Progress } from "antd";
import cc from "classnames";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CupIcon } from "./cup.svg";
import Placeholder from "./Placeholder.jpg";
import useWindowSize from "../../../../utils/useWindowSize";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { EditDecisionModal } from "components/EditDecisionModal/EditDecisionModal";
import { DeleteDecision } from "redux/DecisionMatrix/DeleteDecision/action";
import { DuplicateDecision } from "redux/DecisionMatrix/DuplicateDecision/action";
import { Tooltip as AntdTooltip } from "antd";

const DecisionSampleCard = ({ decision, isAdmin }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const toDetail = (e, decision) => {
    e.stopPropagation();

    if (decision?.is_sample) navigate(`${decision?.id}?is_sample=true`);
    else navigate(`${decision?.id}`);
  };

  const toEditDecision = (e, decision) => {
    e.stopPropagation();
    navigate(`/decision-manager/edit-decision/${decision?.id}`);
  };

  const { loading } = useSelector((state) => state.deleteDecisionReducer);

  const items = [
    {
      key: "1",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={(e) => toEditDecision(e, decision)}
          >
            Edit
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv "
            onClick={(e) => {
              setIsModalOpen(true);
              // e.stopPropagation();
            }}
          >
            Delete
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv "
            onClick={(e) => {
              dispatch(DuplicateDecision({ decision_id: decision?.id }));
              // e.stopPropagation();
            }}
          >
            Duplicate
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      {isEditModalOpen && (
        <EditDecisionModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          decision={decision}
        />
      )}
      {isModalOpen && (
        <ConfirmationModal
          loading={loading}
          data={decision}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleDelete={() =>
            dispatch(DeleteDecision(decision?.id, setIsModalOpen, true))
          }
        />
      )}
      {width > 1199 && (
        <div className={classes.cardWrapper}>
          <div className={classes.dropdownWrapper}>
            <Dropdown
              overlayClassName="decision_header_dropdown"
              menu={{ items }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <EllipsisOutlined rotate={90} style={{ marginBottom: "5px" }} />
                {/* <OptionsIcon className={classes.optionIcon} /> */}
              </a>
            </Dropdown>
          </div>

          <div className={classes.body} onClick={(e) => toDetail(e, decision)}>
            <div className={classes.img}>
              <img src={decision?.final_option?.image || Placeholder} alt="" />
            </div>
            <div className={classes.content}>
              <div className={classes.header}>
                <div className={classes.title}> {decision?.problem}</div>
                <span>
                  {/* <Popover
                    overlayClassName="cardPopover"
                    placement="bottomLeft"
                    content={content}
                    className={classes.popover}
                    onClick={(e) => e.stopPropagation()}
                    trigger="focus"
                  >
                    <EllipsisOutlined
                      rotate={90}
                      style={{ marginBottom: "5px" }}
                    />
                  </Popover> */}
                </span>
              </div>

              {decision?.priority && (
                <div
                  className={cc(classes.priority, {
                    [classes.high]: decision?.priority === 3,
                    [classes.medium]: decision?.priority === 2,
                    [classes.low]: decision?.priority === 1,
                  })}
                >
                  <div>
                    Priority: {decision?.priority === 1 && "Low"}{" "}
                    {decision?.priority === 2 && "Medium"}{" "}
                    {decision?.priority === 3 && "High"}
                  </div>
                </div>
              )}

              {!isAdmin && <div className={classes.readOnly}>View only</div>}

              {/* Hidden temporarily, do not remove: */}
              <div className={classes.progressWrapper}>
                {/* <div className={classes.progress}>
                  <div>Decision Progress</div>
                  <div>{progress || 0}%</div>
                </div>
                <Progress
                  style={{ color: "green", width: "300px" }}
                  percent={progress || 0}
                  showInfo={false}
                  size="small"
                /> */}
              </div>

              {/* <div className={classes.deadline}>
                <span>Deadline: </span>
                {moment(decision?.deadline, "YYYY-MM-DD").format("MM/DD/YYYY")}
              </div> */}

              {decision?.final_option && (
                <div className={classes.winner}>
                  <CupIcon />
                  <AntdTooltip
                    title={decision?.final_option?.name}
                    color={"#feab3c"}
                  >
                    <span>{decision?.final_option?.name}</span>
                  </AntdTooltip>
                </div>
              )}

              <div className={classes.footer}>{decision?.footer}</div>
            </div>
          </div>
        </div>
      )}

      {width < 1200 && (
        <div
          className={classes.cardWrapper}
          onClick={(e) => toDetail(e, decision)}
        >
          <div className={classes.img}>
            <img src={decision?.final_option?.image || Placeholder} alt="" />
          </div>
          <div className={classes.content}>
            <div className={classes.header}>
              <div className={classes.title}> {decision?.problem}</div>
              {/* <span>
                <Popover
                  overlayClassName="cardPopover"
                  content={content}
                  className={classes.popover}
                  onClick={(e) => e.stopPropagation()}
                  trigger="focus"
                >
                  <EllipsisOutlined
                    rotate={90}
                    style={{ marginBottom: "5px" }}
                  />
                </Popover>
              </span> */}
            </div>

            {decision?.priority && (
              <div
                className={cc(classes.priority, {
                  [classes.high]: decision?.priority === 3,
                  [classes.medium]: decision?.priority === 2,
                  [classes.low]: decision?.priority === 1,
                })}
              >
                <div>
                  Priority: {decision?.priority === 1 && "Low"}{" "}
                  {decision?.priority === 2 && "Medium"}{" "}
                  {decision?.priority === 3 && "High"}
                </div>
              </div>
            )}

            <div className={classes.progressWrapper}>
              <div className={classes.progress}>
                <div>Decision Progress</div>
                {/* <div>{progress || 0}%</div> */}
              </div>
              <Progress
                style={{ color: "green", width: "202px" }}
                // percent={progress || 0}
                showInfo={false}
                size="small"
              />
            </div>

            <div className={classes.deadline}>
              <span>Deadline: </span>
              {moment(decision?.deadline, "YYYY-MM-DD").format("MM/DD/YYYY")}
            </div>

            {decision?.final_option && (
              <div className={classes.winner}>
                <CupIcon />
                <span>{decision?.final_option?.name}</span>
              </div>
            )}

            <div className={classes.footer}>{decision?.footer}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export { DecisionSampleCard };
