export const COLORS = {
  primary: "#3090F1",
  white: "#ffffff",
  fillButton: "#112538",
  activeArea: "#73B2DF",
  activeSlider: "#EFF7FB",
  grey1: "#F5F6FA",
  slider: "#C9DDF0",
  toolBG: "rgba(254, 171, 61, 0.15)",
  sliderGreen: "#25B169",
  inprogressBG: "#ECEC4E",
  scheduledBG: "#1C8B7E",
  completedBG: "#3090F1",
  red: "#E85A25",
  orange: "#FFB900",
};
