function ArrowIcon({
  color = '#ffffff',
  ...props
}) {
  return (
    <svg
      width={12}
      height={6}
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0667 4.9928C11.0675 5.12459 11.0423 5.25523 10.9926 5.37724C10.9429 5.49925 10.8697 5.61022 10.7772 5.70379C10.6844 5.79765 10.5739 5.87215 10.4523 5.92299C10.3306 5.97382 10.2001 6 10.0683 6C9.93649 6 9.80599 5.97382 9.68433 5.92299C9.56266 5.87215 9.45223 5.79765 9.35942 5.70379L5.99711 2.38918L2.63474 5.7088C2.44768 5.89531 2.19463 6 1.93086 6C1.66709 6 1.41404 5.89531 1.22697 5.7088C1.13339 5.61571 1.05911 5.50495 1.00843 5.38293C0.957737 5.2609 0.931641 5.13001 0.931641 4.99781C0.931641 4.86562 0.957737 4.73473 1.00843 4.6127C1.05911 4.49067 1.13339 4.37992 1.22697 4.28682L5.29822 0.286255C5.48485 0.102773 5.73577 1.14235e-08 5.99711 0C6.25845 -1.14235e-08 6.50937 0.102773 6.69601 0.286255L10.7472 4.29183C10.844 4.38171 10.9221 4.48993 10.9769 4.61025C11.0317 4.73056 11.0623 4.86058 11.0667 4.9928Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowIcon;
