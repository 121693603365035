import c from 'classnames';
import styles from './styles.module.scss';

const Button = props => {
  const { as = 'button', type, size = "md", withIcon, children, className, ...other } = props;

  const Component = as;

  return (
    <Component className={c(styles.button, styles[type], styles[size], withIcon && styles.icon, className)} type="button" {...other}>
      {children}
    </Component>
  );
}

export default Button;
