import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 94px 0;
  margin: 0 auto;
  max-width: 356px;
`;

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Text = styled.span`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;

  b {
    font-weight: 600;
  }
`;

export const Hr = styled.hr`
  margin: 12px 0;
  border: none;
  height: 1px;
  background: var(--Neutral-200, #D9D9D9);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;